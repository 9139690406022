import { setupData } from "../data/setup"
import { countries } from "../data/country"

const parseGender = (gender: string, ref: string, country?: string) => {
  let result = null
  setupData(country).gender.conditions.forEach((val) => {
    if (gender === val.lucidCode && ref === "lucid") {
      result = val.label
    } else if (gender === val.pureCode && ref === "pure") {
      result = val.label
    } else if (gender === val.prodegeCode && ref === "prodege") {
      result = val.label
    }
  })
  return result
}

const parseMariageStatus = (mariageStatus: string, ref: string, country: string) => {
  let result = null
  setupData(country).relationship.conditions.forEach((val) => {
    if (mariageStatus === val.lucidCode && ref === "lucid") {
      result = val.label
    } else if (mariageStatus === val.pureCode && ref === "pure") {
      result = val.label
    } else if (mariageStatus === val.prodegeCode && ref === "prodege") {
      result = val.label
    }
  })
  return result
}

const parseKids = (kids: string, ref: string, country: string) => {
  let result = null
  const arrayKids = kids?.split("")
  if (ref === "lucid" && (arrayKids[1] === "1" || arrayKids[2] === "1")) {
    result = "Have Children"
  } else if (ref === "lucid") {
    result = "No Children"
  } else if (ref === "pure") {
    setupData(country).children.conditions.forEach((val) => {
      if (kids === val.pureCode) {
        result = val.label
      }
    })
  } else if (ref === "prodege") {
    setupData(country).children.conditions.forEach((val) => {
      if (kids === val.prodegeCode) {
        result = val.label
      }
    })
  }

  return result
}

const parseWorkingStatus = (workingStatus: string, ref: string, country: string) => {
  let result = null
  setupData(country).employment.conditions.forEach((val) => {
    if ((workingStatus === "7" || workingStatus === "8") && ref === "lucid") {
      result = "Unemployed"
    } else if (workingStatus === val.lucidCode && ref === "lucid") {
      result = val.label
    } else if (workingStatus === val.pureCode && ref === "pure") {
      result = val.label
    } else if (workingStatus === val.prodegeCode && ref === "prodege") {
      result = val.label
    }
  })
  return result
}
const parseLocation = (region: string, ref: string, country: string) => {
  let result = ""
  countries[countries.findIndex((val) => val.label === country)]?.cities?.forEach((val) => {
    if (region === val.lucidCode && ref === "lucid") {
      result = val.label
    } else if (region === val.pureCode && ref === "pure") {
      result = val.label
    } else if (region === val.prodegeCode && ref === "prodege") {
      result = val.label
    }
  })
  return result
}

export { parseGender, parseKids, parseLocation, parseMariageStatus, parseWorkingStatus }
