import React, { ReactNode } from 'react'
import { Button } from '../button/button.component'

interface BasicModalProps {
  title: string
  cancelOnClick: () => void
  saveOnClick: () => void
  disableSave?: boolean
  children: ReactNode
  maxWidth: string
}

const BasicModal: React.FC<BasicModalProps> = ({
  title,
  cancelOnClick,
  saveOnClick,
  disableSave = false,
  children,
  maxWidth,
}) => {
  return (
    <div className="relative z-[9999]" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-full text-center sm:p-0 px-5 lg:px-0">
          <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 max-w-xl w-full max-h-[650px] overflow-y-auto mac-style-scrollbar">
            <div className="text-[#414141] p-6">
              <div>
                <div className="text-lg font-semibold text-[#000000DE]">{title}</div>
                <div className="h-[1px] w-full bg-[#E0E0E0] my-4"></div>
                {children}
                <div className="border-b-4 border-dashed border-[#EEEEEE] mt-6"></div>
                <div className="mt-4 flex justify-end gap-1">
                  <Button color={'black'} variant="base" className="font-normal" onClick={cancelOnClick}>
                    {'Cancel'}
                  </Button>
                  <Button variant="contained" onClick={saveOnClick} disabled={disableSave}>
                    SAVE
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { BasicModal }
