import { FC } from 'react'
import { Button } from '../../../../common/button/button.component'
import styles from './add-question.module.css'

interface IAddQuestionComponent {
  setNewQuestion: React.Dispatch<React.SetStateAction<any>>
  projectiveQuestions: []
}

const AddQuestionComponent: FC<IAddQuestionComponent> = ({ projectiveQuestions, setNewQuestion }) => {
  return (
    <>
      <div className="flex justify-between items-center mt-6">
        <h3 className="text-sm font-[500]">Want deeper insights? Check out the AI generated projective questions:</h3>
      </div>
      <div
        className={`flex items-center justify-between flex-wrap gap-2 mt-4 p-2 overflow-y-auto mac-style-scrollbar max-h-[150px] ${styles.chatGuideSbar}`}
      >
        {projectiveQuestions?.map((question: string, index: number) => (
          <div className="border border-light-gray rounded-md p-4 flex justify-between items-center w-full" key={index}>
            <p className="text-sm" data-bolt="projective-question">
              {question}
            </p>
            <Button
              size="xsmall"
              color="green"
              radius="full"
              onClick={() => {
                setNewQuestion({
                  question: question,
                  type: 'TEXT',
                  stimulus: null,
                  punchType: null,
                  options: [],
                })
              }}
              dataBolt="select-projective-question"
            >
              Select
            </Button>
          </div>
        ))}
      </div>
    </>
  )
}

export { AddQuestionComponent }
