import { FC, useEffect, useRef, useState } from 'react'
import { HiPlus } from 'react-icons/hi'
import autoAnimate from '@formkit/auto-animate'
import { Button } from '../../../../common/button/button.component'
import { Setup } from '../../../../../constant/setup'

interface IPollQuestionsComponent {
  disabled: boolean
  question: any
  setQuestion: React.Dispatch<React.SetStateAction<any>>
  error: any
}

const PollQuestionsComponent: FC<IPollQuestionsComponent> = ({ disabled, question, setQuestion, error }) => {
  const [numberOfAnswers, setNumberOfAnswers] = useState(3)
  const parent = useRef(null)

  useEffect(() => {
    parent.current && autoAnimate(parent.current)
  }, [parent])

  useEffect(() => {
    setNumberOfAnswers(question.options?.length === 0 ? 3 : question.options?.length)
  }, [])

  function selectQuestionType() {
    const questions = question
    questions.punchType = questions.punchType === 'SINGLE' ? 'MULTI' : 'SINGLE'
    setQuestion({ ...questions })
  }

  function removeOption(i: number) {
    setNumberOfAnswers(numberOfAnswers - 1)
    const options = [...question.options]
    options.splice(i, 1)
    setQuestion({ ...question, options: [...options] })
  }

  const handleChange = (e: any) => {
    const { value } = e.target
    let questionTitle = question.question
    questionTitle = value
    setQuestion({ ...question, question: questionTitle })
  }

  function handleOptions(i: number, e: any) {
    const { name, value } = e.target
    const options = question.options
    options[i] = { ...options[i], [name]: value }
    setQuestion({ ...question, options: [...options] })
  }

  // add answer
  function addOption() {
    if (numberOfAnswers < Setup.maxNumberOfAnswerPollQuestion) {
      setNumberOfAnswers(numberOfAnswers + 1)

      setQuestion({
        ...question,
        options: [...question.options, { title: '' }],
      })
    }
  }

  const createQuestions = () => {
    return (
      <div className="bg-white rounded">
        <div className="sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:text-left">
            <div className="mb-3 text-black" key={question.length > 1 ? 1 : 0}>
              <div className="relative mt-2">
                <textarea
                  className="border border-[#E0E0E0] rounded-md text-[13px] p-2 resize-none w-full focus:border-bolt-purple"
                  placeholder="Custom Question (i.e. What's your go-to morning hairstyle routine?)"
                  rows={4}
                  name="title"
                  value={question?.question}
                  onChange={(e) => handleChange(e)}
                  disabled={disabled}
                  data-bolt="poll-question"
                ></textarea>
              </div>
              <div className="font-medium text-xs text-red-500" data-bolt="poll-question-error">{error.message}</div>
              <div className="mt-3" ref={parent}>
                <div className="flex gap-40 mb-2">
                  <div className="text-[14px] text-black font-bold">Answers:</div>
                </div>
                {createOptions()}
              </div>

              {numberOfAnswers < Setup.maxNumberOfAnswerPollQuestion && (
                <Button onClick={() => addOption()} variant="outlined" size="small" disabled={disabled} icon={<HiPlus />}>
                  Add Answer
                </Button>
              )}
              <div className="text-sm mt-8">
                <p className="text-black font-semibold">Settings:</p>
                <div className="flex justify-between items-center">
                  <span className="font-light">Allow multiple answer selection:</span>
                  <div>
                    <button className="ml-1 place-self-center" disabled={disabled} onClick={() => selectQuestionType()}>
                      {/* <div className="checkbox-wrapper-4 flex items-center justify-center">
                        <input
                          className="inp-cbx place-self-start"
                          type="checkbox"
                          disabled={disabled}
                          checked={question.punchType === 'SINGLE' || !question.punchType ? false : true}
                          onChange={selectQuestionType}
                        />
                        <label className="cbx" htmlFor="morning">
                          <span>
                            <svg width="12px" height="10px">
                              <use xlinkHref="#check-4"></use>
                            </svg>
                          </span>
                        </label>
                        <span className="w-100 h-100">
                          <svg className="inline-svg" height={24} width={20}>
                            <symbol id="check-4" viewBox="0 0 12 10">
                              <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                            </symbol>
                          </svg>
                        </span>
                      </div> */}
                      <input
                        type="checkbox"
                        className="w-6 h-6 rounded-md text-bolt-purple cursor-pointer accent-bolt-purple disabled:text-bolt-purple disabled:accent-bolt-purple focus:ring-0 border-[0.1px] focus:outline-0 border-opacity-10"
                        disabled={disabled}
                        checked={question.punchType === 'SINGLE' || !question.punchType ? false : true}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const createOptions = () => {
    return Array(numberOfAnswers).map((el, i) => (
      <div className="text-black mt-3 mb-2" key={i}>
        <div className="relative">
          <input
            className="border border-[#E0E0E0] rounded-sm text-[13px] p-3 resize-none w-full h-8 focus:ring-1 focus:outline-0 focus:border focus:border-bolt-purple"
            placeholder={'Answer ' + (i + 1)}
            name="title"
            type="text"
            value={question?.options[i]?.title || ''}
            onChange={(e) => handleOptions(i, e)}
            disabled={disabled}
            data-bolt="poll-question-option"
          />
          <button disabled={disabled} className="absolute top-2 right-2" onClick={() => removeOption(i)}>
            <RemoveIcon />
          </button>
        </div>
      </div>
    ))
  }
  return (
    <div className="bg-white">
      <div className="px-0 w-full">{createQuestions()}</div>
    </div>
  )
}

export { PollQuestionsComponent }

function RemoveIcon() {
  return (
    <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.295 0L3.5 2.795L0.705 0L0 0.705L2.795 3.5L0 6.295L0.705 7L3.5 4.205L6.295 7L7 6.295L4.205 3.5L7 0.705L6.295 0Z"
        fill="black"
      />
    </svg>
  )
}
