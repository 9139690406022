// const baseUrl = process.env.NEXT_PUBLIC_BASE_URL  //! its not working on admin-ui. we need csr solutions
const uatUrl = "https://chat-uat.boltinsight.com"
const localUrl = "http://localhost:3000"
const devUrl = "https://chat-dev.boltinsight.com"
const prodUrl = "https://chat.boltinsight.com"
const baseUrl = devUrl

const pureQualificationBuilder = (project: any) => {
  const countryCode = project?.location?.countrycodes[0]
  const qualification = [
    {
      qualification_code: 212,
      range_sets: [
        {
          from:
            project?.demographics?.agegroup?.from && project?.demographics?.agegroup?.to
              ? Number(project?.demographics?.agegroup?.from)
              : 18,
          to:
            project?.demographics?.agegroup?.from && project?.demographics?.agegroup?.to
              ? Number(project?.demographics?.agegroup?.to)
              : 99,
          units: 311,
        },
      ],
    },
    {
      qualification_code: 219,
      condition_codes: ["112"], // mobile
    },
    ...(window.location.hostname.split(".")[0] !== "chat-dev" &&
      window.location.hostname.split(".")[0] !== "chat-uat" &&
      window.location.hostname !== "localhost"
      ? [
        {
          qualification_code: 2934, // Length of Survey Alert
          condition_codes: ["111"],
        },
      ]
      : []),
  ]
  const schemaCity = {
    qualification_code:
      countryCode === "en_US"
        ? 225
        : countryCode === "en_GB"
          ? 237
          : countryCode === "es_ES"
            ? 286
            : countryCode === "it_IT"
              ? 256
              : countryCode === "de_DE"
                ? 285
                : countryCode === "fr_FR"
                  ? 289
                  : countryCode === "en_CA"
                    ? 1139
                    : countryCode === "fr_CA"
                      ? 1139
                      : countryCode === "vi_VN"
                        ? 240
                        : countryCode === "tr_TR"
                          ? 277
                          : countryCode === "zh_CN"
                            ? 282
                            : countryCode === "en_AU"
                              ? 321
                              : countryCode === "nl_NL"
                                ? 280
                                : countryCode === "es_MX"
                                  ? 251
                                  : countryCode === "hi_IN"
                                    ? 225
                                    : countryCode === "ja_JP"
                                      ? 264
                                      : countryCode === "en_SG"
                                        ? 2444
                                        : countryCode === "th_TH"
                                          ? 306
                                          : countryCode === "id_ID"
                                            ? 259
                                            : countryCode === "pt_BR"
                                              ? 260
                                              : 237,
    condition_codes: project?.location?.citycodes,
  }
  const schemaGender = {
    qualification_code: 211,
    condition_codes: project?.demographics?.gender?.codes,
  }
  const schemaRelationship = {
    qualification_code: 217,
    condition_codes: project?.demographics?.relationship?.codes,
  }
  const schemaChildren = {
    qualification_code: 218,
    condition_codes: project?.demographics?.children?.codes,
  }
  const schemaEmployment = {
    qualification_code: 215,
    condition_codes: project?.demographics?.employment?.codes,
  }

  if (project?.location?.citycodes?.length > 0) {
    qualification.push(schemaCity)
  }
  if (project?.demographics?.gender?.codes?.length > 0) {
    qualification.push(schemaGender)
  }
  if (project?.demographics?.relationship?.codes?.length > 0) {
    qualification.push(schemaRelationship)
  }
  if (project?.demographics?.children?.codes?.length > 0) {
    qualification.push(schemaChildren)
  }
  if (project?.demographics?.employment?.codes?.length > 0) {
    qualification.push(schemaEmployment)
  }
  return qualification
}

const lucidQualificationBuilder = (project: any) => {
  const countryCode = project?.location?.countrycodes[0]
  const haveLucidCityCode = project?.location?.lucidCityCodes.length
  function ageRange(start: number, end: number) {
    return Array(end - start + 1)
      .fill(1)
      .map((_, idx) => String(start + idx))
  }
  const lucidQualifications = [
    {
      Name: "boltchat-alert-v2",
      QuestionID: 192526,
      LogicalOperator: "OR",
      NumberOfRequiredConditions: 1,
      PreCodes: ["1"],
      IsActive: true,
      Order: 1,
      MaxPunch: 1,
    },
    project?.demographics?.agegroup?.from &&
    project?.demographics?.agegroup?.to && {
      Name: "AGE",
      QuestionID: 42,
      LogicalOperator: "OR",
      NumberOfRequiredConditions: 1,
      IsActive: true,
      Order: 2,
      PreCodes: ageRange(Number(project?.demographics?.agegroup?.from), Number(project?.demographics?.agegroup?.to)),
      MaxPunch: -1,
    },
    project?.demographics?.gender?.lucidCodes.length > 0 && {
      Name: "GENDER",
      QuestionID: 43,
      LogicalOperator: "OR",
      NumberOfRequiredConditions: 1,
      IsActive: true,
      Order: 3,
      PreCodes: project?.demographics?.gender?.lucidCodes,
      MaxPunch: 1,
    },
    haveLucidCityCode > 0 && countryCode === "en_GB"
      ? {
        Name: "Fulcrum_Region_UK_NUTS_I",
        QuestionID: 12452,
        LogicalOperator: "OR",
        NumberOfRequiredConditions: 1,
        IsActive: true,
        Order: 4,
        PreCodes: project?.location?.lucidCityCodes,
        MaxPunch: 1,
      }
      : null,
    haveLucidCityCode > 0 && countryCode === "en_US"
      ? {
        Name: "boltchat-state-us",
        QuestionID: 188434,
        LogicalOperator: "OR",
        NumberOfRequiredConditions: 1,
        IsActive: true,
        Order: 4,
        PreCodes: project?.location?.lucidCityCodes,
        MaxPunch: 1,
      }
      : null,
    haveLucidCityCode > 0 && countryCode === "es_ES"
      ? {
        Name: "Fulcrum_Region_ES_NUTS_I",
        QuestionID: 12442,
        LogicalOperator: "OR",
        NumberOfRequiredConditions: 1,
        IsActive: true,
        Order: 4,
        PreCodes: project?.location?.lucidCityCodes,
        MaxPunch: 1,
      }
      : null,
    haveLucidCityCode > 0 && countryCode === "it_IT"
      ? {
        Name: "Fulcrum_Region_IT_NUTS_I",
        QuestionID: 12423,
        LogicalOperator: "OR",
        NumberOfRequiredConditions: 1,
        IsActive: true,
        Order: 4,
        PreCodes: project?.location?.lucidCityCodes,
        MaxPunch: 1,
      }
      : null,
    haveLucidCityCode > 0 && countryCode === "de_DE"
      ? {
        Name: "Fulcrum_Region_DE_NUTS_I",
        QuestionID: 12416,
        LogicalOperator: "OR",
        NumberOfRequiredConditions: 1,
        IsActive: true,
        Order: 4,
        PreCodes: project?.location?.lucidCityCodes,
        MaxPunch: 1,
      }
      : null,
    haveLucidCityCode > 0 && countryCode === "fr_FR"
      ? {
        Name: "Fulcrum_Region_FR_NUTS_II",
        QuestionID: 12414,
        LogicalOperator: "OR",
        NumberOfRequiredConditions: 1,
        IsActive: true,
        Order: 4,
        PreCodes: project?.location?.lucidCityCodes,
        MaxPunch: 1,
      }
      : null,
    haveLucidCityCode > 0 && countryCode === "vi_VN"
      ? {
        Name: "STANDARD_Region_VN",
        QuestionID: 79329,
        LogicalOperator: "OR",
        NumberOfRequiredConditions: 1,
        IsActive: true,
        Order: 4,
        PreCodes: project?.location?.lucidCityCodes,
        MaxPunch: 1,
      }
      : null,
    haveLucidCityCode > 0 && countryCode === "tr_TR"
      ? {
        Name: "Fulcrum_Region_TR_Region",
        QuestionID: 12455,
        LogicalOperator: "OR",
        NumberOfRequiredConditions: 1,
        IsActive: true,
        Order: 4,
        PreCodes: project?.location?.lucidCityCodes,
        MaxPunch: 1,
      }
      : null,
    haveLucidCityCode > 0 && countryCode === "zh_CN"
      ? {
        Name: "Fulcrum_Region_CN_Trad",
        QuestionID: 12407,
        LogicalOperator: "OR",
        NumberOfRequiredConditions: 1,
        IsActive: true,
        Order: 4,
        PreCodes: project?.location?.lucidCityCodes,
        MaxPunch: 1,
      }
      : null,
    haveLucidCityCode > 0 && countryCode === "en_AU"
      ? {
        Name: "STANDARD_State_AU",
        QuestionID: 79397,
        LogicalOperator: "OR",
        NumberOfRequiredConditions: 1,
        IsActive: true,
        Order: 4,
        PreCodes: project?.location?.lucidCityCodes,
        MaxPunch: 1,
      }
      : null,
    haveLucidCityCode > 0 && countryCode === "nl_NL"
      ? {
        Name: "Fulcrum_Region_NL_NUTS_II",
        QuestionID: 12430,
        LogicalOperator: "OR",
        NumberOfRequiredConditions: 1,
        IsActive: true,
        Order: 4,
        PreCodes: project?.location?.lucidCityCodes,
        MaxPunch: 1,
      }
      : null,
    haveLucidCityCode > 0 && countryCode === "es_MX"
      ? {
        Name: "STANDARD_State_MX",
        QuestionID: 79401,
        LogicalOperator: "OR",
        NumberOfRequiredConditions: 1,
        IsActive: true,
        Order: 4,
        PreCodes: project?.location?.lucidCityCodes,
        MaxPunch: 1,
      }
      : null,
    haveLucidCityCode > 0 && countryCode === "hi_IN"
      ? {
        Name: "STANDARD_State_IN",
        QuestionID: 79400,
        LogicalOperator: "OR",
        NumberOfRequiredConditions: 1,
        IsActive: true,
        Order: 4,
        PreCodes: project?.location?.lucidCityCodes,
        MaxPunch: 1,
      }
      : null,
    haveLucidCityCode > 0 && countryCode === "ja_JP"
      ? {
        Name: "STANDARD_Region_JP",
        QuestionID: 79392,
        LogicalOperator: "OR",
        NumberOfRequiredConditions: 1,
        IsActive: true,
        Order: 4,
        PreCodes: project?.location?.lucidCityCodes,
        MaxPunch: 1,
      }
      : null,
    haveLucidCityCode > 0 && countryCode === "en_SG"
      ? {
        Name: "STANDARD_Region_SG",
        QuestionID: 79394,
        LogicalOperator: "OR",
        NumberOfRequiredConditions: 1,
        IsActive: true,
        Order: 4,
        PreCodes: project?.location?.lucidCityCodes,
        MaxPunch: 1,
      }
      : null,
    haveLucidCityCode > 0 && countryCode === "th_TH"
      ? {
        Name: "Fulcrum_Region_TH_Region",
        QuestionID: 19452,
        LogicalOperator: "OR",
        NumberOfRequiredConditions: 1,
        IsActive: true,
        Order: 4,
        PreCodes: project?.location?.lucidCityCodes,
        MaxPunch: 1,
      }
      : null,

    haveLucidCityCode > 0 && countryCode === "id_ID"
      ? {
        Name: "STANDARD_Province_ID",
        QuestionID: 79381,
        LogicalOperator: "OR",
        NumberOfRequiredConditions: 1,
        IsActive: true,
        Order: 4,
        PreCodes: project?.location?.lucidCityCodes,
        MaxPunch: 1,
      }
      : null,
    haveLucidCityCode > 0 && countryCode === "pt_BR"
      ? {
        Name: "STANDARD_State_BR",
        QuestionID: 79398,
        LogicalOperator: "OR",
        NumberOfRequiredConditions: 1,
        IsActive: true,
        Order: 4,
        PreCodes: project?.location?.lucidCityCodes,
        MaxPunch: 1,
      }
      : null,
    project?.demographics?.relationship?.lucidCodes.length > 0 && {
      Name: "STANDARD_RELATIONSHIP",
      QuestionID: 632,
      LogicalOperator: "OR",
      NumberOfRequiredConditions: 1,
      IsActive: true,
      Order: 5,
      PreCodes: project?.demographics?.relationship?.lucidCodes,
      MaxPunch: 1,
    },
    project?.demographics?.children?.lucidCodes.length > 0 && {
      Name: "Parental_Status_Standard",
      QuestionID: 7064,
      LogicalOperator: "OR",
      NumberOfRequiredConditions: 1,
      IsActive: true,
      Order: 6,
      PreCodes: project?.demographics?.children?.lucidCodes,
      MaxPunch: 12,
    },
    project?.demographics?.employment?.lucidCodes.length > 0 && {
      Name: "STANDARD_EMPLOYMENT",
      QuestionID: 2189,
      LogicalOperator: "OR",
      NumberOfRequiredConditions: 1,
      IsActive: true,
      Order: 7,
      PreCodes: project?.demographics?.employment?.lucidCodes,
      MaxPunch: 1,
    },
    {
      Name: "MS_is_Mobile",
      QuestionID: 8214,
      LogicalOperator: "OR",
      NumberOfRequiredConditions: 1,
      IsActive: true,
      Order: 7,
      PreCodes: ["true"],
      MaxPunch: 1,
    },
  ]
  return lucidQualifications
}

const urlBuilder = (project: any, projectId: string) => {
  const country = project?.setups[0]?.steps?.location?.countrycodes[0]
  const url = `${baseUrl}api/v1/chat-core/participant/participant-start?lang=${country === "en_GB"
    ? "en"
    : country === "en_US"
      ? "en"
      : country === "ar_AE"
        ? "ar"
        : country === "fr_FR"
          ? "fr"
          : country === "de_DE"
            ? "de"
            : country === "it_IT"
              ? "it"
              : country === "es_ES"
                ? "es"
                : country === "en_CA"
                  ? "en"
                  : country === "fr_CA"
                    ? "fr"
                    : country === "vi_VN"
                      ? "vi"
                      : country === "tr_TR"
                        ? "tr"
                        : country === "zh_CN"
                          ? "zh"
                          : country === "en_AU"
                            ? "en"
                            : country === "nl_NL"
                              ? "nl"
                              : country === "es_MX"
                                ? "es-mx"
                                : country === "hi_IN"
                                  ? "hi"
                                  : country === "en_ZA"
                                    ? "en"
                                    : country === "ar_SA"
                                      ? "ar"
                                      : country === "ja_JP"
                                        ? "ja"
                                        : country === "en_SG"
                                          ? "en"
                                          : country === "th_TH"
                                            ? "th"
                                            : country === "id_ID"
                                              ? "id"
                                              : country === "pt_BR"
                                                ? "pt-br"
                                                : country === "ar_EG"
                                                  ? "ar"
                                                  : country === "kk_KZ"
                                                    ? "kk"
                                                    : country === "de_CH"
                                                      ? "de"
                                                      : country === "fr_CH"
                                                        ? "fr"
                                                        : country === "pl_PL"
                                                          ? "pl"
                                                          : country === "ro_RO"
                                                            ? "ro"
                                                            : country === "en_HK"
                                                              ? "en"
                                                              : country === "en_IE"
                                                                ? "en"
                                                                : country === "pt_PT"
                                                                  ? "pt"
                                                                  : country === "es_CL"
                                                                    ? "es"
                                                                    : country === "es_CO"
                                                                      ? "pt"
                                                                      : country === "tl_PH"
                                                                        ? "tl"
                                                                        : country === "zh_TW"
                                                                          ? "zh-tw"
                                                                          : country === "sv_SE"
                                                                            ? "sv"
                                                                            : country === "ko_KR"
                                                                              ? "ko"
                                                                              : country === "ms_MY"
                                                                                ? "ms"
                                                                                : country === "cs_CZ"
                                                                                  ? "cs"
                                                                                  : "en"
    }&projectId=${projectId}&cid=${country}`
  return url
}

const buildCountryCode = (country: string) => {
  let countryCode
  country === "United Kingdom"
    ? (countryCode = ["en_GB"])
    : country === "United States"
      ? (countryCode = ["en_US"])
      : country === "United Arab Emirates"
        ? (countryCode = ["ar_AE"])
        : country === "Canada"
          ? (countryCode = ["en_CA"])
          : country === "Canada (French)"
            ? (countryCode = ["fr_CA"])
            : country === "Spain"
              ? (countryCode = ["es_ES"])
              : country === "Italy"
                ? (countryCode = ["it_IT"])
                : country === "France"
                  ? (countryCode = ["fr_FR"])
                  : country === "Germany"
                    ? (countryCode = ["de_DE"])
                    : country === "Vietnam"
                      ? (countryCode = ["vi_VN"])
                      : country === "Turkey"
                        ? (countryCode = ["tr_TR"])
                        : country === "China"
                          ? (countryCode = ["zh_CN"])
                          : country === "Australia"
                            ? (countryCode = ["en_AU"])
                            : country === "Netherlands"
                              ? (countryCode = ["nl_NL"])
                              : country === "Mexico"
                                ? (countryCode = ["es_MX"])
                                : country === "India"
                                  ? (countryCode = ["hi_IN"])
                                  : country === "South Africa"
                                    ? (countryCode = ["en_ZA"])
                                    : country === "Saudi Arabia"
                                      ? (countryCode = ["ar_SA"])
                                      : country === "Japan"
                                        ? (countryCode = ["ja_JP"])
                                        : country === "Singapore"
                                          ? (countryCode = ["en_SG"])
                                          : country === "Thailand"
                                            ? (countryCode = ["th_TH"])
                                            : country === "Indonesia"
                                              ? (countryCode = ["id_ID"])
                                              : country === "Brazil"
                                                ? (countryCode = ["pt_BR"])
                                                : country === "Kazakhstan"
                                                  ? (countryCode = ["kk_KZ"])
                                                  : country === "Egypt"
                                                    ? (countryCode = ["ar_EG"])
                                                    : country === "Switzerland"
                                                      ? (countryCode = ["de_CH"])
                                                      : country === "Switzerland (French)"
                                                        ? (countryCode = ["fr_CH"])
                                                        : country === "Poland"
                                                          ? (countryCode = ["pl_PL"])
                                                          : country === "Romania"
                                                            ? (countryCode = ["ro_RO"])
                                                            : country === "Hong Kong"
                                                              ? (countryCode = ["en_HK"])
                                                              : country === "Ireland"
                                                                ? (countryCode = ["en_IE"])
                                                                : country === "Portugal"
                                                                  ? (countryCode = ["pt_PT"])
                                                                  : country === "Chile"
                                                                    ? (countryCode = ["es_CL"])
                                                                    : country === "Colombia"
                                                                      ? (countryCode = ["es_CO"])
                                                                      : country === "Philippines"
                                                                        ? (countryCode = ["tl_PH"])
                                                                        : country === "Taiwan"
                                                                          ? (countryCode = ["zh_TW"])
                                                                          : country === "Sweden"
                                                                            ? (countryCode = ["sv_SE"])
                                                                            : country === "South Korea"
                                                                              ? (countryCode = ["ko_KR"])
                                                                              : country === "Malaysia"
                                                                                ? (countryCode = ["ms_MY"])
                                                                                : country === "Czech Republic"
                                                                                  ? (countryCode = ["cs_CZ"])
                                                                                  : (countryCode = ["en_GB"])
  return countryCode
}

const lucidUrlBuilder = (live_url: string, lang: string) => {
  return `${live_url}&ref=lucid${lang === "en_GB"
    ? "&state=[%Fulcrum_Region_UK_NUTS_I%]"
    : lang === "en_US"
      ? "&state=[%boltchat-state-us%]"
      : lang === "fr_FR"
        ? "&state=[%Fulcrum_Region_FR_NUTS_II%]"
        : lang === "de_DE"
          ? "&state=[%Fulcrum_Region_DE_NUTS_I%]"
          : lang === "it_IT"
            ? "&state=[%Fulcrum_Region_IT_NUTS_I%]"
            : lang === "es_ES"
              ? "&state=[%Fulcrum_Region_ES_NUTS_I%]"
              : lang === "vi_VN"
                ? "&state=[%STANDARD_Region_VN%]"
                : lang === "tr_TR"
                  ? "&state=[%Fulcrum_Region_TR_Region%]"
                  : lang === "zh_CN"
                    ? "&state=[%Fulcrum_Region_CN_Trad%]"
                    : lang === "en_AU"
                      ? "&state=[%STANDARD_State_AU%]"
                      : lang === "nl_NL"
                        ? "&state=[%Fulcrum_Region_NL_NUTS_II%]"
                        : lang === "es_MX"
                          ? "&state=[%STANDARD_State_MX%]"
                          : lang === "hi_IN"
                            ? "&state=[%STANDARD_State_IN%]"
                            : lang === "ja_JP"
                              ? "&state=[%STANDARD_Region_JP%]"
                              : lang === "en_SG"
                                ? "&state=[%STANDARD_Region_SG%]"
                                : lang === "th_TH"
                                  ? "&state=[%Fulcrum_Region_TH_Region%]"
                                  : lang === "id_ID"
                                    ? "&state=[%STANDARD_Province_ID%]"
                                    : lang === "pt_BR"
                                      ? "&state=[%STANDARD_State_BR%]"
                                      : ""
    }&relationship=[%STANDARD_RELATIONSHIP%]&employment=[%STANDARD_EMPLOYMENT%]&age=[%AGE%]&gender=[%GENDER%]&isMobile=[%MS_is_mobile%]&hasKid=[%Parental_Status_Standard%]&RID=[%RID%]`
}

const lucidCountryIdBuilder = (lang: string) => {
  return lang === "en_GB"
    ? 8
    : lang === "en_US"
      ? 9
      : lang === "ar_AE"
        ? 82
        : lang === "fr_FR"
          ? 10
          : lang === "de_DE"
            ? 11
            : lang === "it_IT"
              ? 13
              : lang === "es_ES"
                ? 22
                : lang === "en_CA"
                  ? 6
                  : lang === "fr_CA"
                    ? 25
                    : lang === "vi_VN"
                      ? 81
                      : lang === "tr_TR"
                        ? 37
                        : lang === "zh_CN"
                          ? 1
                          : lang === "en_AU"
                            ? 5
                            : lang === "nl_NL"
                              ? 4
                              : lang === "es_MX"
                                ? 21
                                : lang === "hi_IN"
                                  ? 76
                                  : lang === "en_ZA"
                                    ? 49
                                    : lang === "ar_SA"
                                      ? 29
                                      : lang === "ja_JP"
                                        ? 14
                                        : lang === "en_SG"
                                          ? 50
                                          : lang === "th_TH"
                                            ? 54
                                            : lang === "id_ID"
                                              ? 52
                                              : lang === "pt_BR"
                                                ? 16
                                                : lang === "ar_EG"
                                                  ? 77
                                                  : lang === "de_CH"
                                                    ? 12
                                                    : lang === "fr_CH"
                                                      ? 34
                                                      : lang === "kk_KZ"
                                                        ? 276
                                                        : lang === "pl_PL"
                                                          ? 15
                                                          : lang === "ro_RO"
                                                            ? 45
                                                            : lang === "en_HK"
                                                              ? 73
                                                              : lang === "en_IE"
                                                                ? 43
                                                                : lang === "pt_PT"
                                                                  ? 17
                                                                  : lang === "es_CL"
                                                                    ? 47
                                                                    : lang === "es_CO"
                                                                      ? 20
                                                                      : lang === "tl_PH"
                                                                        ? 58
                                                                        : lang === "zh_TW"
                                                                          ? 3
                                                                          : lang === "sv_SE"
                                                                            ? 23
                                                                            : lang === "ms_MY"
                                                                              ? 53
                                                                              : lang === 'cs_CZ'
                                                                                ? 39
                                                                                : 8
}

const audienceUrlBuilder = (countryCode: string, projectId: string, platform: string) => {
  const url = `${platform === "uat" ? uatUrl : platform === "dev" ? devUrl : platform === "local" ? localUrl : prodUrl
    }/api/recruit/start?ref=pure&lang=${countryCode === "en_GB"
      ? "en"
      : countryCode === "en_US"
        ? "en"
        : countryCode === "ar_AE"
          ? "ar"
          : countryCode === "fr_FR"
            ? "fr"
            : countryCode === "de_DE"
              ? "de"
              : countryCode === "it_IT"
                ? "it"
                : countryCode === "es_ES"
                  ? "es"
                  : countryCode === "en_CA"
                    ? "en"
                    : countryCode === "fr_CA"
                      ? "fr"
                      : countryCode === "vi_VN"
                        ? "vi"
                        : countryCode === "tr_TR"
                          ? "tr"
                          : countryCode === "zh_CN"
                            ? "zh"
                            : countryCode === "en_AU"
                              ? "en"
                              : countryCode === "nl_NL"
                                ? "nl"
                                : countryCode === "es_MX"
                                  ? "es-mx"
                                  : countryCode === "hi_IN"
                                    ? "hi"
                                    : countryCode === "en_ZA"
                                      ? "en"
                                      : countryCode === "ar_SA"
                                        ? "ar"
                                        : countryCode === "ja_JP"
                                          ? "ja"
                                          : countryCode === "en_SG"
                                            ? "en"
                                            : countryCode === "th_TH"
                                              ? "th"
                                              : countryCode === "id_ID"
                                                ? "id"
                                                : countryCode === "pt_BR"
                                                  ? "pt-br"
                                                  : countryCode === "ar_EG"
                                                    ? "ar"
                                                    : countryCode === "kk_KZ"
                                                      ? "kk"
                                                      : countryCode === "de_CH"
                                                        ? "de"
                                                        : countryCode === "fr_CH"
                                                          ? "fr"
                                                          : countryCode === "pl_PL"
                                                            ? "pl"
                                                            : countryCode === "ro_RO"
                                                              ? "ro"
                                                              : countryCode === "en_HK"
                                                                ? "en"
                                                                : countryCode === "en_IE"
                                                                  ? "en"
                                                                  : countryCode === "pt_PT"
                                                                    ? "pt"
                                                                    : countryCode === "es_CL"
                                                                      ? "es"
                                                                      : countryCode === "es_CO"
                                                                        ? "es"
                                                                        : countryCode === "tl_PH"
                                                                          ? "en"
                                                                          : countryCode === "zh_TW"
                                                                            ? "zh-tw"
                                                                            : countryCode === "sv_SE"
                                                                              ? "sv"
                                                                              : countryCode === "ms_MY"
                                                                                ? "ms"
                                                                                : countryCode === 'cs_CZ'
                                                                                  ? "cs"
                                                                                  : "en"
    }&projectId=${projectId}&cid=${countryCode}&age=21&gender=111&relationship=114&hasKid=112&employment=114&education=115&region=111&test=1`
  return url
}

const lucidCountryCode = (country: string | undefined | null) => {
  return country === "United Kingdom"
    ? "8"
    : country === "United States"
      ? "9"
      : country === "United Arab Emirates"
        ? "82"
        : country === "Spain"
          ? "22"
          : country === "Italy"
            ? "13"
            : country === "Germany"
              ? "11"
              : country === "France"
                ? "10"
                : country === "Canada"
                  ? "6"
                  : country === "Canada (French)"
                    ? "25"
                    : country === "Vietnam"
                      ? "81"
                      : country === "Turkey"
                        ? "37"
                        : country === "China"
                          ? "1"
                          : country === "Australia"
                            ? "5"
                            : country === "Netherlands"
                              ? "4"
                              : country === "Mexico"
                                ? "21"
                                : country === "India"
                                  ? "76"
                                  : country === "South Africa"
                                    ? "49"
                                    : country === "Saudi Arabia"
                                      ? "29"
                                      : country === "Japan"
                                        ? "14"
                                        : country === "Singapore"
                                          ? "50"
                                          : country === "Thailand"
                                            ? "54"
                                            : country === "Indonesia"
                                              ? "52"
                                              : country === "Brazil"
                                                ? "16"
                                                : country === "Egypt"
                                                  ? "77"
                                                  : country === "Switzerland"
                                                    ? "12"
                                                    : country === "Switzerland (French)"
                                                      ? "34"
                                                      : country === "Kazakhstan"
                                                        ? "276"
                                                        : country === "Poland"
                                                          ? "15"
                                                          : country === "Romania"
                                                            ? "45"
                                                            : country === "Hong Kong"
                                                              ? "73"
                                                              : country === "Ireland"
                                                                ? "43"
                                                                : country === "Portugal"
                                                                  ? "17"
                                                                  : country === "Chile"
                                                                    ? "47"
                                                                    : country === "Colombia"
                                                                      ? "20"
                                                                      : country === "Philippines"
                                                                        ? "58"
                                                                        : country === "Taiwan"
                                                                          ? "3"
                                                                          : country === "Sweden"
                                                                            ? "23"
                                                                            : country === "South Korea"
                                                                              ? "24"
                                                                              : country === "Malaysia"
                                                                                ? "53"
                                                                                : country === "Czech Republic"
                                                                                  ? "39"
                                                                                  : null
}
export {
  pureQualificationBuilder,
  lucidQualificationBuilder,
  urlBuilder,
  buildCountryCode,
  lucidUrlBuilder,
  lucidCountryIdBuilder,
  audienceUrlBuilder,
  lucidCountryCode,
}
