export const countries = [
  {
    label: "United Kingdom",
    code: "en_GB",
    cities: [
      {
        label: "London",
        pureCode: "111",
        lucidCode: "3",
        prodegeCode: "3",
      },
      {
        label: "South East",
        pureCode: "112",
        lucidCode: "8",
        prodegeCode: "8",
      },
      {
        label: "East of England",
        pureCode: "113",
        lucidCode: "2",
        prodegeCode: "2",
      },
      {
        label: "South West",
        pureCode: "114",
        lucidCode: "9",
        prodegeCode: "9",
      },
      {
        label: "North West",
        pureCode: "115",
        lucidCode: "5",
        prodegeCode: "5",
      },
      {
        label: "East Midlands",
        pureCode: "116",
        lucidCode: "1",
        prodegeCode: "1",
      },
      {
        label: "West Midlands",
        pureCode: "117",
        lucidCode: "11",
        prodegeCode: "11",
      },
      {
        label: "Yorkshire and the Humber",
        pureCode: "118",
        lucidCode: "12",
        prodegeCode: "12",
      },
      {
        label: "North East",
        pureCode: "119",
        lucidCode: "4",
        prodegeCode: "4",
      },
      {
        label: "Wales",
        pureCode: "120",
        lucidCode: "10",
        prodegeCode: "10",
      },
      {
        label: "Scotland",
        pureCode: "121",
        lucidCode: "7",
        prodegeCode: "7",
      },
      {
        label: "Northern Ireland",
        pureCode: "122",
        lucidCode: "6",
        prodegeCode: "6",
      },
    ],
  },
  {
    label: "United States",
    code: "en_US",
    cities: [
      {
        label: "Alabama",
        pureCode: "1",
        lucidCode: "1",
        prodegeCode: "1",
      },
      {
        label: "Alaska",
        pureCode: "2",
        lucidCode: "2",
        prodegeCode: "2",
      },
      {
        label: "Hampshire",
        pureCode: "33",
        lucidCode: "30",
        prodegeCode: "30",
      },
      {
        label: "New Jersey",
        pureCode: "34",
        lucidCode: "31",
        prodegeCode: "31",
      },
      {
        label: "New York",
        pureCode: "36",
        lucidCode: "33",
        prodegeCode: "33",
      },
      {
        label: "Massachusetts",
        pureCode: "25",
        lucidCode: "22",
        prodegeCode: "22",
      },
      {
        label: "Connecticut",
        pureCode: "9",
        lucidCode: "7",
        prodegeCode: "7",
      },
      {
        label: "Maine",
        pureCode: "23",
        lucidCode: "20",
        prodegeCode: "20",
      },
      {
        label: "Rhode Island",
        pureCode: "44",
        lucidCode: "40",
        prodegeCode: "40",
      },
      {
        label: "Vermont",
        pureCode: "50",
        lucidCode: "46",
        prodegeCode: "46",
      },
      {
        label: "Pennsylvania",
        pureCode: "42",
        lucidCode: "39",
        prodegeCode: "39",
      },
      {
        label: "Delaware",
        pureCode: "10",
        lucidCode: "8",
        prodegeCode: "8",
      },
      {
        label: "District of Columbia",
        pureCode: "11",
        lucidCode: "9",
        prodegeCode: "9",
      },
      {
        label: "Virginia",
        pureCode: "51",
        lucidCode: "47",
        prodegeCode: "47",
      },
      {
        label: "Maryland",
        pureCode: "24",
        lucidCode: "21",
        prodegeCode: "21",
      },
      {
        label: "North Carolina",
        pureCode: "37",
        lucidCode: "34",
        prodegeCode: "34",
      },
      {
        label: "South Carolina",
        pureCode: "45",
        lucidCode: "41",
        prodegeCode: "41",
      },
      {
        label: "Georgia",
        pureCode: "13",
        lucidCode: "11",
        prodegeCode: "11",
      },
      {
        label: "West Virginia",
        pureCode: "54",
        lucidCode: "49",
        prodegeCode: "49",
      },
      {
        label: "Florida",
        pureCode: "12",
        lucidCode: "10",
        prodegeCode: "10",
      },
      {
        label: "Kentucky",
        pureCode: "21",
        lucidCode: "18",
        prodegeCode: "18",
      },
      {
        label: "Ohio",
        pureCode: "39",
        lucidCode: "36",
        prodegeCode: "36",
      },
      {
        label: "Mississippi",
        pureCode: "28",
        lucidCode: "25",
        prodegeCode: "25",
      },
      {
        label: "Tennessee",
        pureCode: "47",
        lucidCode: "43",
        prodegeCode: "43",
      },
      {
        label: "Iowa",
        pureCode: "19",
        lucidCode: "16",
        prodegeCode: "16",
      },
      {
        label: "Wisconsin",
        pureCode: "55",
        lucidCode: "50",
        prodegeCode: "50",
      },
      {
        label: "Michigan",
        pureCode: "26",
        lucidCode: "23",
        prodegeCode: "23",
      },
      {
        label: "Minnesota",
        pureCode: "27",
        lucidCode: "24",
        prodegeCode: "24",
      },
      {
        label: "Indiana",
        pureCode: "18",
        lucidCode: "15",
        prodegeCode: "15",
      },
      {
        label: "South Dakota",
        pureCode: "46",
        lucidCode: "42",
        prodegeCode: "42",
      },
      {
        label: "North Dakota",
        pureCode: "38",
        lucidCode: "35",
        prodegeCode: "35",
      },
      {
        label: "Montana",
        pureCode: "30",
        lucidCode: "27",
        prodegeCode: "27",
      },
      {
        label: "Nebraska",
        pureCode: "31",
        lucidCode: "28",
        prodegeCode: "28",
      },
      {
        label: "Louisiana",
        pureCode: "22",
        lucidCode: "19",
        prodegeCode: "19",
      },
      {
        label: "Arkansas",
        pureCode: "5",
        lucidCode: "4",
        prodegeCode: "4",
      },
      {
        label: "Illinois",
        pureCode: "17",
        lucidCode: "14",
        prodegeCode: "14",
      },
      {
        label: "Kansas",
        pureCode: "20",
        lucidCode: "17",
        prodegeCode: "17",
      },
      {
        label: "Missouri",
        pureCode: "29",
        lucidCode: "26",
        prodegeCode: "26",
      },
      {
        label: "Oklahoma",
        pureCode: "40",
        lucidCode: "37",
        prodegeCode: "37",
      },
      {
        label: "Texas",
        pureCode: "48",
        lucidCode: "44",
        prodegeCode: "44",
      },
      {
        label: "Idaho",
        pureCode: "16",
        lucidCode: "13",
        prodegeCode: "13",
      },
      {
        label: "Utah",
        pureCode: "49",
        lucidCode: "45",
        prodegeCode: "45",
      },
      {
        label: "Arizona",
        pureCode: "4",
        lucidCode: "3",
        prodegeCode: "3",
      },
      {
        label: "California",
        pureCode: "6",
        lucidCode: "5",
        prodegeCode: "5",
      },
      {
        label: "Washington",
        pureCode: "53",
        lucidCode: "48",
        prodegeCode: "48",
      },
      {
        label: "Colorado",
        pureCode: "8",
        lucidCode: "6",
        prodegeCode: "6",
      },
      {
        label: "Wyoming",
        pureCode: "56",
        lucidCode: "51",
        prodegeCode: "51",
      },
      {
        label: "New Mexico",
        pureCode: "35",
        lucidCode: "32",
        prodegeCode: "32",
      },
      {
        label: "Nevada",
        pureCode: "32",
        lucidCode: "29",
        prodegeCode: "29",
      },
      {
        label: "Hawaii",
        pureCode: "15",
        lucidCode: "12",
        prodegeCode: "12",
      },
      {
        label: "Oregon",
        pureCode: "41",
        lucidCode: "38",
        prodegeCode: "38",
      },
    ],
  },
  {
    label: "United Arab Emirates",
    code: "ar_AE",
    cities: [],
  },
  {
    label: "Spain",
    code: "es_ES",
    cities: [
      {
        label: "Canarias",
        pureCode: "110",
        lucidCode: "1",
        prodegeCode: "1",
      },
      {
        label: "Centro",
        pureCode: "111",
        lucidCode: "2",
        prodegeCode: "2",
      },
      {
        label: "Comunidad de Madrid",
        pureCode: "112",
        lucidCode: "3",
        prodegeCode: "3",
      },
      {
        label: "Este",
        pureCode: "113",
        lucidCode: "4",
        prodegeCode: "4",
      },
      {
        label: "Noreste",
        pureCode: "114",
        lucidCode: "5",
        prodegeCode: "5",
      },
      {
        label: "Noroeste",
        pureCode: "115",
        lucidCode: "6",
        prodegeCode: "6",
      },
      {
        label: "Sur",
        pureCode: "116",
        lucidCode: "7",
        prodegeCode: "7",
      },
    ],
  },
  {
    label: "Italy",
    code: "it_IT",
    cities: [],
  },
  {
    label: "Germany",
    code: "de_DE",
    cities: [
      {
        label: "Baden-Württemberg",
        pureCode: "111",
        lucidCode: "1",
        prodegeCode: "1",
      },
      {
        label: "Bayern",
        pureCode: "112",
        lucidCode: "2",
        prodegeCode: "2",
      },
      {
        label: "Berlin",
        pureCode: "113",
        lucidCode: "3",
        prodegeCode: "3",
      },
      {
        label: "Brandenburg",
        pureCode: "114",
        lucidCode: "4",
        prodegeCode: "4",
      },
      {
        label: "Bremen",
        pureCode: "115",
        lucidCode: "5",
        prodegeCode: "5",
      },
      {
        label: "Hamburg",
        pureCode: "116",
        lucidCode: "6",
        prodegeCode: "6",
      },
      {
        label: "Hessen",
        pureCode: "117",
        lucidCode: "7",
        prodegeCode: "7",
      },
      {
        label: "Mecklenburg-Vorpommern",
        pureCode: "118",
        lucidCode: "8",
        prodegeCode: "8",
      },
      {
        label: "Niedersachsen",
        pureCode: "119",
        lucidCode: "9",
        prodegeCode: "9",
      },
      {
        label: "Nordrhein-Westfalen",
        pureCode: "120",
        lucidCode: "10",
        prodegeCode: "10",
      },
      {
        label: "Rheinland-Pfalz",
        pureCode: "121",
        lucidCode: "11",
        prodegeCode: "11",
      },
      {
        label: "Saarland",
        pureCode: "122",
        lucidCode: "12",
        prodegeCode: "12",
      },
      {
        label: "Sachsen",
        pureCode: "123",
        lucidCode: "13",
        prodegeCode: "13",
      },
      {
        label: "Sachsen-Anhalt",
        pureCode: "124",
        lucidCode: "14",
        prodegeCode: "14",
      },
      {
        label: "Schleswig-Holstein",
        pureCode: "125",
        lucidCode: "15",
        prodegeCode: "15",
      },
      {
        label: "Thüringen",
        pureCode: "126",
        lucidCode: "16",
        prodegeCode: "16",
      },
    ],
  },
  {
    label: "France",
    code: "fr_FR",
    cities: [
      {
        label: "Alsace",
        pureCode: "119",
        lucidCode: "1",
        prodegeCode: "1",
      },
      {
        label: "Aquitaine",
        pureCode: "128",
        lucidCode: "2",
        prodegeCode: "2",
      },
      {
        label: "Auvergne",
        pureCode: "112",
        lucidCode: "3",
        prodegeCode: "3",
      },
      {
        label: "Bourgogne",
        pureCode: "113",
        lucidCode: "4",
        prodegeCode: "4",
      },
      {
        label: "Bretagne",
        pureCode: "115",
        lucidCode: "5",
        prodegeCode: "5",
      },
      {
        label: "Centre",
        pureCode: "116",
        lucidCode: "6",
        prodegeCode: "6",
      },
      {
        label: "Champagne-Ardenne",
        pureCode: "118",
        lucidCode: "7",
        prodegeCode: "7",
      },
      {
        label: "Corse",
        pureCode: "117",
        lucidCode: "8",
        prodegeCode: "8",
      },
      {
        label: "Franche-Comté",
        pureCode: "114",
        lucidCode: "9",
        prodegeCode: "9",
      },
      {
        label: "Île-de-France",
        pureCode: "123",
        lucidCode: "12",
        prodegeCode: "12",
      },
      {
        label: "Languedoc-Roussillon",
        pureCode: "130",
        lucidCode: "13",
        prodegeCode: "13",
      },
      {
        label: "Limousin",
        pureCode: "127",
        lucidCode: "14",
        prodegeCode: "14",
      },
      {
        label: "Lorraine",
        pureCode: "120",
        lucidCode: "15",
        prodegeCode: "15",
      },
      {
        label: "Mayotte",
        pureCode: "114",
        lucidCode: "17",
        prodegeCode: "17",
      },
      {
        label: "Midi-Pyrénées",
        pureCode: "129",
        lucidCode: "18",
        prodegeCode: "18",
      },
      {
        label: "Nord-Pas-de-Calais",
        pureCode: "122",
        lucidCode: "19",
        prodegeCode: "19",
      },
      {
        label: "Basse-Normandie",
        pureCode: "124",
        lucidCode: "20",
        prodegeCode: "20",
      },
      {
        label: "Haute-Normandie",
        pureCode: "125",
        lucidCode: "21",
        prodegeCode: "21",
      },
      {
        label: "PAYS de la Loire",
        pureCode: "131",
        lucidCode: "22",
        prodegeCode: "22",
      },
      {
        label: "Picardie",
        pureCode: "121",
        lucidCode: "23",
        prodegeCode: "23",
      },
      {
        label: "Poitou-Charentes",
        pureCode: "126",
        lucidCode: "24",
        prodegeCode: "24",
      },
      {
        label: "Provence-Alpes-Côte d'Azur",
        pureCode: "132",
        lucidCode: "25",
        prodegeCode: "25",
      },
      {
        label: "Rhône-Alpes",
        pureCode: "111",
        lucidCode: "27",
        prodegeCode: "27",
      },
    ],
  },
  {
    label: "Canada",
    code: "en_CA",
    cities: [],
  },
  {
    label: "Canada (French)",
    code: "fr_CA",
    cities: [],
  },
  {
    label: "Vietnam",
    code: "vi_VN",
    cities: [],
  },
  {
    label: "Turkey",
    code: "tr_TR",
    cities: [],
  },
  {
    label: "China",
    code: "zh_CN",
    cities: [],
  },
  {
    label: "Australia",
    code: "en_AU",
    cities: [
      {
        label: "Australian Capital Territory",
        pureCode: "111",
        lucidCode: "1",
        prodegeCode: "1",
      },
      {
        label: "New South Wales",
        pureCode: "113",
        lucidCode: "5",
        prodegeCode: "5",
      },
      {
        label: "Northern Territory",
        pureCode: "114",
        lucidCode: "6",
        prodegeCode: "7",
      },
      {
        label: "Queensland",
        pureCode: "115",
        lucidCode: "7",
        prodegeCode: "8",
      },
      {
        label: "South Australia",
        pureCode: "116",
        lucidCode: "8",
        prodegeCode: "9",
      },
      {
        label: "Tasmania",
        pureCode: "117",
        lucidCode: "9",
        prodegeCode: "10",
      },
      {
        label: "Victoria",
        pureCode: "118",
        lucidCode: "10",
        prodegeCode: "11",
      },
      {
        label: "Western Australia",
        pureCode: "119",
        lucidCode: "11",
        prodegeCode: "12",
      },
    ],
  },
  {
    label: "Netherlands",
    code: "nl_NL",
    cities: [],
  },
  {
    label: "Mexico",
    code: "es_MX",
    cities: [],
  },
  {
    label: "India",
    code: "hi_IN",
    cities: [
      {
        label: "Andhra Pradesh",
        pureCode: "2",
        lucidCode: "2",
        prodegeCode: "1",
      },
      {
        label: "Telangana",
        pureCode: "32",
        lucidCode: "36",
        prodegeCode: "36",
      },
      {
        label: "Assam",
        pureCode: "4",
        lucidCode: "4",
        prodegeCode: "3",
      },
      {
        label: "Odisha",
        pureCode: "26",
        lucidCode: "26",
        prodegeCode: "20",
      },
      {
        label: "Maharashtra",
        pureCode: "21",
        lucidCode: "21",
        prodegeCode: "15",
      },
      {
        label: "Rajasthan",
        pureCode: "29",
        lucidCode: "29",
        prodegeCode: "22",
      },
      {
        label: "Gujarat",
        pureCode: "12",
        lucidCode: "12",
        prodegeCode: "7",
      },
      {
        label: "Haryana",
        pureCode: "13",
        lucidCode: "13",
        prodegeCode: "8",
      },
      {
        label: "Daman & Diu",
        pureCode: "9",
        lucidCode: "9",
        prodegeCode: "32",
      },
      {
        label: "Dadra & Nagar Haveli",
        pureCode: "8",
        lucidCode: "8",
        prodegeCode: "31",
      },
      {
        label: "Bihar",
        pureCode: "5",
        lucidCode: "5",
        prodegeCode: "4",
      },
      {
        label: "Manipur",
        pureCode: "22",
        lucidCode: "22",
        prodegeCode: "16",
      },
      {
        label: "Mizoram",
        pureCode: "24",
        lucidCode: "24",
        prodegeCode: "18",
      },
      {
        label: "Chattisgarh",
        pureCode: "7",
        lucidCode: "7",
        prodegeCode: "5",
      },
      {
        label: "Nagaland",
        pureCode: "25",
        lucidCode: "25",
        prodegeCode: "19",
      },
      {
        label: "Tamil Nadu",
        pureCode: "31",
        lucidCode: "31",
        prodegeCode: "24",
      },
      {
        label: "Pondicherry",
        pureCode: "27",
        lucidCode: "27",
        prodegeCode: "35",
      },
      {
        label: "Jharkhand",
        pureCode: "16",
        lucidCode: "16",
        prodegeCode: "11",
      },
      {
        label: "Karnataka",
        pureCode: "17",
        lucidCode: "17",
        prodegeCode: "12",
      },
      {
        label: "Uttar Pradesh",
        pureCode: "34",
        lucidCode: "34",
        prodegeCode: "26",
      },
      {
        label: "Himachal Pradesh",
        pureCode: "14",
        lucidCode: "14",
        prodegeCode: "9",
      },
      {
        label: "Delhi",
        pureCode: "10",
        lucidCode: "10",
        prodegeCode: "33",
      },
      {
        label: "Jammu & Kashmir",
        pureCode: "15",
        lucidCode: "15",
        prodegeCode: "10",
      },
      {
        label: "Kerala",
        pureCode: "18",
        lucidCode: "18",
        prodegeCode: "13",
      },
      {
        label: "Punjab",
        pureCode: "28",
        lucidCode: "28",
        prodegeCode: "21",
      },
      {
        label: "West Bengal",
        pureCode: "36",
        lucidCode: "35",
        prodegeCode: "28",
      },
      {
        label: "Andaman & Nicobar Islands",
        pureCode: "1",
        lucidCode: "1",
        prodegeCode: "29",
      },
      {
        label: "Sikkim",
        pureCode: "30",
        lucidCode: "30",
        prodegeCode: "23",
      },
      {
        label: "Lakshadweep",
        pureCode: "19",
        lucidCode: "19",
        prodegeCode: "34",
      },
      {
        label: "Uttarakhand",
        pureCode: "35",
        lucidCode: "34",
        prodegeCode: "27",
      },
      {
        label: "Madhya Pradesh",
        pureCode: "20",
        lucidCode: "20",
        prodegeCode: "14",
      },
      {
        label: "Tripura",
        pureCode: "33",
        lucidCode: "32",
        prodegeCode: "25",
      },
      {
        label: "Arunachal Pradesh",
        pureCode: "3",
        lucidCode: "3",
        prodegeCode: "2",
      },
      {
        label: "Meghalaya",
        pureCode: "23",
        lucidCode: "23",
        prodegeCode: "17",
      },
      {
        label: "Goa",
        pureCode: "11",
        lucidCode: "11",
        prodegeCode: "6",
      },
      {
        label: "Chandigarh",
        pureCode: "6",
        lucidCode: "6",
        prodegeCode: "30",
      },
    ],
  },
  {
    label: "South Africa",
    code: "en_ZA",
    cities: [],
  },
  {
    label: "Saudi Arabia",
    code: "ar_SA",
    cities: [],
  },
  {
    label: "Japan",
    code: "ja_JP",
    cities: [],
  },
  {
    label: "Singapore",
    code: "en_SG",
    cities: [
      {
        label: "Central",
        pureCode: "111",
        lucidCode: "1",
        prodegeCode: "1",
      },
      {
        label: "East",
        pureCode: "112",
        lucidCode: "4",
        prodegeCode: "2",
      },
      {
        label: "North",
        pureCode: "113",
        lucidCode: "3",
        prodegeCode: "3",
      },
      {
        label: "North East",
        pureCode: "114",
        lucidCode: "2",
        prodegeCode: "4",
      },
      {
        label: "West",
        pureCode: "115",
        lucidCode: "5",
        prodegeCode: "5",
      },
    ],
  },
  {
    label: "Thailand",
    code: "th_TH",
    cities: [],
  },
  {
    label: "Indonesia",
    code: "id_ID",
    cities: [],
  },
  {
    label: "Brazil",
    code: "pt_BR",
    cities: [],
  },
  {
    label: "Egypt",
    code: "ar_EG",
    cities: [],
  },
  {
    label: "Switzerland",
    code: "de_CH",
    cities: [],
  },
  {
    label: "Switzerland (French)",
    code: "fr_CH",
    cities: [],
  },
  {
    label: "Poland",
    code: "pl_PL",
    cities: [],
  },
  {
    label: "Romania",
    code: "ro_RO",
    cities: [],
  },
  {
    label: "Hong Kong",
    code: "en_HK",
    cities: [],
  },
  {
    label: "Ireland",
    code: "en_IE",
    cities: [],
  },
  {
    label: "Portugal",
    code: "pt_PT",
    cities: [],
  },
  {
    label: "Chile",
    code: "es_CL",
    cities: [],
  },
  {
    label: "Colombia",
    code: "es_CO",
    cities: [],
  },
  {
    label: "Philippines",
    code: "tl_PH",
    cities: [],
  },
  {
    label: "Taiwan",
    code: "zh-TW",
    cities: [],
  },
  {
    label: "Sweden",
    code: "sv-SE",
    cities: [],
  },
  {
    label: "South Korea",
    code: "ko_KR",
    cities: [],
  },
  {
    label: "Malaysia",
    code: "ms_MY",
    cities: [],
  },
  {
    label: "Czech Republic",
    code: "cs_CZ",
    cities: [],
  },
]
