import momentT from "moment-timezone"
import moment from "moment"

const startTime = 8
const endTime = 22

interface TimeByCountry {
  countryTime?: { time: string }
  timezoneInitials?: { initial: string } // Added for timezone initials
  localTime?: { startTime: string; endTime: string }
  isValid: { valid: boolean }
}

interface TimeZones {
  [country: string]: { zone: string; initials: string }
}

const getTimeByCountry = (country: string): TimeByCountry => {
  const getCountryTime = (timezone: string) => {
    const hourForCountry = Number(momentT().tz(timezone).format("H"))
    return {
      hour: hourForCountry,
      time: momentT().tz(timezone).format("hh.mma"),
    }
  }

  const getLocalTime = (timezone: string) => {
    const hourForCountry = Number(momentT().tz(timezone).format("H"))
    const timezoneOffsetInHours = new Date().getHours() - hourForCountry
    return {
      startTime: moment("080000", "HHmmss").add(timezoneOffsetInHours, "hours").format("hh.mma"),
      endTime: moment("220000", "HHmmss").add(timezoneOffsetInHours, "hours").format("hh.mma"),
    }
  }

  const isValidTime = (hour: number) => hour < startTime || hour > endTime
  // https://momentjs.com/timezone/
  const timeZones: TimeZones = {
    "United States": { zone: "America/New_York", initials: "USA" },
    "United Kingdom": { zone: "Europe/London", initials: "UK" },
    "United Arab Emirates": { zone: "Asia/Dubai", initials: "AE" },
    Canada: { zone: "America/Toronto", initials: "CA" },
    "Canada (French)": { zone: "America/Toronto", initials: "CA" },
    Thailand: { zone: "Asia/Bangkok", initials: "TH" },
    Indonesia: { zone: "Asia/Jakarta", initials: "ID" },
    Turkey: { zone: "Europe/Istanbul", initials: "TR" },
    Germany: { zone: "Europe/Berlin", initials: "DE" },
    France: { zone: "Europe/Paris", initials: "FR" },
    Spain: { zone: "Europe/Madrid", initials: "ES" },
    Italy: { zone: "Europe/Rome", initials: "IT" },
    Vietnam: { zone: "Asia/Ho_Chi_Minh", initials: "VN" },
    Australia: { zone: "Australia/Sydney", initials: "AU" },
    Netherlands: { zone: "Europe/Amsterdam", initials: "NL" },
    Mexico: { zone: "America/Mexico_City", initials: "MX" },
    India: { zone: "Asia/Kolkata", initials: "IN" },
    China: { zone: "Asia/Shanghai", initials: "CN" },
    "South Africa": { zone: "Africa/Johannesburg", initials: "ZA" },
    "Saudi Arabia": { zone: "Asia/Riyadh", initials: "SA" },
    Japan: { zone: "Asia/Tokyo", initials: "JP" },
    Singapore: { zone: "Asia/Singapore", initials: "SG" },
    Brazil: { zone: "America/Sao_Paulo", initials: "BR" },
    Switzerland: { zone: "Europe/Zurich", initials: "CH" },
    "Switzerland (French)": { zone: "Europe/Zurich", initials: "CH" },
    Egypt: { zone: "Africa/Cairo", initials: "EG" },
    Kazakhstan: { zone: "Asia/Almaty", initials: "KZ" },
    Poland: { zone: "Europe/Warsaw", initials: "PL" },
    Romania: { zone: "Europe/Bucharest", initials: "RO" },
    "Hong Kong": { zone: "Asia/Hong_Kong", initials: "HK" },
    Ireland: { zone: "Europe/Dublin", initials: "IE" },
    Portugal: { zone: "Europe/Lisbon", initials: "PT" },
    Chile: { zone: "America/Santiago", initials: "CL" },
    Colombia: { zone: "America/Bogota", initials: "CO" },
    Philippines: { zone: "Asia/Manila", initials: "PH" },
    Taiwan: { zone: "Asia/Taipei", initials: "TW" },
    Sweden: { zone: "Europe/Stockholm", initials: "SE" },
    "South Korea": { zone: "Asia/Seoul", initials: "KR" },
    Malaysia: { zone: "Asia/Kuala_Lumpur", initials: "MY" },
    "Czech Republic": { zone: "Europe/Prague", initials: "CZ" }
    // Add other countries as needed
  }

  if (!Object.prototype.hasOwnProperty.call(timeZones, country)) {
    return { isValid: { valid: false } }
  }

  const timezoneInfo = timeZones[country]
  const countryTime = getCountryTime(timezoneInfo.zone)
  if (!isValidTime(countryTime.hour)) {
    return { isValid: { valid: true } }
  }

  const localTime = getLocalTime(timezoneInfo.zone)

  return {
    countryTime: { time: countryTime.time },
    timezoneInitials: { initial: timezoneInfo.initials }, // Include timezone initials in the return object
    localTime: { startTime: localTime.startTime, endTime: localTime.endTime },
    isValid: { valid: false },
  }
}

export { getTimeByCountry }
