import React from 'react'
import type { ChartData, ChartOptions } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import 'chart.js/auto'

interface HorizontalBarChartProps {
  labels: string[]
  dataset: number[]
  color: string
}

const HorizontalBarChart: React.FC<HorizontalBarChartProps> = ({ labels, dataset, color }) => {
  const data: ChartData<'bar'> = {
    labels: labels,
    datasets: [
      {
        data: dataset,
        backgroundColor: ['#8f6ed5', '#1f93f4', '#00b0f5', '#00c6dd', '#1ed6bb'],
      },
    ],
  }

  const options: ChartOptions<'bar'> = {
    indexAxis: 'y',
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
        min: 0,
      },
      y: {
        ticks: {
          color: color,
          callback: function (value: any) {
            const label: any = this.getLabelForValue(value)
            return label?.length > 30 ? label.substring(0, 30) + '...' : label
          },
        },
      },
    },

    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        intersect: false,
        mode: 'y',
      },
      // labels: {
      //   color: 'white',
      //   maxWidth: 100,
      // },
    },
    animation: {
      duration: 2000,
      easing: 'easeInOutQuart',
    },
    maintainAspectRatio: true,
  }

  return <Bar data={data} options={options} data-bolt="horizontal-bar-chart" />
}

export { HorizontalBarChart }
