import { IResponse } from "@bolt-chat-core/common"
import ApiService, { API_URL } from "./api.axios"
import { ENDPOINTS } from "@bolt-chat-core/common-type"

/*
 * @param payload: Generic payload
 * @returns Generic response
 * @description: Get generic response from db with query
 */
export const _genericQuery = async <PayloadType, ResponseType>(
  modelName: string,
  payload: PayloadType,
): Promise<ResponseType> => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.CHAT_CORE.QUERY.QUERY.URL}?model=${modelName}`, payload)
  return response.data
}

/*
 * @param payload: Generic payload
 * @returns number
 * @description: Post generic response count from db
 */
export const _genericCount = async <PayloadType>(modelName: string, where: any): Promise<IResponse<number>> => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.CHAT_CORE.QUERY.COUNT.URL}?model=${modelName}`, { where })
  return response.data
}

export const queryParamBuilder = (data: any) => {
  return Object.keys(data)
    .filter((key) => Boolean(data[key]))
    .map((key) => {
      return `${key}=${data[key]}`
    })
    .join("&")
}
