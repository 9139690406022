/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChatPrisma, ProjectStatus, Projects, projectReport } from "@bolt-chat-core/chat-prisma-service-interface"
import { _genericCount, _genericQuery } from "../../services/query.service"
import {
  ChatCoreProjectCustomerUploadResponse,
  ChatCoreProjectStimulusUploadResponse,
  IProjectActivity,
  IResponse,
} from "@bolt-chat-core/common"
import ApiService, { API_URL } from "../api.axios"
import { ENDPOINTS } from "@bolt-chat-core/common-type"

export const _projectQuery = async (payload: ChatPrisma.ProjectsFindManyArgs): Promise<IResponse<Projects[]>> => {
  const response = await _genericQuery<ChatPrisma.ProjectsFindManyArgs, IResponse<Projects[]>>(
    ChatPrisma.ModelName.Projects,
    payload,
  )
  return response
}

export const _projectCount = async (payload: ChatPrisma.ProjectsWhereInput): Promise<IResponse<number>> => {
  const response = await _genericCount<ChatPrisma.ProjectsWhereInput>(ChatPrisma.ModelName.Projects, payload)
  return response
}

export const _projectSearch = async (
  field: ChatPrisma.ProjectsScalarFieldEnum,
  searchKey: string,
  skip: number = 0,
  take: number = 10,
): Promise<IResponse<Projects[]>> => {
  const response = await _genericQuery<ChatPrisma.ProjectsFindManyArgs, IResponse<Projects[]>>(ChatPrisma.ModelName.Projects, {
    where: {
      [field]: {
        contains: searchKey,
      },
    },
    skip,
    take,
  })
  return response
}

export const _getProject = async (projectId: string): Promise<IResponse<Projects[]>> => {
  const response = await _genericQuery<ChatPrisma.ProjectsFindManyArgs, IResponse<Projects[]>>(ChatPrisma.ModelName.Projects, {
    where: {
      id: projectId,
    },
  })
  return response
}

export const _getProjects = async (skip: number, take: number, teamId: string, userId: string): Promise<IResponse<Projects[]>> => {
  const response = await _genericQuery<ChatPrisma.ProjectsFindManyArgs, IResponse<Projects[]>>(ChatPrisma.ModelName.Projects, {
    where: {
      projectType: "AI",
      OR: [
        {
          teamId: teamId,
        },
        {
          sharedWithTeamIds: {
            hasSome: teamId
          }
        }
      ]
    },
    skip,
    take,
    orderBy: {
      createdAt: "desc",
    },
  })
  return response
}

export const _getFilteredProjects = async (
  skip: number,
  take: number,
  status: ProjectStatus,
  teamId: string,
  userId: string,
): Promise<IResponse<Projects[]>> => {
  const response = await _genericQuery<ChatPrisma.ProjectsFindManyArgs, IResponse<Projects[]>>(ChatPrisma.ModelName.Projects, {
    where: {
      projectType: "AI",
      status: status,
      OR: [
        {
          teamId: teamId
        },
        {
          sharedWithTeamIds: {
            hasSome: teamId
          }
        }
      ],
    },
    skip,
    take,
    orderBy: {
      createdAt: "desc",
    },
  })
  return response
}

export const _getDiscoverProjects = async (skip: number, take: number, search: string): Promise<IResponse<Projects[]>> => {
  const where:any = {
    projectType: "AI",
    discover: {
      is: {
        active: true
      }
    },
    status: 'COMPLETE'
  }
  if(search !== ''){
    where.title = {
      contains: search,
      mode: 'insensitive'
    }
  }
  const response = await _genericQuery<ChatPrisma.ProjectsFindManyArgs, IResponse<Projects[]>>(ChatPrisma.ModelName.Projects, {
    where: where,
    skip,
    take,
    select: {
      id: true,
      title: true,
      discover: true
    },
    orderBy: {
      createdAt: "desc",
    },
  })
  return response
}

export const _createProject = async (data: {teamId: string, payload?: ChatPrisma.ProjectsCreateInput}): Promise<IResponse<Projects>> => {
  const { teamId, payload } = data

  let requestPayload;
  if (payload) requestPayload = payload
  else{
    requestPayload = {
      title: 'Untitled Project',
      status: 'SETUP',
      projectType: 'AI',
      recruitmentType: 'AUTO',
      setups: {
        step: ['1', '2', '6', '4'],
        steps: {
          location: {
            city: [],
            citycodes: [],
            lucidCityCodes: [],
            prodegeCityCodes: [],
            country: 'United Kingdom',
            countrycodes: ['en_GB'],
          },
          product: {
            category: '',
            codes: '',
            lucidCodes: '',
            prodegeCodes: '',
            subCategory: [],
          },
          chatGuide: [],
          attendes: 10,
          type: 'Direct Chat',
        },
      },
      settings: {
        timeoutDuration: 30,
        thresholdEnabled: true,
        dynamicThresholdEnabled: true,
        thresholdValue: 7,
      },
      team: { connect: { id: teamId } },
    }
  }
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.CHAT_CORE.PROJECT.CREATE.URL}`, requestPayload)
  return response.data
}

export const _updateProject = async (
  payload: ChatPrisma.ProjectsUpdateInput,
  projectId: string | undefined,
): Promise<IResponse<Projects>> => {
  const response = await ApiService.patch(`${API_URL}${ENDPOINTS.CHAT_CORE.PROJECT.UPDATE.URL}?projectId=${projectId}`, payload)
  return response.data
}

export const _deleteProject = async (projectId: string): Promise<IResponse<Projects>> => {
  const response = await ApiService.delete(`${API_URL}${ENDPOINTS.CHAT_CORE.PROJECT.DELETE.URL}?projectId=${projectId}`)
  return response.data
}

export const _getProjectActivities = async (projectId: string): Promise<IResponse<IProjectActivity[]>> => {
  const response = await ApiService.get(`${API_URL}${ENDPOINTS.CHAT_CORE.PROJECT.GET_ACTIVITIES.URL}?projectId=${projectId}`)
  return response.data
}

export const _getProjectDataAcquisitionActivities = async (projectId: string): Promise<IResponse<IProjectActivity[]>> => {
  const response = await ApiService.get(
    `${API_URL}${ENDPOINTS.CHAT_CORE.PROJECT.GET_DATA_ACQUISITION_ACTIVITIES.URL}?projectId=${projectId}`,
  )
  return response.data
}

export const _shareProjectWithTeam = async (projectId: string, teamId: string): Promise<IResponse<any>> => {
  const response = await ApiService.post(
    `${API_URL}${ENDPOINTS.CHAT_CORE.PROJECT.SHARE_WITH_TEAM.URL}?projectId=${projectId}&teamId=${teamId}&action=${"add"}`,
  )
  return response.data
}

export const _unshareProjectWithTeam = async (projectId: string, teamId: string): Promise<IResponse<any>> => {
  const response = await ApiService.post(
    `${API_URL}${ENDPOINTS.CHAT_CORE.PROJECT.SHARE_WITH_TEAM.URL}?projectId=${projectId}&teamId=${teamId}&action=${"remove"}`,
  )
  return response.data
}

export const _shareReportWithClient = async (
  projectId: string,
  action: "share" | "unshare",
  mailSend: boolean,
): Promise<IResponse<any>> => {
  const response = await ApiService.post(
    `${API_URL}${ENDPOINTS.CHAT_CORE.PROJECT.SHARE_REPORT_WITH_CLIENT.URL}?projectId=${projectId}&action=${action}&mailSend=${mailSend}`,
  )
  return response.data
}

export const _updateProjectExportInLocalLanguage = async (projectId: string, exportInLocalLanguage: boolean) => {
  const response = await ApiService.post(
    `${API_URL}${ENDPOINTS.CHAT_CORE.PROJECT.UPDATE_EXPORT_IN_LOCAL_LANGUAGE.URL}?projectId=${projectId}&exportInLocalLanguage=${exportInLocalLanguage}`,
  )
  return response.data
}

export const _shareChatWithClient = async (projectId: string, action: "share" | "unshare"): Promise<IResponse<any>> => {
  const response = await ApiService.post(
    `${API_URL}${ENDPOINTS.CHAT_CORE.PROJECT.SHARE_CHATS_WITH_CLIENT.URL}?projectId=${projectId}&action=${action}`,
  )
  return response.data
}

export const _createContactUs = async (payload: { projectId: string; message: string }): Promise<IResponse<any>> => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.CHAT_CORE.PROJECT.CREATE_CONTACT_US.URL}`, payload)
  return response.data
}

export const _getProducts = async (): Promise<IResponse<any>> => {
  const response = await ApiService.get(`${API_URL}${ENDPOINTS.CHAT_CORE.PAYMENT.GET_PRODUCTS.URL}`)
  return response.data
}

export const _uploadStimulus = async (payload: any): Promise<IResponse<ChatCoreProjectStimulusUploadResponse>> => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.CHAT_CORE.PROJECT.UPLOAD_STIMULUS.URL}`, payload)
  return response.data
}

export const _uploadDiscover = async (payload: any): Promise<IResponse<ChatCoreProjectCustomerUploadResponse>> => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.CHAT_CORE.PROJECT.UPLOAD_DISCOVER.URL}`, payload)
  return response.data
}

export const _uploadAudio = async (payload: any): Promise<IResponse<ChatCoreProjectCustomerUploadResponse>> => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.CHAT_CORE.PROJECT.UPLOAD_AUDIO.URL}`, payload)
  return response.data
}

export const _uploadCustomerFilePresign = async (payload: any): Promise<IResponse<any>> => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.CHAT_CORE.PROJECT.UPLOAD_CUSTOMER_FILE.URL}`, payload)
  return response.data
}

export const _uploadCustomerFilePresignVideo = async (payload: any): Promise<IResponse<any>> => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.CHAT_CORE.PROJECT.UPLOAD_VIDEO.URL}`, payload)
  return response.data
}

export const _launchProject = async (payload: { projectId: string }): Promise<IResponse<any>> => {
  const response = await ApiService.post(
    `${API_URL}${ENDPOINTS.CHAT_CORE.PROJECT.LAUNCH_PROJECT.URL}?projectId=${payload.projectId}`,
  )
  return response.data
}

export const _projectGoToCompletion = async (projectId: string) => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.CHAT_CORE.PROJECT.GO_TO_COMPLETE.URL}`, { projectId })
  return response.data
}

export const _projectStartLiveChat = async (projectId: string) => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.CHAT_CORE.PROJECT.START_LIVE_CHAT.URL}`, { projectId })
  return response.data
}

export const _projectBackToReview = async (projectId: string) => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.CHAT_CORE.PROJECT.BACK_TO_REVIEW.URL}`, { projectId })
  return response.data
}

export const _projectBackToSetup = async (projectId: string) => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.CHAT_CORE.PROJECT.BACK_TO_SETUP.URL}`, { projectId })
  return response.data
}