import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const notifySuccess = (message: string | null | undefined, delay?: number) =>
  toast.success(message, {
    theme: 'dark',
    autoClose: delay || 3000,
  })

const notifyFailed = (message: string | null | undefined) =>
  toast.error(message, {
    theme: 'dark',
  })

const notifyOffline = () => {
  toast("You're Offline📡", {
    position: 'bottom-center',
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: 'light',
    closeButton: false,
    style: {
      fontFamily: 'sans-serif',
      maxWidth: '150px',
      minHeight: '50px',
      textAlign: 'center',
      color: 'white',
      backgroundColor: '#262627',
    },
  })
}
const closeAllToasts = () => toast.dismiss()

const Toast: React.FC = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      limit={1}
    />
  )
}

export { Toast }
export { notifySuccess, notifyFailed, notifyOffline, closeAllToasts }
