import React, { useEffect, useRef, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import type { InputRef } from 'antd'
import { Flex, Input, Tag, Tooltip } from 'antd'

const tagInputClass = {
  small: 'w-32 me-2 align-top text-[12px] h-6',
  large: 'w-32 py-1.5 me-2 align-top text-[14px]',
}

const tagPlusClass = {
  small: 'border-dashed bg-[#FAFAFA] h-6 bg-white',
  large: 'border-dashed bg-[#FAFAFA] py-1.5 bg-white',
}

type ComponentSize = 'small' | 'large'

interface Props {
  value: string[]
  onChange: (chips: string[]) => void
  size: ComponentSize
  disabled: boolean
  dataBolt?: string
}

const ChipsInput: React.FC<Props> = ({ value, onChange, size, disabled }) => {
  const [tags, setTags] = useState<string[]>(value)
  const [inputVisible, setInputVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [editInputIndex, setEditInputIndex] = useState(-1)
  const [editInputValue, setEditInputValue] = useState('')
  const inputRef = useRef<InputRef>(null)
  const editInputRef = useRef<InputRef>(null)
  useEffect(() => {
    setTags(value)
  }, [value])
  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus()
    }
  }, [inputVisible])

  useEffect(() => {
    editInputRef.current?.focus()
  }, [editInputValue])

  const handleClose = (removedTag: string) => {
    if (!disabled) {
      const newTags = tags.filter((tag) => tag !== removedTag)
      setTags(newTags)
      onChange(newTags)
    }
  }

  const showInput = () => {
    if (!disabled) setInputVisible(true)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  const handleInputConfirm = () => {
    if (inputValue && !tags.includes(inputValue)) {
      setTags([...tags, inputValue])
      onChange([...tags, inputValue])
    }
    setInputVisible(false)
    setInputValue('')
  }

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInputValue(e.target.value)
  }

  const handleEditInputConfirm = () => {
    const newTags = [...tags]
    newTags[editInputIndex] = editInputValue
    setTags(newTags)
    onChange(newTags)
    setEditInputIndex(-1)
    setEditInputValue('')
  }

  return (
    <Flex gap="8px" wrap="wrap">
      {tags.map<React.ReactNode>((tag, index) => {
        if (editInputIndex === index) {
          return (
            <Input
              ref={editInputRef}
              key={tag}
              size="small"
              rootClassName={tagInputClass[size]}
              value={editInputValue}
              onChange={handleEditInputChange}
              onBlur={handleEditInputConfirm}
              onPressEnter={handleEditInputConfirm}
              data-bolt={`chips-input-edit-${tag}`}
            />
          )
        }
        const isLongTag = tag.length > 20
        const tagElem = (
          <Tag
            key={tag}
            closable={!disabled}
            className={`select-none ${size === 'large' && 'py-1.5'}`}
            onClose={() => handleClose(tag)}
          >
            <span
              onDoubleClick={(e) => {
                if (!disabled) {
                  setEditInputIndex(index)
                  setEditInputValue(tag)
                  e.preventDefault()
                }
              }}
            >
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </span>
          </Tag>
        )
        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        )
      })}
      {inputVisible ? (
        <Input
          ref={inputRef}
          type="text"
          size="middle"
          rootClassName={tagInputClass[size]}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
          className="focus:border focus:border-black"
        />
      ) : (
        <Tag
          className={tagPlusClass[size]}
          icon={<PlusOutlined />}
          onClick={showInput}
          style={{ userSelect: disabled ? 'none' : 'text', pointerEvents: disabled ? 'none' : 'all' }}
        >
          <span>New Category</span>
        </Tag>
      )}
    </Flex>
  )
}

export { ChipsInput }
