import { FC } from 'react'
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'

type LabelMap = { [key: number]: string } // Type for label map

const horizontalLabels = (projectSize: number): LabelMap => ({
  10: '10',
  ...{ [projectSize]: `${projectSize}` },
})

interface ISizeComponentProps {
  handleChange: (val: number) => void
  size: number
  disabled: boolean
  projectSize: number
}

const SizeComponent: FC<ISizeComponentProps> = ({ handleChange, size, disabled, projectSize }) => {
  return (
    <Slider
      min={10}
      max={projectSize}
      step={10}
      value={size}
      labels={horizontalLabels(projectSize)}
      handleLabel={String(size)}
      disabled={disabled}
      onChange={(value: number) => handleChange(value)}
      className=""
      data-bolt="size-component-slider"
    />
  )
}

export { SizeComponent }
