import axios from "axios"
import { CONFIG } from "../config/init.config"

let API_URL_BASE = CONFIG.BASE_URL

export const API_URL = API_URL_BASE
const ApiService = axios.create()


ApiService.interceptors.request.use((config) => {
  // add authorization header with jwt token if available
  const state = localStorage.getItem("auth")
  const token = state ? JSON.parse(state).state.accessToken : null
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  // X-Page-Location header
  config.headers["x-page-location"] = window.location.pathname

  return config
})

ApiService.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem("auth")
      window.location.href = `/auth/login?redirect=${window.location.pathname}`
    } else if (error?.response?.status === 403) {
      window.location.href = "/404"
    }
    // else if(error.response.status === 404 || error.response.status === 400){
    //   window.location.href = "/404"
    // }

    return Promise.reject(error)
  },
)

export default ApiService
