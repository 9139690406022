/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, useState, useRef, FC } from 'react'
import axios from 'axios'
import ReactPlayer from 'react-player'
import { Button } from '../../../../common/button/button.component'
import { Textarea } from '../../../../common/textarea/textarea.component'
import { v4 } from 'uuid'

const validFileTypes = new Set(['image/jpg', 'image/jpeg', 'image/png', 'video/mp4', 'video/avi', 'video/mov'])

async function getImageDimensions(file: any) {
  const img = new Image()
  img.src = URL.createObjectURL(file)
  await img.decode()
  return { width: img.width, height: img.height }
}

interface IStimulusComponent {
  setOpenAddStimulusQuestionModal: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean
      section: string
    }>
  >
  openAddStimulusQuestionModal: { isOpen: boolean; section: string }
  editStimulusQuestionId: number | null | undefined
  aiQuestion: any
  setAiQuestion: React.Dispatch<React.SetStateAction<any>>
  disabled: boolean
  projectId: string
  chatGuide: any[]
}

const StimulusComponent: FC<IStimulusComponent> = ({
  setOpenAddStimulusQuestionModal,
  openAddStimulusQuestionModal,
  editStimulusQuestionId,
  aiQuestion,
  setAiQuestion,
  disabled,
  projectId,
  chatGuide,
}) => {
  const inputRef = useRef<any>(null)
  const [files, setFiles] = useState<File | null>(null)
  const [description, setDescription] = useState<string | undefined>('')
  const [previewFile, setPreviewFile] = useState<string | null | undefined>(null)
  const [fileUrl, setFileUrl] = useState<string | null | undefined>(null)
  const [fileType, setFileType] = useState<string | null | undefined>(null)
  const [editMode, setEditMode] = useState<{ fileUrl: string | null | undefined }>({ fileUrl: null })
  const [fileLoading, setFileLoading] = useState(false)
  const [mediaUploadError, setMediaUploadError] = useState({
    section: '',
    status: false,
  })
  const [loadingSave, setLoadingSave] = useState(false)

  async function handleSubmitFile(file: any) {
    try {
      if (file) {
        // const fileName = v4()
        // setFileLoading(true)
        // const {data,success} = await _stimulusUpload(file,"boltchat-stimulus",`${projectId}/${fileName}-${Date.now()}`,file.type)
        // if(success){
        //   setFileUrl(data?.fileUrl?.split('?')[0])
        //   setPreviewFile(data?.fileUrl?.split('?')[0])
        //   setFileType(file.type)
        //   setFileLoading(false)
        //   setMediaUploadError({ section: '', status: false })
        // }
      }
    } catch (error) {
      setFileUrl(null)
      setPreviewFile(null)
      setFileType(null)
      setFileLoading(false)
      setMediaUploadError({ section: 'unexpected_error', status: true })
    }
  }
  async function handleChange(e: React.BaseSyntheticEvent) {
    e.preventDefault()

    const file = e.target.files?.[0]

    if (file) {
      const fileType = file.type
      const isImage = fileType.split('/')[0] === 'image'

      if (validFileTypes.has(fileType)) {
        if (isImage) {
          const { width, height } = await getImageDimensions(file)
          if (width > height * 2) {
            setMediaUploadError({ section: 'dimension', status: true })
          } else {
            setFiles(file)
            handleSubmitFile(file)
          }
        } else {
          setFiles(file)
          handleSubmitFile(file)
        }
      } else {
        setMediaUploadError({ section: 'fileType', status: true })
      }
    }
  }

  async function handleDrop(e: React.DragEvent) {
    e.preventDefault()
    e.stopPropagation()

    const file = e.dataTransfer.files?.[0]

    if (file) {
      const fileType = file.type
      const isImage = fileType.split('/')[0] === 'image'

      if (validFileTypes.has(fileType)) {
        if (isImage) {
          const { width, height } = await getImageDimensions(file)
          if (width > height * 2) {
            setMediaUploadError({ section: 'dimension', status: true })
          } else {
            setFiles(file)
            handleSubmitFile(file)
          }
        } else {
          setFiles(file)
          handleSubmitFile(file)
        }
      } else {
        setMediaUploadError({ section: 'fileType', status: true })
      }
    }
  }

  function handleDragLeave(e: React.DragEvent) {
    const dropzone = document.getElementById('dropzone')
    dropzone?.classList.remove('border-bolt-purple')
    e.preventDefault()
    e.stopPropagation()
  }

  function handleDragOver(e: React.DragEvent) {
    const dropzone = document.getElementById('dropzone')
    dropzone?.classList.add('border-bolt-purple')
    e.preventDefault()
    e.stopPropagation()
  }

  function handleDragEnter(e: React.DragEvent) {
    e.preventDefault()
    e.stopPropagation()
  }

  function removeFile() {
    const dropzone = document.getElementById('dropzone')
    dropzone?.classList.remove('border-bolt-purple')
    setFiles(null)
    setPreviewFile(null)
    setFileUrl(null)
    setMediaUploadError({ section: '', status: false })
  }

  function openFileExplorer() {
    inputRef.current.value = ''
    inputRef.current.click()
  }

  useEffect(() => {
    if (!files) {
      setPreviewFile(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(files)
    setPreviewFile(objectUrl)
    setFileType(files.type)
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [files])

  const handleNext = async () => {
    if (disabled) {
      setLoadingSave(true)
      const newStimulus = {
        description,
        fileType,
        fileUrl: fileUrl?.replace('boltchat-stimulus.s3.eu-central-1.amazonaws.com', 'boltchatai-stimulus-cdn.boltinsight.com'),
        thumbnail: '',
      }

      if (files?.type.includes('video')) {
        const response = await axios.post(`${process.env.NEXT_PUBLIC_LAMBDA_VIDEO_THUMBNAIL_GENERATOR_URL}`, {
          videoURL: fileUrl,
          projectId: projectId,
          thumbnailTimestamp: 5,
        })

        newStimulus.thumbnail = JSON.parse(response.data.body).thumbnailURL
      }

      setAiQuestion((prev: any) => [
        {
          question: description,
          stimulus: newStimulus,
          type: 'STIMULUS',
          punchType: null,
          options: [],
        },
        ...prev,
      ])
      setPreviewFile(null)
      setDescription('')
      setFiles(null)
      setFileUrl(null)
      setFileType(null)
      setOpenAddStimulusQuestionModal({
        isOpen: false,
        section: 'add',
      })
      setLoadingSave(false)
    }
  }

  useEffect(() => {
    if (openAddStimulusQuestionModal.isOpen && openAddStimulusQuestionModal.section === 'edit') {
      const data = chatGuide[editStimulusQuestionId as number]['stimulus']
      setDescription(data?.description)
      setPreviewFile(data?.fileUrl)
      setFileUrl(data?.fileUrl)
      setFileType(data?.fileType)
      setEditMode({ fileUrl: data?.fileUrl })
    }
  }, [openAddStimulusQuestionModal])

  const handleCancel = () => {
    setDescription('')
    setPreviewFile(null)
    setFileUrl(null)
    setFileType(null)
    setOpenAddStimulusQuestionModal({
      isOpen: false,
      section: 'add',
    })
    setMediaUploadError({ section: '', status: false })
    setEditMode({ fileUrl: null })
  }
  const handleUpdate = async () => {
    if (disabled) {
      setLoadingSave(true)

      const stimulusUpdateData = {
        description,
        fileType,
        fileUrl: fileUrl?.replace('boltchat-stimulus.s3.eu-central-1.amazonaws.com', 'boltchatai-stimulus-cdn.boltinsight.com'),
        thumbnail: '',
      }

      if (fileType?.includes('video')) {
        const response = await axios.post(`${process.env.LAMBDA_VIDEO_THUMBNAIL_GENERATOR_URL}`, {
          videoURL: fileUrl,
          projectId: projectId,
          thumbnailTimestamp: 5,
        })

        stimulusUpdateData.thumbnail = response.data.thumbnailURL
      }

      aiQuestion[editStimulusQuestionId as number] = {
        question: description,
        stimulus: stimulusUpdateData,
        type: 'STIMULUS',
      }

      setAiQuestion(aiQuestion)
      setPreviewFile(null)
      setDescription('')
      setFiles(null)
      setFileUrl(null)
      setFileType(null)
      setEditMode({ fileUrl: null })
      setOpenAddStimulusQuestionModal({
        isOpen: false,
        section: 'add',
      })
      setLoadingSave(false)
    }
  }
  return (
    <>
      {openAddStimulusQuestionModal.isOpen && (
        <>
          <div className="relative z-[9999]" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-end sm:items-center justify-center min-h-full text-center sm:p-0">
                <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 max-w-xl w-full max-h-[650px] overflow-y-auto mac-style-scrollbar">
                  <div className="text-[#414141] p-6">
                    <div>
                      <div className="text-lg font-semibold text-[#000000DE]">Stimulus</div>
                      <div className="h-[1px] w-full bg-[#E0E0E0] my-4"></div>
                      <p className="text-sm mt-2 mb-4">
                        Please give brief description about the stimulus and ask consumers to review carefully before answering
                        the next questions.
                      </p>
                      <Textarea
                        rows={3}
                        placeholder="Now, you will seen an image. Please review carefully and then answer the next questions"
                        className="mac-style-scrollbar block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                        disabled={disabled}
                        dataBolt='stimulus-description'
                      />
                    </div>
                    <div>
                      <p className="text-sm mb-4 mt-4">
                        Please upload a mobile-friendly image or video file (easy to read, portrait orientation)
                      </p>

                      <form
                        className={`bg-[#FAFAFA]
                            p-4 w-full rounded-lg border-2 border-dashed border-[#D9DAE0] min-h-[14rem] text-center flex flex-col items-center justify-center relative hover:border-bolt-purple `}
                        onDragEnter={handleDragEnter}
                        onSubmit={(e) => e.preventDefault()}
                        onDrop={handleDrop}
                        onDragLeave={handleDragLeave}
                        onDragOver={handleDragOver}
                        id="dropzone"
                        // disabled={disabled}
                      >
                        <input
                          placeholder="fileInput"
                          className="hidden"
                          ref={inputRef}
                          type="file"
                          onChange={handleChange}
                          accept=".jpg, .jpeg, .png, .mp4, .avi, .mov"
                          disabled={(previewFile || disabled) as boolean}
                          data-bolt='stimulus-file-input'
                        />
                        {mediaUploadError.status &&
                          (mediaUploadError.section === 'fileType' ? (
                            <p className="text-xs absolute top-0 w-full text-red-500 bg-red-100 py-1">
                              Can&apos;t upload. Use a media file in one of these formats: .jpg, .jpeg, .png, .mp4, .avi, .mov
                            </p>
                          ) : mediaUploadError.section === 'unexpected_error' ? (
                            <p className="text-xs absolute top-0 w-full text-red-500 bg-red-100 py-1">
                              Can&apos;t upload. Unexpected error
                            </p>
                          ) : (
                            <p className="text-xs absolute top-0 w-full text-red-500 bg-red-100 py-1">
                              Can&apos;t upload. Width must be less than 2 times height.
                            </p>
                          ))}

                        {fileLoading ? (
                          <div className="flex items-center text-bolt-purple">
                            <svg
                              aria-hidden="true"
                              role="status"
                              className="inline mr-3 w-6 h-6 animate-spin"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="#E5E7EB"
                              ></path>
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                            <span>Loading...</span>
                          </div>
                        ) : previewFile ? (
                          <div className="flex flex-col items-center p-2">
                            <div className="flex flex-row space-x-5 relative">
                              {fileType?.split('/')[0] === 'video' ? (
                                <ReactPlayer
                                  url={previewFile}
                                  controls
                                  width="560px"
                                  height="220px"
                                  style={{
                                    maxWidth: '480px',
                                    maxHeight: '220px',
                                    objectFit: 'cover',
                                  }}
                                  config={{
                                    file: {
                                      forceVideo: true,
                                    },
                                  }}
                                  data-bolt="stimulus-video"
                                />
                              ) : (
                                <img
                                  src={previewFile}
                                  className="rounded-lg max-w-[480px] max-h-[220px] object-fill"
                                  alt="stimulus"
                                  data-bolt="stimulus-image"
                                />
                              )}

                              <button
                                className="bg-[#D14343] rounded-full p-1 cursor-pointer absolute right-1 top-2"
                                onClick={() => removeFile()}
                                disabled={disabled}
                                data-bolt="stimulus-remove-button"
                              >
                                <svg width="22" height="22" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g opacity="0.8">
                                    <path
                                      d="M12.6199 4.76041L4.94705 12.4332C4.66476 12.7155 4.66476 13.1732 4.94705 13.4555L4.9478 13.4562C5.23008 13.7385 5.68775 13.7385 5.97004 13.4562L13.6429 5.78341C13.9251 5.50112 13.9251 5.04345 13.6429 4.76117L13.6421 4.76041C13.3598 4.47813 12.9021 4.47813 12.6199 4.76041Z"
                                      fill="white"
                                    />
                                    <path
                                      d="M4.94708 5.78274L12.6199 13.4556C12.9022 13.7378 13.3598 13.7378 13.6421 13.4556L13.6429 13.4548C13.9252 13.1725 13.9252 12.7148 13.6429 12.4326L5.97007 4.75975C5.68779 4.47746 5.23012 4.47747 4.94784 4.75975L4.94708 4.7605C4.6648 5.04279 4.6648 5.50046 4.94708 5.78274Z"
                                      fill="white"
                                    />
                                  </g>
                                </svg>
                              </button>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="py-3 px-8 rounded-[45px] bg-[#7039BE] bg-opacity-10">
                              <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M3.32992 16.848C2.80021 16.848 2.2922 16.6375 1.91763 16.263C1.54307 15.8884 1.33264 15.3804 1.33264 14.8507V11.9974C1.33264 11.7704 1.42282 11.5527 1.58335 11.3921C1.74388 11.2316 1.9616 11.1414 2.18862 11.1414C2.41564 11.1414 2.63336 11.2316 2.79389 11.3921C2.95442 11.5527 3.0446 11.7704 3.0446 11.9974V14.8507C3.0446 15.0082 3.17242 15.136 3.32992 15.136H15.3136C15.3893 15.136 15.4619 15.1059 15.5154 15.0524C15.5689 14.9989 15.5989 14.9263 15.5989 14.8507V11.9974C15.5989 11.7704 15.6891 11.5527 15.8497 11.3921C16.0102 11.2316 16.2279 11.1414 16.4549 11.1414C16.6819 11.1414 16.8997 11.2316 17.0602 11.3921C17.2207 11.5527 17.3109 11.7704 17.3109 11.9974V14.8507C17.3109 15.3804 17.1005 15.8884 16.7259 16.263C16.3513 16.6375 15.8433 16.848 15.3136 16.848H3.32992Z"
                                  fill="#7039BE"
                                />
                                <path
                                  d="M13.6359 6.25665C13.7153 6.33609 13.7783 6.43039 13.8213 6.53418C13.8643 6.63797 13.8864 6.74921 13.8864 6.86155C13.8864 6.97388 13.8643 7.08512 13.8213 7.18891C13.7783 7.2927 13.7153 7.387 13.6359 7.46644C13.5565 7.54587 13.4622 7.60888 13.3584 7.65187C13.2546 7.69486 13.1433 7.71699 13.031 7.71699C12.9187 7.71699 12.8074 7.69486 12.7036 7.65187C12.5999 7.60888 12.5055 7.54587 12.4261 7.46644L10.1777 5.21921V11.7121C10.1777 11.9391 10.0876 12.1568 9.92703 12.3174C9.76651 12.4779 9.54878 12.5681 9.32176 12.5681C9.09475 12.5681 8.87702 12.4779 8.7165 12.3174C8.55597 12.1568 8.46579 11.9391 8.46579 11.7121V5.21921L6.21742 7.46644C6.13798 7.54587 6.04368 7.60888 5.93989 7.65187C5.8361 7.69486 5.72486 7.71699 5.61253 7.71699C5.50019 7.71699 5.38895 7.69486 5.28516 7.65187C5.18137 7.60888 5.08707 7.54587 5.00763 7.46644C4.9282 7.387 4.86519 7.2927 4.8222 7.18891C4.77921 7.08512 4.75708 6.97388 4.75708 6.86155C4.75708 6.74921 4.77921 6.63797 4.8222 6.53418C4.86519 6.43039 4.9282 6.33609 5.00763 6.25665L8.71687 2.54741C8.79627 2.46791 8.89056 2.40483 8.99435 2.3618C9.09814 2.31876 9.2094 2.29661 9.32176 2.29661C9.43413 2.29661 9.54539 2.31876 9.64918 2.3618C9.75297 2.40483 9.84726 2.46791 9.92666 2.54741L13.6359 6.25665Z"
                                  fill="#7039BE"
                                />
                              </svg>
                            </div>
                            <p className="text-[#414141] mt-6">
                              <button className="text-[#7039BE] cursor-pointer" onClick={openFileExplorer} disabled={disabled} data-bolt='stimulus-upload-button'>
                                Click here
                              </button>{' '}
                              to upload or drop media here
                            </p>
                          </>
                        )}
                      </form>
                    </div>
                    <div className="border-b-4 border-dashed border-[#EEEEEE] mt-6"></div>
                    <div className="mt-4 flex justify-end gap-1">
                      <Button variant="base" onClick={() => handleCancel()} dataBolt='stimulus-cancel-button'>
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => (editMode?.fileUrl ? handleUpdate() : handleNext())}
                        disabled={!description || !fileUrl || loadingSave}
                        loading={loadingSave}
                        dataBolt='stimulus-save-button'
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export { StimulusComponent }
