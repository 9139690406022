import { ApiKey, ChatBuilder, ChatPrisma } from "@bolt-chat-core/chat-prisma-service-interface"
import { _genericCount, _genericQuery } from "../query.service"
import { AuthApiKeyUserLoginResponse, ExternalChatBuilderValidateAndRedirectGatewayResponse, ExternalChatBuilderValidateAndRedirectResponse, IResponse } from "@bolt-chat-core/common"
import { API_URL } from "../api.axios"
import { AuthApiKeyCreateDtoType, AuthApiKeyUpdateDtoType } from "@bolt-chat-core/common-type"
import ApiService from "../api.axios"
import { ENDPOINTS } from "@bolt-chat-core/common-type"
import { ExternalChatBuilderCreateDtoType } from "../../../../common/src/packages/index-type"
import axios from "axios"

// -- init custom axios instance
const chatBuilderAxios = axios.create()

// ----------------------- Core operations -----------------------

export const _chatBuilderQuery = async (payload: ChatPrisma.ChatBuilderFindManyArgs): Promise<IResponse<ChatBuilder[]>> => {
  const response = await _genericQuery<ChatPrisma.ChatBuilderFindManyArgs, ChatBuilder>(ChatPrisma.ModelName.ChatBuilder, payload)
  return response
}

export const _chatBuilderCount = async (payload: ChatPrisma.ChatBuilderWhereInput): Promise<IResponse<number>> => {
  const response = await _genericCount<ChatPrisma.ChatBuilderWhereInput>(ChatPrisma.ModelName.ChatBuilder, payload)
  return response
}

export const _chatBuilderSearch = async (
  field: ChatPrisma.ChatBuilderScalarFieldEnum,
  searchKey: string,
  skip: number = 0,
  take: number = 10,
): Promise<IResponse<ChatBuilder[]>> => {
  const response = await _genericQuery<ChatPrisma.ChatBuilderFindManyArgs, IResponse<ChatBuilder[]>>(
    ChatPrisma.ModelName.ChatBuilder,
    {
      where: {
        [field]: {
          contains: searchKey,
        },
      },
      skip,
      take,
    },
  )
  return response
}

export const _getChatBuilder = async (chatBuilderId: string): Promise<IResponse<ChatBuilder[]>> => {
  const response = await _genericQuery<ChatPrisma.ChatBuilderFindManyArgs, IResponse<ChatBuilder[]>>("ChatBuilder", {
    where: {
      id: chatBuilderId,
    },
  })
  if (response.success) response.data = response.data[0]
  return response
}

export const _getChatBuilderByProjectId = async (projectId: string): Promise<IResponse<ChatBuilder[]>> => {
  const response = await _genericQuery<ChatPrisma.ChatBuilderFindManyArgs, IResponse<ChatBuilder[]>>("ChatBuilder", {
    where: {
      projectId,
    },
  })
  if (response.success) response.data = response.data[0]
  return response
}

// ----------------------- Api operations -----------------------

export const _createChatBuilder = async (data: ExternalChatBuilderCreateDtoType): Promise<IResponse<ChatBuilder>> => {
  const response = await ApiService.post<ChatBuilder>(`${API_URL}${ENDPOINTS.EXTERNAL.CHAT_BUILDER.CREATE.URL}`, data)
  return response.data
}

export const _chatBuilderValidateAndRedirect = async (chatBuilderId: string, token: string): Promise<IResponse<ExternalChatBuilderValidateAndRedirectGatewayResponse>> => {
  const response = await ApiService.post<ChatBuilder>(
    `${API_URL}${ENDPOINTS.EXTERNAL.CHAT_BUILDER.VALIDATE_AND_REDIRECT.URL}?chatBuilderId=${chatBuilderId}&token=${token}`,
  )
  return response.data
}

export const _getChatBuilderReport = async (chatBuilderId: string): Promise<IResponse<ChatBuilder>> => {
  const response = await ApiService.get<ChatBuilder>(
    `${API_URL}${ENDPOINTS.EXTERNAL.CHAT_BUILDER.GET_REPORT.URL}?chatBuilderId=${chatBuilderId}`,
  )
  return response.data
}

// ----------------------- Api Test operations -----------------------

export const _apiTestCreateChatBuilder = async (params: {
  apiKey: string
  data: ExternalChatBuilderCreateDtoType
}): Promise<IResponse<ChatBuilder>> => {
  const response = await chatBuilderAxios.post<ChatBuilder>(
    `${API_URL}${ENDPOINTS.EXTERNAL.CHAT_BUILDER.CREATE.URL}`,
    params.data,
    {
      headers: {
        "x-api-key": params.apiKey,
      },
    },
  )
  return response.data
}

export const _apiTestChatBuilderValidateAndRedirect = async (params: {
  apiKey: string
  data: { chatBuilderId: string; token: string }
}): Promise<IResponse<ExternalChatBuilderValidateAndRedirectGatewayResponse>> => {
  const response = await chatBuilderAxios.post<ChatBuilder>(
    `${API_URL}${ENDPOINTS.EXTERNAL.CHAT_BUILDER.VALIDATE_AND_REDIRECT.URL}?chatBuilderId=${params.data.chatBuilderId}&token=${params.data.token}`,
    {
      headers: {
        "x-api-key": params.apiKey,
      },
    },
  )
  return response.data
}

export const _apiTestGetChatBuilderReport = async (params: {
  apiKey: string
  data: { chatBuilderId: string }
}): Promise<IResponse<ChatBuilder>> => {
  const response = await chatBuilderAxios.get<ChatBuilder>(
    `${API_URL}${ENDPOINTS.EXTERNAL.CHAT_BUILDER.GET_REPORT.URL}?chatBuilderId=${params.data.chatBuilderId}`,
    {
      headers: {
        "x-api-key": params.apiKey,
      },
    },
  )
  return response.data
}
