import { useEffect, useState } from 'react'
import { closeAllToasts, notifyOffline } from '../components/common/toast/toast.component'

function UseNetwork() {
  const [status, setStatus] = useState(true)
  useEffect(() => {
    function changeStatus() {
      setStatus(navigator?.onLine)
    }
    window.addEventListener('online', changeStatus)
    window.addEventListener('offline', changeStatus)
    return () => {
      window.removeEventListener('online', changeStatus)
      window.removeEventListener('offline', changeStatus)
    }
  }, [status])
  return status
}
export { UseNetwork }
