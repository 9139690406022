const SetupLogo = () => {
  return (
    <a href="/">
      <svg width="38" height="38" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M29.9889 14.9446C29.9612 7.49452 23.9024 0.809381 16.5521 0.0721306C9.20177 -0.659577 2.47228 4.11315 0.532147 11.4413C-1.49113 19.0799 2.41131 26.9901 9.67295 29.928C11.3581 30.6098 13.1208 30.948 14.9279 30.959C20.0166 30.9868 24.8392 28.7251 29.3736 31.0588C29.5066 31.1253 29.6508 31.1586 29.9335 31.2584C29.9612 30.8371 29.9889 30.5378 29.9889 30.2384C29.9889 24.03 30.0111 21.1586 29.9889 14.9502V14.9446ZM10.5987 16.8515C9.551 16.8515 8.70842 16.0034 8.70842 14.9613C8.70842 13.9191 9.55654 13.071 10.5987 13.071C11.6408 13.071 12.4889 13.9191 12.4889 14.9613C12.4889 16.0034 11.6408 16.8515 10.5987 16.8515ZM15.0166 16.8515C13.969 16.8515 13.1264 16.0034 13.1264 14.9613C13.1264 13.9191 13.9745 13.071 15.0166 13.071C16.0588 13.071 16.9069 13.9191 16.9069 14.9613C16.9069 16.0034 16.0588 16.8515 15.0166 16.8515ZM19.4346 16.8515C18.3869 16.8515 17.5443 16.0034 17.5443 14.9613C17.5443 13.9191 18.3925 13.071 19.4346 13.071C20.4767 13.071 21.3248 13.9191 21.3248 14.9613C21.3248 16.0034 20.4767 16.8515 19.4346 16.8515Z"
          fill="#1ED6BB"
        />
      </svg>
    </a>
  )
}

export { SetupLogo }
