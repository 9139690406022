interface HamburgerMenuProps {
  width: number | null
  height: number
}
const HamburgerMenu = (props: HamburgerMenuProps) => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 512 512"
      height={props.height}
      width={props.width !== null ? props.width : ''}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M32 96v64h448V96H32zm0 128v64h448v-64H32zm0 128v64h448v-64H32z"></path>
    </svg>
  )
}

export { HamburgerMenu }
