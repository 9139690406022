import { useEffect, useState } from 'react'
import { Customer, Profile, SubscriptionType, UserRole } from '@prisma/client/chat'
import { _getUser, _teamQuery } from '@bolt-chat-core/shared-ui'
import { useRouter } from 'next/router'
export interface UserType {
  id: string
  email: string
  fullname: string
  customer: string | null
  country: string | null
  profile: Profile
  lockedCount: number
  createdAt: Date
  updatedAt: Date
  Customer: Customer
  customerId: string
  role: UserRole
  credit: number
  subscriptionType: SubscriptionType
  hasAccessToManualProjects: boolean
}
function useCreditCheck(props: { userId: string | undefined }) {
  const [user, setUser] = useState<UserType | undefined>()
  const [teams, setTeams] = useState<
    Array<{
      name: string
      description: string
      subscriptionType: string
      credits: number
    }>
  >([])
  const router = useRouter()
  useEffect(() => {
    async function checkCredit() {
      const excludePages =
        router.pathname === '/chat/chatapp' || router.pathname === '/404' || router.pathname.includes('/access/chat-builder')
      if (props.userId !== undefined && !excludePages) {
        const response = await _getUser(props.userId)
        if (response.success) {
          if (response.data.teamIds.length > 0) {
            const teamResponse = await _teamQuery({
              where: {
                OR: response.data.teamIds.map((e: string) => {
                  return { id: e }
                }),
              },
              select: {
                id: true,
                name: true,
                credits: true,
                description: true,
                subscriptionType: true,
                memberIds: true,
                createdAt: true,
                updatedAt: true,
                deletedAt: true,
                sharedProjectIds: true,
              },
            })
            setTeams(teamResponse.data)
          }
          setUser(response.data)
        }
      }
    }
    checkCredit()
  }, [props.userId])
  return { user, teams }
}
export { useCreditCheck }
