import React from 'react';
import { CircularProgress } from '@mui/material';

interface BasicLoadingProps {
  message?: string;
  className?: string;
}

const BasicLoading: React.FC<BasicLoadingProps> = ({ message, className }) => {
  return (
    <div
      className={`w-full h-full flex flex-col justify-center items-center m-2 ${
        className || ''
      }`}
    >
      <CircularProgress />
      <span className="mt-5">{message || 'Loading...'}</span>
    </div>
  );
};

export default BasicLoading;
