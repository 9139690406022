/* eslint-disable @typescript-eslint/no-explicit-any */
// Import statements remain the same
import {create} from "zustand"
import { persist } from "zustand/middleware"
import { ProjectState } from "../types/project-types"
import { Projects } from "@prisma/client/chat"

export const useProjectStore = create<ProjectState>()(
  persist(
    (set, get) => ({
      project: null,
      loading: false,
      metaCount: 0,
      update: (project: Projects | any) => {
        set({ project })
      },
      setLoading: (loading: boolean) =>{
        set({loading: loading})
      },
      setMetaCount: (count: number) =>{
        set({metaCount: count})
      },
      get: () => {
        const { project } = get()
        return project || null
      },

    }),
    {
      name: "project",
    },
  ),
)
