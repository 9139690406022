import { ApiKey, ChatPrisma } from "@bolt-chat-core/chat-prisma-service-interface"
import { _genericCount, _genericQuery } from "../query.service"
import { AuthApiKeyUserLoginResponse, IResponse } from "@bolt-chat-core/common"
import { API_URL } from "../api.axios"
import { AuthApiKeyCreateDtoType, AuthApiKeyUpdateDtoType} from "@bolt-chat-core/common-type"
import ApiService from "../api.axios"
import { ENDPOINTS } from "@bolt-chat-core/common-type"

// ----------------------- Core operations -----------------------
export const _apiKeyQuery = async (payload: ChatPrisma.ApiKeyFindManyArgs): Promise<IResponse<ApiKey[]>> => {
    const response = await _genericQuery<ChatPrisma.ApiKeyFindManyArgs, ApiKey>(ChatPrisma.ModelName.ApiKey, payload)
    return response
}

export const _apiKeyCount = async (payload: ChatPrisma.ApiKeyWhereInput): Promise<IResponse<number>> => {
    const response = await _genericCount<ChatPrisma.ApiKeyWhereInput>(ChatPrisma.ModelName.ApiKey, payload)
    return response
    }

export const _apiKeySearch = async (
    field: ChatPrisma.ApiKeyScalarFieldEnum,
    searchKey: string,
    skip: number = 0,
    take: number = 10,
    ): Promise<IResponse<ApiKey[]>> => {
    const response = await _genericQuery<ChatPrisma.ApiKeyFindManyArgs, IResponse<ApiKey[]>>(ChatPrisma.ModelName.ApiKey, {
        where: {
            [field]: {
                contains: searchKey,
            },
        },
        skip,
        take,
    })
    return response
    }

export const _getApiKey = async (apiKeyId: string): Promise<IResponse<ApiKey[]>> => {
    const response = await _genericQuery<ChatPrisma.ApiKeyFindManyArgs, IResponse<ApiKey[]>>("ApiKey", {
        where: {
            id: apiKeyId,
        },
    })
    if (response.success) response.data = response.data[0]
    return response
}

// ----------------------- Api operations -----------------------

export const _createApiKey = async (
    data: AuthApiKeyCreateDtoType,
    ): Promise<IResponse<ApiKey>> => {
    const response = await ApiService.post<ApiKey>(`${API_URL}${ENDPOINTS.AUTH.API_KEY_CREATE.URL}`, data)
    return response.data
}

export const _updateApiKey = async (
    data: AuthApiKeyUpdateDtoType,
    ): Promise<IResponse<ApiKey>> => {
    const response = await ApiService.post<ApiKey>(`${API_URL}${ENDPOINTS.AUTH.API_KEY_UPDATE.URL}`, data)
    return response.data
}

export const _deleteApiKey = async (apiKeyId: string): Promise<IResponse<ApiKey>> => {
    const response = await ApiService.delete<ApiKey>(`${API_URL}${ENDPOINTS.AUTH.API_KEY_DELETE.URL}?apiKeyId=${apiKeyId}`)
    return response.data
}

export const _loginWithApiKey = async (apiKey: string): Promise<IResponse<AuthApiKeyUserLoginResponse>> => {
    const response = await ApiService.post(`${API_URL}${ENDPOINTS.AUTH.API_KEY_USER_LOGIN.URL}?apiKey=${apiKey}`)
    return response.data
}
