import { useState, useCallback } from 'react'

const useDebounceSearch = (cb: (...args: any[]) => void, delay: number) => {
  const [debounceTimeout, setDebounceTimeout] = useState<null | ReturnType<typeof setTimeout>>(null)

  const debouncedFunction = useCallback(
    (...args: any[]) => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout)
      }

      const timeoutId = setTimeout(() => {
        cb(...args)
      }, delay)

      setDebounceTimeout(timeoutId)
    },
    [cb, delay, debounceTimeout],
  )

  return debouncedFunction
}
export { useDebounceSearch }
