export const downloadFile = (blob: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(blob)
  const element = document.createElement('a')

  element.href = url
  element.download = fileName

  document.body.appendChild(element)

  element.click()
  element.remove()
}