/* eslint-disable @typescript-eslint/no-explicit-any */
import { _updateProject } from '@bolt-chat-core/shared-ui'
import React, { createContext, useContext, useState } from 'react'
import { useProjectStore } from '../stores/project-store'

type TryAgainContextType = {
  open: boolean
  unSavedData: { unSavedData: any; projectId: any }
  handleOpen: (status: boolean) => void
  handleTryAgain: () => void
  handleUnSavedData: (unSavedData: any, projectId: any) => void
}

const TryAgainContext = createContext<TryAgainContextType>({} as TryAgainContextType)

export function TryAgainProvider({ children }: { children: React.ReactNode }) {
  const [open, setOpen] = useState(false)
  const [unSavedData, setUnSavedData] = useState({ unSavedData: null, projectId: null })
  const handleOpen = (status: boolean) => {
    setOpen(status)
    setUnSavedData({ unSavedData: null, projectId: null })
  }
  const handleUnSavedData = (unSavedData: any, projectId: any) => {
    setOpen(true)
    setUnSavedData({ unSavedData: unSavedData, projectId: projectId })
  }
  const handleTryAgain = async () => {
    if (unSavedData.unSavedData !== null && unSavedData.projectId !== null) {
      try {
        const { data, success } = await _updateProject(unSavedData.unSavedData, unSavedData.projectId)
        if (success) {
          useProjectStore.getState().update(data)
          handleOpen(false)
          setUnSavedData({ unSavedData: null, projectId: null })
        }
      } catch (error) {
        /* console.error('Failed to save data', error) */
      } finally {
        useProjectStore.getState().setLoading(false)
      }
    }
  }
  return (
    <TryAgainContext.Provider value={{ open, handleOpen, handleTryAgain, unSavedData, handleUnSavedData }}>
      {children}
    </TryAgainContext.Provider>
  )
}

export const useTryAgainContext = () => {
  return useContext(TryAgainContext)
}
