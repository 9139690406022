import { Projects } from "@prisma/client/chat"
import { CONFIG } from "../config/init.config"

export const internalUrlBuilder = (project: Projects, projectId: string, ref: string) => {
  const country = project?.setups[0]?.steps?.location?.countrycodes[0]
  const url = `${CONFIG.BASE_URL}/chat-core/participant/participant-start?lang=${country === "en_GB"
    ? "en"
    : country === "en_US"
      ? "en"
      : country === "ar_AE"
        ? "ar"
        : country === "fr_FR"
          ? "fr"
          : country === "de_DE"
            ? "de"
            : country === "it_IT"
              ? "it"
              : country === "es_ES"
                ? "es"
                : country === "en_CA"
                  ? "en"
                  : country === "fr_CA"
                    ? "fr"
                    : country === "vi_VN"
                      ? "vi"
                      : country === "tr_TR"
                        ? "tr"
                        : country === "zh_CN"
                          ? "zh"
                          : country === "en_AU"
                            ? "en"
                            : country === "nl_NL"
                              ? "nl"
                              : country === "es_MX"
                                ? "es-mx"
                                : country === "hi_IN"
                                  ? "hi"
                                  : country === "en_ZA"
                                    ? "en"
                                    : country === "ar_SA"
                                      ? "ar"
                                      : country === "ja_JP"
                                        ? "ja"
                                        : country === "en_SG"
                                          ? "en"
                                          : country === "th_TH"
                                            ? "th"
                                            : country === "id_ID"
                                              ? "id"
                                              : country === "pt_BR"
                                                ? "pt-br"
                                                : country === "ar_EG"
                                                  ? "ar"
                                                  : country === "kk_KZ"
                                                    ? "kk"
                                                    : country === "de_CH"
                                                      ? "de"
                                                      : country === "pl_PL"
                                                        ? "pl"
                                                        : country === "ro_RO"
                                                          ? "ro"
                                                          : country === "en_HK"
                                                            ? "en"
                                                            : country === "en_IE"
                                                              ? "en"
                                                              : country === "pt_PT"
                                                                ? "pt"
                                                                : country === "es_CL"
                                                                  ? "es"
                                                                  : country === "es_CO"
                                                                    ? "es"
                                                                    : country === "tl_PH"
                                                                      ? "tl"
                                                                      : country === "zh_TW"
                                                                        ? "zh-tw"
                                                                        : country === "sv_SE"
                                                                          ? "sv"
                                                                          : country === "ko_KR"
                                                                            ? "ko"
                                                                            : country === "ms_MY"
                                                                              ? "ms"
                                                                              : country === "cs_CZ"
                                                                                ? "cs"
                                                                                : "en"
    }&projectId=${projectId}&cid=${country}${ref === "pure"
      ? "&ref=pure&age=21&gender=111&relationship=114&hasKid=112&employment=114&education=115&region=111&test=1"
      : ref === "pure"
        ? `&ref=lucid${country === "en_GB"
          ? "&state=3"
          : country === "en_US"
            ? "&state=1"
            : country === "fr_FR"
              ? "&state=1"
              : country === "de_DE"
                ? "&state=1"
                : country === "it_IT"
                  ? "&state=1"
                  : country === "es_ES"
                    ? "&state=1"
                    : country === "vi_VN"
                      ? "&state=1"
                      : country === "tr_TR"
                        ? "&state=1"
                        : country === "zh_CN"
                          ? "&state=1"
                          : country === "en_AU"
                            ? "&state=1"
                            : country === "nl_NL"
                              ? "&state=1"
                              : country === "es_MX"
                                ? "&state=1"
                                : country === "hi_IN"
                                  ? "&state=1"
                                  : country === "ja_JP"
                                    ? "&state=1"
                                    : country === "en_SG"
                                      ? "&state=1"
                                      : country === "th_TH"
                                        ? "&state=1"
                                        : country === "id_ID"
                                          ? "&state=1"
                                          : country === "pt_BR"
                                            ? "&state=1"
                                            : country === "ar_EG"
                                              ? "&state=1"
                                              : country === "kk_KZ"
                                                ? "&state=1"
                                                : country === "de_CH"
                                                  ? "&state=1"
                                                  : country === "pl_PL"
                                                    ? "&state=1"
                                                    : country === "ro_RO"
                                                      ? "&state=1"
                                                      : country === "en_HK"
                                                        ? "&state=1"
                                                        : country === "en_IE"
                                                          ? "&state=1"
                                                          : country === "pt_PT"
                                                            ? "&state=1"
                                                            : ""
        }&relationship=1&employment=1&age=22&gender=1&isMobile=true&hasKid=2&RID=123&test=1`
        : `&ref=prodege&relationship=1&employment=1&age=22&gender=1&isMobile=true&hasKid=2&region=1&test=1`
    }`

  return url
}

export const externalUrlBuilder = (countrycode: string, projectId: string) => {
  const url = `${CONFIG.BASE_URL}/chat-core/participant/participant-start?lang=${countrycode === "en_GB"
    ? "en"
    : countrycode === "en_US"
      ? "en"
      : countrycode === "ar_AE"
        ? "ar"
        : countrycode === "fr_FR"
          ? "fr"
          : countrycode === "de_DE"
            ? "de"
            : countrycode === "it_IT"
              ? "it"
              : countrycode === "es_ES"
                ? "es"
                : countrycode === "en_CA"
                  ? "en"
                  : countrycode === "fr_CA"
                    ? "fr"
                    : countrycode === "vi_VN"
                      ? "vi"
                      : countrycode === "tr_TR"
                        ? "tr"
                        : countrycode === "zh_CN"
                          ? "zh"
                          : countrycode === "en_AU"
                            ? "en"
                            : countrycode === "nl_NL"
                              ? "nl"
                              : countrycode === "es_MX"
                                ? "es-mx"
                                : countrycode === "hi_IN"
                                  ? "hi"
                                  : countrycode === "en_ZA"
                                    ? "en"
                                    : countrycode === "ar_SA"
                                      ? "ar"
                                      : countrycode === "ja_JP"
                                        ? "ja"
                                        : countrycode === "en_SG"
                                          ? "en"
                                          : countrycode === "th_TH"
                                            ? "th"
                                            : countrycode === "id_ID"
                                              ? "id"
                                              : countrycode === "pt_BR"
                                                ? "pt-br"
                                                : countrycode === "ar_EG"
                                                  ? "ar"
                                                  : countrycode === "kk_KZ"
                                                    ? "kk"
                                                    : countrycode === "de_CH"
                                                      ? "de"
                                                      : countrycode === "pl_PL"
                                                        ? "pl"
                                                        : countrycode === "ro_RO"
                                                          ? "ro"
                                                          : countrycode === "en_HK"
                                                            ? "en"
                                                            : countrycode === "en_IE"
                                                              ? "en"
                                                              : countrycode === "pt_PT"
                                                                ? "pt"
                                                                : countrycode === "es_CL"
                                                                  ? "es"
                                                                  : countrycode === "es_CO"
                                                                    ? "es"
                                                                    : countrycode === "tl_PH"
                                                                      ? "tl"
                                                                      : countrycode === "zh_TW"
                                                                        ? "zh-tw"
                                                                        : countrycode === "sv_SE"
                                                                          ? "sv"
                                                                          : countrycode === "ko_KR"
                                                                            ? "ko"
                                                                            : countrycode === "ms_MY"
                                                                              ? "ms"
                                                                              : countrycode === "cs_CZ"
                                                                                ? "cs"
                                                                                : "en"
    }&projectId=${projectId}&cid=${countrycode}&ref=external&test=1`

  return url
}
