import React, { ReactNode, HTMLAttributes } from 'react';

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  className?: string;
}

const Card: React.FC<CardProps> = ({ children, className = '', ...rest }) => {
  return (
    <div
      className={`flex gap-4 bg-[#23252B] border border-[#2B2D34] pl-2 pt-3 pr-5 pb-3 rounded-lg ${className}`}
      {...rest}
    >
      {children}
    </div>
  );
};

export {Card};
