interface LogoProps {
  width: number
  height: number
}

const Logo = (props: LogoProps) => {
  return (
    <a href="/">
      <img src="/svg/navbar/boltchat_ai_03_beyaz_ai_cyan.svg" height={props.height} width={props.width} alt="Bolt Logo" />
    </a>
  )
}

export { Logo }
