import { _genericCount, _genericQuery } from "../../services/query.service"
import ApiService, { API_URL } from "../api.axios"
import {  AuthNLoginWithMsalDtoType, AuthNRegisterWithTokenDtoType, ENDPOINTS } from "@bolt-chat-core/common-type"
import { AuthNLoginResponse, AuthNRegisterResponse, AuthNRegisterWithTokenResponse, IResponse } from "@bolt-chat-core/common"
import {
  AuthNResetPasswordDtoType,
  AuthNRegisterDtoType,
  AuthNLoginDtoType,
  AuthNInviteUserDtoType,
  AuthNForgetPasswordDtoType,
} from "@bolt-chat-core/common-type"

export const _login = async (payload: AuthNLoginDtoType): Promise<IResponse<AuthNLoginResponse>> => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.AUTH.LOGIN.URL}`, payload)
  return response.data
}

export const _loginWithMsal = async (payload: AuthNLoginWithMsalDtoType): Promise<IResponse<AuthNLoginResponse>> => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.AUTH.MSAL_LOGIN.URL}`, payload)
  return response.data
}

export const _inviteUser = async (payload: AuthNInviteUserDtoType): Promise<IResponse<any>> => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.AUTH.INVITE_USER.URL}`, payload)
  return response.data
}

export const _register = async (payload: AuthNRegisterDtoType): Promise<IResponse<AuthNRegisterResponse>> => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.AUTH.REGISTER.URL}`, payload)
  return response.data
}

export const _forgotPassword = async (payload: AuthNForgetPasswordDtoType): Promise<IResponse<any>> => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.AUTH.FORGOT_PASSWORD.URL}`, payload)
  return response.data
}

export const _resetPassword = async (payload: AuthNResetPasswordDtoType): Promise<IResponse<any>> => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.AUTH.RESET_PASSWORD.URL}`, payload)
  return response.data
}

export const _registerWithToken = async (
  payload: AuthNRegisterWithTokenDtoType,
): Promise<IResponse<AuthNRegisterWithTokenResponse>> => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.AUTH.REGISTER_WITH_TOKEN.URL}`, payload)
  return response.data
}

export const _logout = async (userId: string): Promise<IResponse<any>> => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.AUTH.LOGOUT.URL}?userId=${userId}`)
  return response.data
}