/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AppProps } from 'next/app'
import '../styles/global.css'
import { Toast, closeAllToasts, msalConfig, notifyOffline } from '@bolt-chat-core/shared-ui'
import { ReactQueryProvider } from '../providers/react-query-provider'
import { FpjsProvider, defaultEndpoint, defaultScriptUrlPattern } from '@fingerprintjs/fingerprintjs-pro-react'
import { UseNetwork } from '@bolt-chat-core/shared-ui'
import { useAuthStore } from '../stores/auth-store'
import { useEffect } from 'react'
import { useCreditCheck } from '../hooks/useCreditCheck'
import { TryAgainProvider } from '../providers/try-again-provider'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { Team } from '@bolt-chat-core/chat-prisma-service-interface'
import { useRouter } from 'next/router'
import PendingPage from './pending'

// fingerprint public key
const FINGERPRINT_PUBLIC_KEY = process.env.NEXT_PUBLIC_FINGERPRINT_PUBLIC as string

// setup msal
export const msalInstance = new PublicClientApplication(msalConfig)

export default function MyApp({ Component, pageProps }: AppProps) {
  const userId = useAuthStore((state) => state.user?.id)
  const selectedTeam = useAuthStore().selectedTeam
  const { user, teams }: any = useCreditCheck({ userId })
  const status = UseNetwork()
  const router = useRouter()
  useEffect(() => {
    if (user !== undefined) {
      useAuthStore.getState().update(user)
      if (user.status === 'PENDING' || user.status === 'REJECTED') {
        router.push('/pending')
        return
      }
    }
    if (teams?.length > 0) {
      useAuthStore.getState().updateTeams(teams)
      if (selectedTeam) {
        const filteredTeam = teams.filter((e: Team) => e.id === selectedTeam.id)
        useAuthStore.getState().updateSelectedTeam(filteredTeam[0])
      } else {
        useAuthStore.getState().updateSelectedTeam(teams[0])
      }
    }
  }, [user, teams])
  useEffect(() => {
    if (!status) {
      notifyOffline()
    } else {
      closeAllToasts()
    }
  }, [status])
  return (
    <>
      <ReactQueryProvider>
        <MsalProvider instance={msalInstance}>
          <TryAgainProvider>
            <FpjsProvider
              loadOptions={{
                apiKey: FINGERPRINT_PUBLIC_KEY,
                endpoint: ['https://chatmetrics.boltinsight.com', defaultEndpoint],
                scriptUrlPattern: [
                  'https://chatmetrics.boltinsight.com/web/v3/CQ60lw922EyxLbDSwrX9/loader_v3.8.3.js',
                  defaultScriptUrlPattern,
                ],
                region: 'eu',
              }}
            >
              <Toast />
              {user?.status === 'ACTIVE' || (user && user?.status === null) || !user ? (
                <Component {...pageProps} />
              ) : (
                <PendingPage />
              )}
            </FpjsProvider>
          </TryAgainProvider>
        </MsalProvider>
      </ReactQueryProvider>
    </>
  )
}
