import { create } from "zustand"
import { persist } from "zustand/middleware"
import { AuthState, Team, UserType } from "../types/authTypes"

export const useAuthStore = create<AuthState>()(
  persist(
    (set, get) => ({
      user: null,
      accessToken: null,
      teams: [],
      selectedTeam: null,
      login: (accessToken: string,  user: UserType | undefined) => {
        set({ accessToken,  user })
      },
      register: (accessToken: string, user: UserType | undefined) => {
        set({ accessToken,  user })
      },
      updateCredit: (val: number) => {
        set((state: any) => ({
          user: { ...state.user, credit: val },
        }))
      },
      updateTeams: (teams: Array<Team>)=>{
        set(()=>({
          teams: teams
        }))
      },
      updateSelectedTeam: (team: Team)=>{
        set(()=>({
          selectedTeam: team
        }))
      },
      logout: () => {
        set({ accessToken: null, user: null, teams: [], selectedTeam: null })
      },
      useAuthenticated: () => {
        const { user } = get()
        return !!user
      },
      update: (user) => {
        set({ user })
      },
      getUser: () => {
        const { user } = get()
        return user || null
      },
    }),
    {
      name: "auth",
    },
  ),
)
