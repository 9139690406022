export const categories = [
  {
    category: { label: "Automotive", pureCode: "211", lucidCode: "1" },
    subcategory: ["E-Scooter", "Electric Car", "Motorbike/Scooter", "Petrol Car"],
  },
  {
    category: { label: "Baby Care", pureCode: "212", lucidCode: "17" },
    subcategory: [
      "Baby Bath",
      "Baby Food",
      "Baby Lotion",
      "Baby Moisturiser",
      "Baby Oil",
      "Baby Powder",
      "Baby Wipes",
      "Nappies",
      "Nappy Rash Cream",
      "Formula Milk",
    ],
  },
  {
    category: { label: "Beverage", pureCode: "213", lucidCode: "4" },
    subcategory: [
      "Beer",
      "Bottled Water",
      "Carbonated Drinks",
      "Cider",
      "Cocktails",
      "Coffee",
      "Concentrates",
      "Energy Drinks",
      "Fruit/Vegetable Juice",
      "Liqueur",
      "Spirits",
      "Tea",
      "Wine",
    ],
  },
  {
    category: {
      label: "Consumer Electronics",
      pureCode: "214",
      lucidCode: "6",
    },
    subcategory: [
      "Desktop Computer",
      "DVD Player",
      "Fitness Tracker",
      "Laptop Computer",
      "Mobile Phone",
      "Smart TV",
      "Smart Watch",
      "TV",
    ],
  },
  {
    category: {
      label: "Fashion and Accessories",
      pureCode: "217",
      lucidCode: "8",
    },
    subcategory: ["Accessories", "Childrenswear", "Footwear", "Jewellery", "Menswear", "Sportswear", "Watches", "Womenswear"],
  },
  {
    category: { label: "Financial Services", pureCode: "218", lucidCode: "9" },
    subcategory: [
      "Credit Card",
      "Life Insurance",
      "Mobile Insurance",
      "Mortgage",
      "Mortgage insurance",
      "Personal insurance",
      "Travel insurance",
    ],
  },
  {
    category: { label: "Food", pureCode: "219", lucidCode: "10" },
    subcategory: [
      "Bakery",
      "Candy/Sweets",
      "Cheese",
      "Chocolate",
      "Cream cheese",
      "Crisps",
      "Condiments",
      "Dressings",
      "Eggs",
      "Fish/seafood",
      "Fruits",
      "Frozen food",
      "Ice cream",
      "Meal replacements",
      "Meat",
      "Nuts",
      "Pasta/rice/starches",
      "Processed foods",
      "Pulses",
      "Ready Meals",
      "Sauces",
      "Savoury biscuits & crackers",
      "Soup",
      "Spreads",
      "Stock",
      "Sweet biscuits",
      "Vegetables",
      "Yogurt",
    ],
  },
  {
    category: { label: "Healthcare", pureCode: "220", lucidCode: "12" },
    subcategory: [
      "Allergy Remedies",
      "Cold Treatment",
      "Contact Lenses and Solutions",
      "Dietary Supplements",
      "Digestive Remedies",
      "Eyewear",
      "Herbal Products",
      "Menstrual Products",
      "Lenses",
      "OTC Analgesics",
      "OTC Cough",
      "Sleep Aids",
      "Smoking Cessation Aids",
      "Spectacles",
      "Sport Nutrition",
      "Sunglasses",
      "Paedriatic Health",
      "Vitamins",
    ],
  },
  {
    category: { label: "Home Care", pureCode: "222", lucidCode: "13" },
    subcategory: [
      "Dishwasher Detergent",
      "Fabric Conditioner",
      "Hand Dishwashing Liquid",
      "Laundry Detergent",
      "Stain Remover",
      "Surface Cleaner",
      "Toilet Cleaner",
    ],
  },
  {
    category: {
      label: "Personal Care and Beauty",
      pureCode: "226",
      lucidCode: "2",
    },
    subcategory: [
      "Bar Soap",
      "Body Moisturiser",
      "Colour Cosmetics/Make-up",
      "Conditioner",
      "Dental Floss",
      "Deodorants",
      "Depilatories",
      "Face Wash",
      "Face Moisturiser",
      "Fragrances",
      "Hair Colour",
      "Hair Treatment",
      "Hand Moisturiser",
      "Intimate Wash",
      "Intimate Wipes",
      "Nail Products",
      "Men's Grooming (shaving, after-shave toiletries)",
      "Mouthwash",
      "Shampoo",
      "Shower Gel",
      "Styling Products",
      "Sun Cream",
      "Toothbrush",
      "Toothpaste",
    ],
  },
  {
    category: { label: "Pet Care", pureCode: "227", lucidCode: "18" },
    subcategory: ["Cat Food", "Dog Food", "Other Pet Food"],
  },
  {
    category: {
      label: "Shopping and Retail",
      pureCode: "228",
      lucidCode: "29",
    },
    subcategory: ["Convenience Store", "Hypermarket", "Physical Market", "Online Shopping", "Supermarket"],
  },
  {
    category: { label: "Toys and Games", pureCode: "230", lucidCode: "25" },
    subcategory: [
      "Action Figures & Accessories",
      "Arts & Crafts",
      "Construction",
      "Dress Up & Role Play",
      "Dolls & Accessories",
      "Games & Puzzles",
      "Model Vehicles",
      "Outdoor & Sports",
      "Plush",
      "Remote Control Toys",
      "Ride-on Vehicles",
      "Scientific/Educational",
      "Video Games",
    ],
  },
  {
    category: { label: "Travel", pureCode: "231", lucidCode: "27" },
    subcategory: ["Boat Ride Ticket", "Bus Ticket", "Car Travel", "Cruise Ticket", "Plane Ticket", "Train Ticket"],
  },
  {
    category: { label: "Telecom", pureCode: "226", lucidCode: "2" },
    subcategory: ["Broadband", "Mobile Network Plan"],
  },
  {
    category: {
      label: "Major Household Appliances",
      pureCode: "226",
      lucidCode: "2",
    },
    subcategory: [
      "Refrigerator",
      "Deep Freezer",
      "Water Cooler / Dispensers",
      "Electric Oven",
      "Washing Machine",
      "Standalone Dryer",
      "Combined Washer & Dryer",
      "Dishwasher",
      "Air Conditioner",
      "Stove",
    ],
  },
  {
    category: {
      label: "Small Household Appliances",
      pureCode: "226",
      lucidCode: "2",
    },
    subcategory: [
      "Electric Kettle",
      "Coffee Maker",
      "Espresso Machine",
      "Bread Machine",
      "Air Fryer",
      "Deep Fryer",
      "Panini Press / Sandwich Toaster",
      "Instant Pot",
      "Toaster",
      "Electric Fan",
      "Vacuum Cleaner",
      "Robot Vacuum Cleaner",
      "Hair Dryer",
      "Hair Styling Tools",
      "Blender",
      "Electric Mixer",
      "Food Processor",
      "Juicers / Juice Extractors",
    ],
  },
  {
    category: {
      label: "Tobacco",
      pureCode: "226",
      lucidCode: "2",
    },
    subcategory: ["Classic cigarette", "Heated tobacco", "E-cigarette"],
  },
  {
    category: {
      label: "Home improvement, DIY",
      pureCode: "226",
      lucidCode: "2",
    },
    subcategory: [
      "Building materials",
      "DIY tools and equipment (power tools…)",
      "electrical and lighting",
      "Paint",
      "gardening and outdoor",
      "home decor and furnishings",
      "kitchen and bathroom renovations",
      "heating, ventilation and air conditioning",
    ],
  },
]
