interface Condition {
  label: string
  pureCode: string | string[]
  lucidCode: string | string[]
  prodegeCode: string | string[]
}

interface Section {
  code: string
  validCountries: string[]
  conditions: Condition[]
}

export interface SetupData {
  [key: string]: Section // Index signature for dynamic access
}

export const setupData: (country: string | undefined) => SetupData = (country) => {
  return {
    gender: {
      code: "211",
      validCountries: [
        "United States",
        "United Kingdom",
        "United Arab Emirates",
        "Spain",
        "Italy",
        "Germany",
        "France",
        "Canada",
        'Canada (French)',
        "Vietnam",
        "Turkey",
        "China",
        "Australia",
        "Netherlands",
        "Mexico",
        "India",
        "South Africa",
        "Saudi Arabia",
        "Japan",
        "Singapore",
        "Thailand",
        "Indonesia",
        "Brazil",
        "Switzerland",
        "Switzerland (French)",
        "Kazakhstan",
        "Egypt",
        "Portugal",
        "Poland",
        "Romania",
        "Hong Kong",
        "Ireland",
        "Chile",
        "Colombia",
        "Philippines",
        "Taiwan",
        "Sweden",
        "South Korea",
        "Malaysia",
        "Czech Republic"
      ],
      conditions: [
        {
          label: "Female",
          pureCode: "112",
          lucidCode: "2",
          prodegeCode: "2",
        },
        {
          label: "Male",
          pureCode: "111",
          lucidCode: "1",
          prodegeCode: "1",
        },
        {
          label: "Other",
          pureCode: "113",
          lucidCode: "3",
          prodegeCode: "3",
        },
      ],
    },
    relationship: {
      code: "217",
      validCountries: [
        "United States",
        "United Kingdom",
        "Spain",
        "Italy",
        "Germany",
        "France",
        "Canada",
        'Canada (French)',
        "Mexico",
        "Thailand",
        "Japan",
        "Chile",
        "Colombia",
        "Philippines",
        "Taiwan",
        "Sweden",
        "South Korea",
        "Malaysia",
      ],
      conditions: [
        {
          label: "Married",
          pureCode: "114",
          lucidCode: "2",
          prodegeCode: "6",
        },
        {
          label: "Single",
          pureCode: "111",
          lucidCode: "1",
          prodegeCode: "1",
        },
      ],
    },
    children: {
      code: "218",
      validCountries: [
        "United States",
        "United Kingdom",
        "Spain",
        "Italy",
        "Germany",
        "France",
        "Canada",
        'Canada (French)',
        "Turkey",
        "China",
        "Australia",
        "Netherlands",
        "Mexico",
        "India",
        "South Africa",
        "Japan",
        "Singapore",
        "Thailand",
        "Indonesia",
        "Brazil",
        "Switzerland",
        "Switzerland (French)",
        "Portugal",
        "Poland",
        "Romania",
        "Hong Kong",
        "Ireland",
        "Chile",
        "Colombia",
        "Philippines",
        "Taiwan",
        "Sweden",
        "South Korea",
        "Malaysia",
        "Czech Republic"
      ],
      conditions: [
        {
          label: "Have Children",
          pureCode: "112",
          lucidCode: "2",
          prodegeCode: "2",
        },
        {
          label: "No Children",
          pureCode: "111",
          lucidCode: "4",
          prodegeCode: "4",
        },
      ],
    },
    employment: {
      code: "215",
      validCountries: [
        "United States",
        "United Kingdom",
        "Spain",
        "Italy",
        "Germany",
        "France",
        "Canada",
        'Canada (French)',
        "Vietnam",
        "Turkey",
        "China",
        "Australia",
        "Netherlands",
        "Mexico",
        "India",
        "South Africa",
        "Japan",
        "Singapore",
        "Thailand",
        "Indonesia",
        "Brazil",
        "Switzerland",
        "Switzerland (French)",
        "Kazakhstan",
        "Portugal",
        "Poland",
        "Romania",
        "Hong Kong",
        "Ireland",
        "Chile",
        "Colombia",
        "Philippines",
        "Taiwan",
        "Sweden",
        "South Korea",
        "Malaysia",
        "Czech Republic"
      ],
      conditions: [
        {
          label: "Full Time Employed",
          pureCode: country === "Thailand" ? "111" : "114",
          lucidCode: "1",
          prodegeCode: "1",
        },
        {
          label: "Part Time Employed",
          pureCode: country === "Thailand" ? "112" : "113",
          lucidCode: "2",
          prodegeCode: "2",
        },
        {
          label: "Retired",
          pureCode: country === "Thailand" ? "117" : "115",
          lucidCode: "9",
          prodegeCode: "9",
        },
        {
          label: "Student",
          pureCode: country === "Thailand" ? "118" : "112",
          lucidCode: "10",
          prodegeCode: "10",
        },
        {
          label: "Unemployed",
          pureCode: country === "Thailand" ? ["114", "115", "116"] : "111",
          lucidCode: ["7", "8"],
          prodegeCode: "7",
        },
      ],
    },
  }
}

export interface IDecodedSetupCode {
  country: string
  section: string
  code: string
  platform: string // lucid, pure, prodege
}

export const decodeSetupCode = (params: IDecodedSetupCode): string => {
  const { country, section, code, platform } = params
  try {
    const projectSetupData = setupData(country)
    let result = null
    projectSetupData[section].conditions.forEach((val: any) => {
      if (code === val[`${platform}Code`]) {
        result = val.label
      }
    })
    return result ?? code
  } catch (e) {
    console.log(e)
    return code
  }
}
