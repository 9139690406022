import { IResponse } from "@bolt-chat-core/common"
import ApiService, { API_URL } from "../api.axios"
import { ENDPOINTS } from "@bolt-chat-core/common-type"

export const _exportChatGuide = async (projectId: string): Promise<IResponse<any>> => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.EXPORT.CHAT_GUIDE.URL}?projectId=${projectId}`, undefined, {
    responseType: "arraybuffer",
  })
  return response.data
}

export const _exportChatGuideTranslations = async (projectId: string): Promise<IResponse<any>> => {
  const response = await ApiService.post(
    `${API_URL}${ENDPOINTS.EXPORT.CHAT_GUIDE.URL}?projectId=${projectId}&exportTranslations=1`,
    undefined,
    {
      responseType: "arraybuffer",
    },
  )
  return response.data
}

export const _exportReport = async (projectId: string): Promise<IResponse<any>> => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.EXPORT.REPORT.URL}?projectId=${projectId}`, undefined, {
    responseType: "arraybuffer",
  })
  return response.data
}

export const _exportChats = async (projectId: string): Promise<IResponse<any>> => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.EXPORT.CHATS.URL}?projectId=${projectId}`, undefined, {
    responseType: "arraybuffer",
  })
  return response.data
}

export const _exportMedia = async (projectId: string): Promise<IResponse<any>> => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.EXPORT.MEDIA.URL}?projectId=${projectId}`)
  return response.data
}
