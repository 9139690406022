/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState } from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Autocomplete,
  TextField,
  SelectChangeEvent,
} from '@mui/material'
import { countries } from '../../../data/country'
import { Confirmation } from '../../common/confirmation/confirmation.component'
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

interface ILocationComponent {
  country: string | any
  setCountry: React.Dispatch<React.SetStateAction<string>>
  city: string[]
  setCity: React.Dispatch<React.SetStateAction<string[]>>
  disabled: boolean
}

const LocationComponent: FC<ILocationComponent> = ({ country, setCountry, city, setCity, disabled }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event
    setCity(typeof value === 'string' ? value.split(',') : value)
  }

  return (
    <>
      <Autocomplete
        disabled={disabled}
        disableClearable
        fullWidth
        id="combo-box-demo"
        options={countries.sort((a: any, b: any) => a.label.localeCompare(b.label))}
        sx={{
          '&.Mui-focused .MuiInputLabel-formControl': {
            color: '#6200EE',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#6200EE !important',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#6200EE !important',
          },
        }}
        renderInput={(params) => <TextField {...params} label="Country" data-bolt="location-component-textfield" />}
        renderOption={(props, option) => <li {...props} data-bolt="location-component-option" key={option.label}>{option.label}</li>}
        value={country}
        onChange={(event, newValue: any) => {
          setCountry(newValue?.label)
          if (country !== newValue?.label) {
            setCity([])
          }
        }}
        data-bolt="location-component-autocomplete"
      />
      <div className="inline-grid">
        {countries[countries.findIndex((x: any) => x.label === country)]?.cities[0]?.label && (
          <FormControl fullWidth>
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                '&.Mui-focused': {
                  color: '#6200EE',
                },
              }}
              data-bolt="location-component-label"
            >
              {country === 'United States' ? 'State' : country === 'Canada' ? 'City' : 'Region'}
            </InputLabel>
            <Select
              disabled={disabled}
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={city}
              onChange={handleChange}
              input={<OutlinedInput label={country === 'United States' ? 'State' : country === 'Canada' ? 'City' : 'Region'} />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
              sx={{
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#6200EE',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#6200EE !important',
                },
              }}
              data-bolt="location-component-select"
            >
              {countries[countries.findIndex((x) => x.label === country)]?.cities
                ?.sort((a: any, b: any) => a.label.localeCompare(b.label))
                ?.map((v: any) => (
                  <MenuItem key={v.label} value={v?.label} data-bolt={`location-component-select-${v.label}`}>
                    <Checkbox checked={city?.indexOf(v?.label) > -1}  data-bolt={`location-component-select-checkbox-${v.label}`} />
                    <ListItemText primary={v.label} data-bolt={`location-component-select-text-${v.label}`} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
      </div>
      <Confirmation
        message="This action may be irreversible"
        confirmText="REMOVE"
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        onConfirm={() => console.log('confirm')}
        onCancel={() => console.log('cancel')}
        actionType="danger"
      />
    </>
  )
}

export { LocationComponent }
