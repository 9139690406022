import { ChatPrisma, Projects, Team, UserCreditHistory } from "@bolt-chat-core/chat-prisma-service-interface"
import { _genericCount, _genericQuery, queryParamBuilder } from "../query.service"
import { IResponse } from "@bolt-chat-core/common"
import { API_URL } from "../api.axios"
import { ChatCoreTeamCreateDtoType, ChatCoreTeamCreditUploadDtoType, ChatCoreTeamUpdateDtoType } from "@bolt-chat-core/common-type"
import ApiService from "../api.axios"
import { ENDPOINTS } from "@bolt-chat-core/common-type"
import { _userQuery } from "./user.service"
import { Users } from "aws-sdk/clients/budgets"
import { _projectQuery } from "./project.service"

// ----------------------- Core operations -----------------------

export const _teamQuery = async (payload: ChatPrisma.TeamFindManyArgs): Promise<IResponse<Team[]>> => {
  const response = await _genericQuery<ChatPrisma.TeamFindManyArgs, Team>("Team", payload)
  return response
}

export const _teamCount = async (payload: ChatPrisma.TeamWhereInput): Promise<IResponse<number>> => {
  const response = await _genericCount<ChatPrisma.TeamWhereInput>(ChatPrisma.ModelName.Team, payload)
  return response
}

export const _teamSearch = async (
  field: ChatPrisma.TeamScalarFieldEnum,
  searchKey: string,
  skip: number = 0,
  take: number = 10,
): Promise<IResponse<Team[]>> => {
  const response = await _genericQuery<ChatPrisma.TeamFindManyArgs, IResponse<Team[]>>(ChatPrisma.ModelName.Team, {
    where: {
      [field]: {
        contains: searchKey,
      },
    },
    skip,
    take,
  })
  return response
}

export const _getTeam = async (teamId: string): Promise<IResponse<Team[]>> => {
  const response = await _genericQuery<ChatPrisma.TeamFindManyArgs, IResponse<Team[]>>("Team", {
    where: {
      id: teamId,
    },
  })
  if (response.success) response.data = response.data[0]
  return response
}

// ----------------------- Api operations -----------------------

export const _createTeam = async (data: ChatCoreTeamCreateDtoType): Promise<IResponse<Team>> => {
  const response = await ApiService.post<Team>(`${API_URL}${ENDPOINTS.CHAT_CORE.TEAM.CREATE.URL}`, data)
  return response.data
}

export const _updateTeam = async (data: ChatCoreTeamUpdateDtoType): Promise<IResponse<Team>> => {
  const response = await ApiService.patch<Team>(`${API_URL}${ENDPOINTS.CHAT_CORE.TEAM.UPDATE.URL}?teamId=${data.teamId}`, data)
  return response.data
}

export const _deleteTeam = async (teamId: string): Promise<IResponse<Team>> => {
  const response = await ApiService.delete<Team>(`${API_URL}${ENDPOINTS.CHAT_CORE.TEAM.DELETE.URL}?teamId=${teamId}`)
  return response.data
}

export const _addTeamMember = async (teamId: string, userId: string): Promise<IResponse<Team>> => {
  const response = await ApiService.patch<Team>(
    `${API_URL}${ENDPOINTS.CHAT_CORE.TEAM.UPDATE_MEMBERS.URL}?teamId=${teamId}&userId=${userId}&action=add`,
  )
  return response.data
}

export const _removeTeamMember = async (teamId: string, userId: string): Promise<IResponse<Team>> => {
  const response = await ApiService.patch<Team>(
    `${API_URL}${ENDPOINTS.CHAT_CORE.TEAM.UPDATE_MEMBERS.URL}?teamId=${teamId}&userId=${userId}&action=remove`,
  )
  return response.data
}

export const _updateCredits = async (data: ChatCoreTeamCreditUploadDtoType): Promise<IResponse<Team>> => {
  const response = await ApiService.patch<Team>(`${API_URL}${ENDPOINTS.CHAT_CORE.TEAM.CREDIT_UPDATE.URL}`, data)
  return response.data
}

export const _updateProjectMembership = async (data: { teamId: string; projectId: string }): Promise<IResponse<null>> => {
  const response = await ApiService.patch<Team>(
    `${API_URL}${ENDPOINTS.CHAT_CORE.TEAM.PROJECT_MEMBERSHIP_UPDATE.URL}?teamId=${data.teamId}&projectId=${data.projectId}`,
  )
  return response.data
}

// ----------------------- external operations -----------------------

export const _getTeamMembers = async (teamId: string): Promise<IResponse<Users[]>> => {
  const response = await _userQuery({
    where: {
      teams: {
        some: {
          id: teamId,
        },
      },
    },
    orderBy: {
      createdAt: "desc",
    },
  })
  return response
}

export const _getTeamProjects = async (teamId: string): Promise<IResponse<Projects[]>> => {
  const response = await _projectQuery({
    where: {
      OR: [
        {
          teamId,
        },
        {
          sharedWithTeamIds: {
            has: teamId,
          },
        },
      ],
    },
    orderBy: {
      createdAt: "desc",
    },
  })
  return response
}

export const _getTeamCreditHistory = async (teamId: string): Promise<IResponse<UserCreditHistory[]>> => {
  const response = await _genericQuery<ChatPrisma.UserCreditHistoryFindManyArgs, IResponse<UserCreditHistory[]>>(
    "UserCreditHistory",
    {
      where: {
        teamId,
      },
      orderBy: {
        createdAt: "desc",
      },
    },
  )
  return response
}
