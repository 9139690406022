/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react'
import { ProjectRedirectURls, RecruitmentType } from '@prisma/client/chat'

interface IRecruitmentComponent {
  disabled: boolean
  urls: ProjectRedirectURls
  setUrls: React.Dispatch<React.SetStateAction<ProjectRedirectURls>>
  type: RecruitmentType
  error: boolean
  handleChangeRecruitmentType: (val: RecruitmentType) => void
}

const RecruitmentComponent: FC<IRecruitmentComponent> = ({
  disabled,
  urls,
  setUrls,
  type,
  error,
  handleChangeRecruitmentType,
}) => {
  const handleChangeUrls = (section: string, value: string) => {
    setUrls((prev: any) => ({
      ...prev,
      [section]: { url: value },
    }))
  }

  return (
    <>
      <div className="grid grid-cols-2 gap-4 sm:ml-10 md:mx-10 lg:mx-0">
        <div
          className={`rounded-lg flex justify-center items-center p-8 border-2 ${!disabled ? 'cursor-pointer' : ''}  ${
            type === 'AUTO' ? 'border-bolt-purple bg-bolt-purple bg-opacity-10' : 'border-transparent bg-[#F7F9FC]'
          }`}
          onClick={() => handleChangeRecruitmentType('AUTO')}
          data-bolt="recruitment-auto-card"
        >
          <div>
            <p className="font-semibold">Automatic</p>
            <p className="text-sm">BoltChatAI can recruit for me</p>
          </div>
        </div>
        <div
          className={` rounded-lg flex justify-center items-center p-8 border-2 ${!disabled ? 'cursor-pointer' : ''} ${
            type === 'MANUAL' ? 'border-bolt-purple bg-bolt-purple bg-opacity-10' : 'border-transparent bg-[#F7F9FC]'
          }`}
          onClick={() => handleChangeRecruitmentType('MANUAL')}
          data-bolt="recruitment-manual-card"
        >
          <div>
            <p className="font-semibold">Manual</p>
            <p className="text-sm">I will recruit myself</p>
          </div>
        </div>
      </div>
      {type === 'MANUAL' && (
        <div className="text-black text-start mt-2">
          <p className="text-base font-semibold">Joining the Chat:</p>
          <p className="text-sm pt-2 font-light">
            Respondents join the chat via a link. This link will be shared with you in the audience page, right after you launch
            the project.
          </p>
          <p className="text-base font-semibold mt-4">End of the Chat:</p>
          <p className="text-sm pt-2 font-light">
            Respondents will see BoltChatAI &quot;Thank You&quot; page at the end of the chat. If you want to customize, contact
            us or enter your links below:
          </p>

          <p className={`text-red-500 text-xs ${!error && 'invisible'} pt-3`}>Please enter a valid URL</p>
          <div className={`font-normal text-sm`}>
            <div className="pb-4 pt-1">
              <p className="mb-1">Complete:</p>
              <input
                placeholder="https://datasource.com?status=20"
                className="text-sm font-normal py-2 px-3 w-full rounded text-gray-900 shadow-sm border-0 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#7039BE] outline-none"
                onChange={(e) => handleChangeUrls('complete', e.target.value)}
                disabled={disabled}
                value={urls.complete?.url || undefined}
                data-bolt="recruitment-manual-complete-url"
              />
            </div>
            <div className="mb-4">
              <p className="mb-1">Disqualify:</p>
              <input
                placeholder="https://datasource.com?status=20"
                className="text-sm font-normal py-2 px-3 w-full rounded text-gray-900 shadow-sm border-0 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#7039BE] outline-none"
                onChange={(e) => handleChangeUrls('disqualify', e.target.value)}
                disabled={disabled}
                value={urls.disqualify?.url || undefined}
                data-bolt="recruitment-manual-disqualify-url"
              />
            </div>
            <div className="mb-4">
              <p className="mb-1">Overquota:</p>
              <input
                placeholder="https://datasource.com?status=20"
                className="text-sm font-normal py-2 px-3 w-full rounded text-gray-900 shadow-sm border-0 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#7039BE] outline-none"
                onChange={(e) => handleChangeUrls('overQuota', e.target.value)}
                disabled={disabled}
                value={urls.overQuota?.url || undefined}
                data-bolt="recruitment-manual-overquota-url"
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export { RecruitmentComponent }
