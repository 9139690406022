import React from 'react'
import { Modal } from '@mui/material'
import { Button } from '../button/button.component'

interface ActionType {
  color: string
}

interface ThemeProperty {
  bg: string
  text: string
}

const actionTypes: Record<string, ActionType> = {
  danger: {
    color: 'red',
  },
  base: {
    color: 'purple',
  },
}

const themeProperties: Record<string, ThemeProperty> = {
  light: {
    bg: 'bg-white',
    text: 'text-gray-600',
  },
  dark: {
    bg: 'bg-bolt-gray',
    text: 'text-white',
  },
}

interface ConfirmationProps {
  title?: string
  message?: string
  onConfirm: () => void
  onCancel?: () => void
  confirmText?: string
  cancelText?: string
  actionType?: keyof typeof actionTypes
  open: boolean
  confirmLoading?: boolean
  setOpen: (open: boolean) => void
  theme?: keyof typeof themeProperties
}

const Confirmation: React.FC<ConfirmationProps> = ({
  title,
  message,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onConfirm = () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onCancel = () => {},
  confirmText,
  cancelText,
  actionType = 'base',
  open,
  confirmLoading,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setOpen = () => {},
  theme = 'dark',
}) => {
  const { color } = actionTypes[actionType] || actionTypes.base
  const themeValues = themeProperties[theme] || themeProperties.light

  const handleClose = () => {
    onCancel()
    setOpen(false)
  }

  const handleConfirm = () => {
    onConfirm()
    setOpen(false)
  }

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <div
        className={`flex flex-col justify-center gap-5 p-5 rounded-md absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-11/12 md:w-8/12 md:max-w-[500px] ${themeValues.bg}`}
        data-bolt="confirmation-modal"
      >
        <h1 className={`text-2xl font-bold ${themeValues.text}`} data-bolt="confirmation-modal-title">{title || 'Are you sure?'}</h1>
        <p className={`text-sm ${themeValues.text}`} data-bolt="confirmation-modal-message">{message || 'This action may be irreversible'}</p>
        <div className="flex justify-end items-center mt-5 gap-2 w-full">
          <Button
            color={theme === 'light' ? 'black' : 'white'}
            variant="base"
            onClick={handleClose}
            className="font-normal"
            loading={confirmLoading}
            data-bolt="confirmation-modal-cancel"
          >
            {cancelText || 'Cancel'}
          </Button>
          <Button onClick={handleConfirm} className="font-normal" data-bolt="confirmation-modal-confirm">
            {confirmText || 'Confirm'}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export { Confirmation }
