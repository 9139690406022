import React from 'react'
import { IoMdCloseCircle } from 'react-icons/io'
import { AnimatePresence, motion } from 'framer-motion'
interface IMediaModal {
  selectedMedia: { url: string; text: string; type: string; isOpen: boolean; message: string }
  handleSelectedMedia: (url: string, text: string, type: string, isOpen: boolean, message: string | null) => void
}

const MediaModal: React.FC<IMediaModal> = ({ selectedMedia, handleSelectedMedia }) => {
  const handleClickOutside = (event: any) => {
    if (event.target === event.currentTarget) {
      handleSelectedMedia('', '', '', false, '')
    }
  }

  const handleModalContentClick = (event: any) => {
    event.stopPropagation()
  }
  return (
    <AnimatePresence>
      {selectedMedia.isOpen && (
        <div
          onClick={handleClickOutside}
          className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50`}
        >
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={{
              hidden: {
                opacity: 0,
                scale: 0.5,
              },
              visible: {
                opacity: 1,
                scale: 1,
                transition: { type: 'spring', stiffness: 260, damping: 20 },
              },
            }}
            onClick={handleModalContentClick}
            className="flex flex-col w-10/12 lg:w-8/12 h-1/2 sm:h-4/6 lg:h-3/4 bg-[#1A1C21] rounded-lg relative items-center"
          >
            {selectedMedia.type === 'image' ? (
              <img src={selectedMedia.url} className="w-full h-full object-contain" />
            ) : selectedMedia.type === 'video' ? (
              <div className="h-full w-full items-center flex flex-col gap-5">
                <video src={selectedMedia.url} className={`h-full w-full rounded-b-md`} controls />
              </div>
            ) : (
              <div className="flex items-center justify-center h-full w-full">
                <audio controls controlsList="nodownload noplaybackrate" className="w-full mx-4 lg:mx-10">
                  <source src={selectedMedia.url} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
              </div>
            )}
            <button
              onClick={() => handleSelectedMedia('', '', '', false, '')}
              className="absolute -top-12 lg:-top-12 -right-4 lg:-right-8 font-bold"
            >
              <IoMdCloseCircle color="#fff" className=" h-8 lg:h-10 w-8 lg-w-10" />
            </button>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  )
}

export { MediaModal }

export default function KeyQuotesIcon() {
  return (
    <svg width="50" height="50" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg" className="min-w-[50px]">
      <ellipse cx="15.1758" cy="14.1556" rx="13" ry="12.9117" fill="#1ED6BB" fillOpacity="0.2" />
      <g filter="url(#filter0_d_2013_16753)">
        <path
          d="M20.543 13.586C20.5305 10.8378 18.1884 8.37506 15.3471 8.10143C12.5017 7.8278 9.90169 9.58857 9.14873 12.2932C8.36664 15.1128 9.87672 18.0276 12.6847 19.1102C13.3337 19.3601 14.0201 19.487 14.719 19.4909C16.6866 19.5028 18.5545 18.67 20.3059 19.5266C20.3558 19.5504 20.414 19.5623 20.5222 19.598C20.5305 19.4433 20.543 19.3323 20.543 19.2213C20.543 16.9291 20.5513 15.8742 20.543 13.582V13.586Z"
          fill="#1ED6BB"
        />
        <path
          d="M12.3852 13.5542C12.3852 13.8834 12.6639 14.153 13.0133 14.153C13.3586 14.153 13.6415 13.8834 13.6415 13.5542C13.6415 13.2251 13.3586 12.9554 13.0133 12.9554C12.668 12.9554 12.3852 13.2251 12.3852 13.5542Z"
          fill="white"
        />
        <path
          d="M14.1157 13.5542C14.1157 13.8834 14.3944 14.153 14.7439 14.153C15.0892 14.153 15.372 13.8834 15.372 13.5542C15.372 13.2251 15.0892 12.9554 14.7439 12.9554C14.3986 12.9554 14.1157 13.2251 14.1157 13.5542Z"
          fill="white"
        />
        <path
          d="M15.8463 13.5542C15.8463 13.8834 16.125 14.153 16.4744 14.153C16.8197 14.153 17.1026 13.8834 17.1026 13.5542C17.1026 13.2251 16.8197 12.9554 16.4744 12.9554C16.1292 12.9554 15.8463 13.2251 15.8463 13.5542Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2013_16753"
          x="0.943359"
          y="0.0734863"
          width="27.6035"
          height="27.5244"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.125382 0 0 0 0 0.654167 0 0 0 0 0.576573 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2013_16753" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2013_16753" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
