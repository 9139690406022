import { ChatPrisma, MetaAnalysis, Projects } from "@bolt-chat-core/chat-prisma-service-interface"
import { _genericCount, _genericQuery } from "../../services/query.service"
import {  IResponse } from "@bolt-chat-core/common"
import { API_URL } from "../api.axios"
import { ChatCoreMetaAnalysisCreateDtoType, ChatCoreMetaAnalysisUpdateDtoType } from "@bolt-chat-core/common-type"
import ApiService from "../api.axios"
import { ENDPOINTS } from "@bolt-chat-core/common-type"
// ----------------------- Core operations -----------------------
export const _metaAnalysisQuery = async (
  payload: ChatPrisma.MetaAnalysisWhereInput,
  select?: ChatPrisma.MetaAnalysisSelect,
): Promise<IResponse<MetaAnalysis[]>> => {
  const response = await _genericQuery<ChatPrisma.MetaAnalysisFindManyArgs, IResponse<MetaAnalysis[]>>(
    ChatPrisma.ModelName.MetaAnalysis,
    {
      where: payload,
      select: select,
    },
  )
  return response
}

export const _metaAnalysisCount = async (payload: ChatPrisma.MetaAnalysisWhereInput): Promise<number> => {
  const response = await _genericCount<ChatPrisma.MetaAnalysisWhereInput>(ChatPrisma.ModelName.MetaAnalysis, payload)
  return response.data
}

export const _metaAnalysisSearch = async (
  field: ChatPrisma.MetaAnalysisScalarFieldEnum,
  searchKey: string,
  skip: number = 0,
  take: number = 10,
): Promise<IResponse<MetaAnalysis[]>> => {
  const response = await _genericQuery<ChatPrisma.MetaAnalysisFindManyArgs, IResponse<MetaAnalysis[]>>(
    ChatPrisma.ModelName.MetaAnalysis,
    {
      where: {
        [field]: {
          contains: searchKey,
        },
      },
      skip,
      take,
    },
  )
  return response
}

export const _getMetaAnalysis = async (metaAnalysisId: string): Promise<IResponse<MetaAnalysis[]>> => {
  const response = await _genericQuery<ChatPrisma.MetaAnalysisFindManyArgs, IResponse<MetaAnalysis[]>>(
    ChatPrisma.ModelName.MetaAnalysis,
    {
      where: {
        id: metaAnalysisId,
      },
    },
  )
  return response
}

export const _getProjectListMetaAnaylsis = async (index: number, userId: string, teamId: string, search?: string)=>{
  const where:any = {
    projectType: 'AI',
    status: 'COMPLETE',
    OR: [],
    AND: []
  }
 
  if(search && search !== ''){
    where.OR.push({ title: { contains: search, mode: 'insensitive' } })
    where.AND.push({OR: [{
      teamId: teamId,
    },
    {
      sharedWithTeamIds: {
        hasSome: teamId
      }
    }]})
  }else {
    where.OR.push({
      teamId: teamId,
    },
    {
      sharedWithTeamIds: {
        hasSome: teamId
      }
    })
    delete where.AND
  }
  const response = await _genericQuery<ChatPrisma.ProjectsFindManyArgs, IResponse<Projects[]>>(ChatPrisma.ModelName.Projects, {
    skip: index,
    take: 10,
    orderBy: {
      createdAt: 'desc',
    },
    select: {
      title: true,
      id: true,
      setups: {
        select: {
          steps: {
            select: {
              location: {
                select: {
                  country: true,
                },
              },
              attendes: true,
              product: {
                select: {
                  category: true,
                  subCategory: true,
                  customCategory: true
                }
              },
              demographics: {
                select: {
                  agegroup: {
                    select: {
                      from: true,
                      to: true
                    }
                  },
                  gender: {
                    select: {
                      label: true
                    }
                  },
                  children: {
                    select: {
                      label: true
                    }
                  },
                  relationship: {
                    select: {
                      label: true
                    }
                  },
                  employment: {
                    select: {
                      label: true
                    }
                  },

                }
              }
            },
          },
        },
      },
    },
    where: where,
  })
  return response.data
}
export const _getProjectsDataMetaAnaylsis = async (userId: string, ids: Array<string>)=>{
  const where:any = {
    projectType: 'AI',
    status: 'COMPLETE',
  }
 
  if (ids.length > 0) {
    where.id = { in: ids };
  }
  const response = await _genericQuery<ChatPrisma.ProjectsFindManyArgs, IResponse<Projects[]>>(ChatPrisma.ModelName.Projects, {
    skip: 0,
    orderBy: {
      createdAt: 'desc',
    },
    select: {
      title: true,
      id: true,
      setups: {
        select: {
          steps: {
            select: {
              location: {
                select: {
                  country: true,
                },
              },
              attendes: true,
              product: {
                select: {
                  category: true,
                  subCategory: true,
                  customCategory: true
                }
              },
              demographics: {
                select: {
                  agegroup: {
                    select: {
                      from: true,
                      to: true
                    }
                  },
                  gender: {
                    select: {
                      label: true
                    }
                  },
                  children: {
                    select: {
                      label: true
                    }
                  },
                  relationship: {
                    select: {
                      label: true
                    }
                  },
                  employment: {
                    select: {
                      label: true
                    }
                  },

                }
              }
            },
          },
        },
      },
    },
    where: {
      ...where,
    },
  })
  return response.data
}

// ----------------------- Api operations -----------------------
export const _createSearchMetaAnalysis = async (data: ChatCoreMetaAnalysisCreateDtoType): Promise<IResponse<MetaAnalysis>> => {
  const response = await ApiService.post<MetaAnalysis>(`${API_URL}${ENDPOINTS.CHAT_CORE.META_ANALYSIS.CREATE.URL}`, data)
  return response
}

export const _getMetaAnalysisHistory = async (): Promise<IResponse<MetaAnalysis[]>> => {
  const response = await ApiService.get<MetaAnalysis[]>(`${API_URL}${ENDPOINTS.CHAT_CORE.META_ANALYSIS.HISTORY.URL}`)
  return response
}

export const _getMetaAnalysisById = async (metaAnalysisId: string): Promise<IResponse<MetaAnalysis>> => {
  const response = await ApiService.get<MetaAnalysis>(
    `${API_URL}${ENDPOINTS.CHAT_CORE.META_ANALYSIS.GET.URL}?metaAnalysisId=${metaAnalysisId}`,
  )
  return response
}

export const _updateMetaAnalysis = async (data: ChatCoreMetaAnalysisUpdateDtoType): Promise<IResponse<MetaAnalysis>> => {
  const dataToSend:any = {}
  if(data.saved){
    dataToSend.saved = data.saved
  }
  if(data.seen){
    dataToSend.seen = data.seen
  }
  const response = await ApiService.patch<MetaAnalysis>(`${API_URL}${ENDPOINTS.CHAT_CORE.META_ANALYSIS.UPDATE.URL}?metaAnalysisId=${data.metaAnalysisId}`, dataToSend)
  return response
}

export const _deleteMetaAnalysis = async (metaAnalysisId: string): Promise<IResponse<MetaAnalysis>> => {
  const response = await ApiService.delete<MetaAnalysis>(
    `${API_URL}${ENDPOINTS.CHAT_CORE.META_ANALYSIS.DELETE.URL}?metaAnalysisId=${metaAnalysisId}`,
  )
  return response
}
