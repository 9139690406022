import {
  ChatAiCreateChatGuideTransferData,
  ChatAiCreateReportTransferData,
  ChatAiReportSearchInsightTransferData,
  ChatAiSetupSearchBurningQuestionSearchTransferData,
  ChatAiSetupSearchObjectiveTransferData,
  ChatAiUpdateReportTransferData,
  IResponse,
} from "@bolt-chat-core/common"
import ApiService, { API_URL } from "../api.axios"
import { ChatAiCreateReportDtoType, ENDPOINTS } from "@bolt-chat-core/common-type"
import { ChatPrisma } from "@bolt-chat-core/chat-prisma-service-interface"
import { queryParamBuilder } from "../query.service"

export const _createChatguide = async (projectId: string): Promise<IResponse<ChatAiCreateChatGuideTransferData>> => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.CHAT_AI.CREATE_CHATGUIDE.URL}?projectId=${projectId}`)
  return response.data
}

export const _createReport = async (
  projectId: string,
  params: ChatAiCreateReportDtoType,
): Promise<IResponse<ChatAiCreateReportTransferData>> => {
  const { section, pickedQuestions } = params
  const stringParams = queryParamBuilder({ projectId })
  let bodyPayload = {}
  if (Boolean(section)) bodyPayload = { ...bodyPayload, section }
  if (Boolean(pickedQuestions)) bodyPayload = { ...bodyPayload, pickedQuestions }

  const response = await ApiService.post(`${API_URL}${ENDPOINTS.CHAT_AI.CREATE_REPORT.URL}?${stringParams}`, bodyPayload)
  return response.data
}

export const _updateReport = async (
  projectId: string,
  payload: ChatPrisma.projectReportUpdateInput,
): Promise<IResponse<ChatAiUpdateReportTransferData>> => {
  const response = await ApiService.patch(`${API_URL}${ENDPOINTS.CHAT_AI.UPDATE_REPORT.URL}?projectId=${projectId}`, payload)
  return response.data
}

export const _searchReportInsight = async (
  projectId: string,
  search: string,
): Promise<IResponse<ChatAiReportSearchInsightTransferData> | null> => {
  try {
    const response = await ApiService.post(
      `${API_URL}${ENDPOINTS.CHAT_AI.SEARCH_REPORT_INSIGHT.URL}?projectId=${projectId}&search=${encodeURIComponent(search)}`,
    )
    return response.data
  } catch (e) {
    return null
  }
}
export const _searchSetupObjective = async (
  projectId: string,
  search: string,
): Promise<IResponse<ChatAiSetupSearchObjectiveTransferData>> => {
  const response = await ApiService.post(
    `${API_URL}${ENDPOINTS.CHAT_AI.SEARCH_SETUP_OBJECTIVE.URL}?projectId=${projectId}&search=${encodeURIComponent(search)}`,
  )
  return response.data
}
export const _searchSetupBurningQuestion = async (
  projectId: string,
  search: string,
): Promise<IResponse<ChatAiSetupSearchBurningQuestionSearchTransferData>> => {
  const response = await ApiService.post(
    `${API_URL}${ENDPOINTS.CHAT_AI.SEARCH_SETUP_BURNING_QUESTION.URL}?projectId=${projectId}&search=${encodeURIComponent(
      search,
    )}`,
  )
  return response.data
}

export const _refreshSimilarityCheck = async (projectId: string): Promise<IResponse<null>> => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.CHAT_AI.REFRESH_SIMILARITY_CHECK.URL}?projectId=${projectId}`)
  return response.data
}

export const _multiProjectParticipantSimilarityCheck = async (projectIds: string[]): Promise<IResponse<null>> => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.CHAT_AI.MULTI_PROJECT_PARTICIPANT_SIMILARITY_CHECK.URL}`, {
    projectIds,
  })
  return response.data
}
