import { FC } from 'react'
import { Textarea } from '../../common/textarea/textarea.component'

interface IObjectiveComponent {
  disabled: boolean
  objective: string | undefined
  handleObjectiveStateChange: (value: string) => void
}

const ObjectiveComponent: FC<IObjectiveComponent> = ({ disabled, objective, handleObjectiveStateChange }) => {
  return (
    <Textarea
      disabled={disabled}
      id="researchObjective"
      name="researchObjective"
      rows={7}
      onChange={(e) => handleObjectiveStateChange(e.target.value)}
      value={objective}
      placeholder="I am trying to understand whether or not people with tattoos would be interested in buying skincare products that are specifically made for tattoos. Do people with tattoos have issues with their skin? Would they consider purchasing something tattoo specific? What products would they be interested in? Are there any products meeting these desires? How much would they spend?"
      data-bolt="objective-component-textarea"
    />
  )
}

export { ObjectiveComponent }
