import { ChatPrisma, User, UserCreditHistory, UserStatus } from "@bolt-chat-core/chat-prisma-service-interface"
import { _genericCount, _genericQuery } from "../../services/query.service"
import { IResponse } from "@bolt-chat-core/common"
import ApiService, { API_URL } from "../api.axios"
import { ENDPOINTS } from "@bolt-chat-core/common-type"

export const _userQuery = async (payload: ChatPrisma.UserFindManyArgs): Promise<IResponse<User[]>> => {
  const response = await _genericQuery<ChatPrisma.UserFindManyArgs, IResponse<User[]>>(ChatPrisma.ModelName.User, payload)
  return response
}

export const _userCount = async (payload: ChatPrisma.UserWhereInput): Promise<IResponse<number>> => {
  const response = await _genericCount<ChatPrisma.UserWhereInput>(ChatPrisma.ModelName.User, payload)
  return response
}

export const _userSearch = async (
  field: ChatPrisma.UserScalarFieldEnum,
  searchKey: string,
  skip: number = 0,
  take: number = 10,
): Promise<IResponse<User[]>> => {
  const response = await _genericQuery<ChatPrisma.UserFindManyArgs, IResponse<User[]>>(ChatPrisma.ModelName.User, {
    where: {
      [field]: {
        contains: searchKey,
      },
    },
    skip,
    take,
  })
  return response
}

export const _getUser = async (userId: string): Promise<IResponse<User[]>> => {
  const response = await _genericQuery<ChatPrisma.UserFindManyArgs, IResponse<User[]>>(ChatPrisma.ModelName.User, {
    where: {
      id: userId,
    },
  })
  if (response.success) response.data = response.data[0]
  return response
}

export const _getUsers = async (): Promise<IResponse<User[]>> => {
  const response = await _genericQuery<ChatPrisma.UserFindManyArgs, IResponse<User[]>>(ChatPrisma.ModelName.User, {})
  return response
}

export const _getUserByEmail = async (email: string): Promise<IResponse<User[]>> => {
  const response = await _genericQuery<ChatPrisma.UserFindManyArgs, IResponse<User[]>>(ChatPrisma.ModelName.User, {
    where: {
      email,
    },
  })
  return response
}

export const _updateUser = async (payload: ChatPrisma.UserUpdateInput, userId: string): Promise<IResponse<User>> => {
  const response = await ApiService.patch(`${API_URL}${ENDPOINTS.CHAT_CORE.USER.UPDATE.URL}?userId=${userId}`, payload)
  return response.data
}

export const _deleteUser = async (userId: string): Promise<IResponse> => {
  const response = await ApiService.delete(`${API_URL}${ENDPOINTS.CHAT_CORE.USER.DELETE.URL}?userId=${userId}`)
  return response.data
}

export const _getUserActivities = async (userId: string): Promise<IResponse<any>> => {
  const response = await ApiService.get(`${API_URL}${ENDPOINTS.CHAT_CORE.USER.GET_ACTIVITIES.URL}?userId=${userId}`)
  return response.data
}

export const _sendFreeCreditsTeamsMessage = async (payload: { text: string; amount: number }): Promise<IResponse<any>> => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.CHAT_CORE.USER.FREE_CREDITS.URL}`, payload)
  return response.data
}

export const _getUserCreditHistory = async (
  userId: string,
  skip: number = 0,
  take: number = 10,
): Promise<IResponse<UserCreditHistory[]>> => {
  const response = await _genericQuery<ChatPrisma.UserCreditHistoryFindManyArgs, IResponse<UserCreditHistory>>(
    ChatPrisma.ModelName.UserCreditHistory,
    {
      where: {
        userId,
      },
      orderBy: {
        createdAt: "desc",
      },
      skip,
      take,
    },
  )
  return response
}
export const _getUserCredit = async (userId: string): Promise<IResponse<User[]>> => {
  const response = await _genericQuery<ChatPrisma.UserFindManyArgs, IResponse<User[]>>(ChatPrisma.ModelName.User, {
    where: {
      id: userId,
    },
    select: {
      credit: true,
    },
  })
  if (response.success) response.data = response.data[0]
  return response
}

export const _updateUserStatus = async (userId: string, status: UserStatus, credit: boolean, teamId: string): Promise<IResponse<any>> => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.CHAT_CORE.USER.UPDATE_STATUS.URL}`, {userId, status, credit, teamId})
  return response.data
}