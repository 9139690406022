import React from 'react'
import { CircularProgress } from '@mui/material'

interface SizeProperties {
  extraSmall: string
  xsmall: string
  small: string
  medium: string
  large: string
}

const sizeProperties: SizeProperties = {
  extraSmall: 'text-xs px-2 py-1',
  xsmall: 'text-xs px-4 py-2',
  small: 'text-sm px-4 py-2',
  medium: 'text-base px-4 py-2',
  large: 'text-lg px-6 py-3',
}

interface ColorVariant {
  base: string
  contained: string
  outlined: string
}

interface ColorProperties {
  [key: string]: ColorVariant
}

const colorProperties: ColorProperties = {
  purple: {
    base: 'text-bolt-purple hover:text-bolt-purple-dark',
    contained: 'bg-bolt-purple hover:bg-bolt-purple-dark text-white ',
    outlined: 'border border-bolt-purple text-bolt-purple hover:bg-bolt-purple hover:text-white ',
  },
  blue: {
    base: 'text-bolt-blue hover:text-bolt-blue-dark',
    contained: 'bg-bolt-blue hover:bg-bolt-blue-dark text-white ',
    outlined: 'border border-bolt-blue text-bolt-blue hover:bg-bolt-blue hover:text-white',
  },
  green: {
    base: 'text-bolt-green hover:text-bolt-green-dark',
    contained: 'bg-bolt-green hover:bg-bolt-green-dark text-white ',
    outlined: 'border border-bolt-green text-bolt-green hover:bg-bolt-green hover:text-white ',
  },
  'green-black': {
    base: 'text-bolt-green hover:text-bolt-green-dark',
    contained: 'bg-bolt-green hover:bg-bolt-green-dark text-black',
    outlined: 'border border-bolt-green text-bolt-green hover:bg-bolt-green hover:text-black',
  },
  red: {
    base: 'text-bolt-red hover:text-bolt-red-dark',
    contained: 'bg-bolt-red hover:bg-bolt-red-dark text-white ',
    outlined: 'border border-bolt-red text-bolt-red hover:bg-bolt-red hover:text-white ',
  },
  orange: {
    base: 'text-bolt-orange hover:text-bolt-orange-dark',
    contained: 'bg-bolt-orange hover:bg-bolt-orange-dark text-white ',
    outlined: 'border border-bolt-orange text-bolt-orange hover:bg-bolt-orange hover:text-white ',
  },
  white: {
    base: 'text-white hover:text-gray-100',
    contained: 'bg-white hover:bg-gray-100 text-black',
    outlined: 'border border-white text-white hover:bg-gray-100 hover:text-black ',
  },
  whiteBordered: {
    base: 'text-white hover:text-gray-100',
    contained: 'bg-white hover:bg-bolt-blue hover:text-white text-bolt-blue border border-bolt-blue',
    outlined: 'border border-white text-white hover:bg-bolt-blue hover:text-white ',
  },
  black: {
    base: 'text-black hover:text-gray-400',
    contained: 'bg-black hover:bg-black sm:hover:bg-gray-400 text-white',
    outlined: 'border border-black text-black hover:bg-gray-400 hover:text-white ',
  },
  gray: {
    base: 'text-gray-700 hover:text-gray-500',
    contained: 'bg-gray-700 hover:bg-gray-600 text-white',
    outlined: 'border border-gray-700 text-gray-700 hover:bg-gray-300 hover:text-white',
  },
  bolt: {
    base: 'text-[#1ED6BB] hover:text-[#17a899]',
    contained:
      'bg-[#1A1C20] hover:bg-[#151718] border border-[#1ED6BB] hover:border-[#17a899] text-[#1ED6BB] hover:text-[#17a899]',
    outlined: 'border border-[#1ED6BB] hover:border-[#17a899] text-[#1ED6BB] hover:text-[#17a899]',
  },
  online: {
    base: 'text-white',
    contained: 'bg-[#69B73F] text-white',
    outlined: 'border border-gray-700 text-gray-700 hover:bg-gray-300 hover:text-white',
  },
  close: {
    base: 'text-white',
    contained: 'bg-[#27B185] text-white',
    outlined: 'border border-gray-700 text-gray-700 hover:bg-gray-300 hover:text-white',
  },
}

interface RadiusProperties {
  rounded: string
  full: string
  sharp: string
}

const radiusProperties: RadiusProperties = {
  rounded: 'rounded',
  full: 'rounded-full',
  sharp: 'rounded-none',
}

interface ButtonProps {
  size?: keyof SizeProperties
  color?: keyof ColorProperties
  onClick?: () => void
  disabled?: boolean
  text?: string
  children?: React.ReactNode
  className?: string
  variant?: 'base' | 'contained' | 'outlined'
  wrap?: string
  radius?: keyof RadiusProperties
  loading?: boolean
  icon?: React.ReactNode
  id?: string
  dataBolt?: string
}

const Button: React.FC<ButtonProps> = ({
  size = 'medium',
  color = 'purple',
  onClick = () => {},
  disabled = false,
  text = '',
  children,
  className,
  variant = 'contained',
  radius = 'rounded',
  wrap = 'text-balance',
  loading = false,
  icon,
  id,
  dataBolt,
}) => {
  const variantProperties = colorProperties[color][variant]

  return (
    <button
      className={`${disabled ? 'bg-opacity-40' : ''} ${sizeProperties[size]} ${variantProperties} ${
        radiusProperties[radius]
      } transition-colors duration-300 ease-in-out ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}
       ${className || ''}`}
      onClick={onClick}
      disabled={disabled || loading}
      id={id || ''}
      data-bolt={dataBolt || ''}
    >
      {loading ? (
        <div className="flex items-center justify-center">
          <span className="opacity-40">{text || children}</span>
          <CircularProgress size={size === 'xsmall' ? 12 : 20} className="absolute" />
        </div>
      ) : (
        <div className={`flex items-center justify-center gap-1 ${wrap}`}>
          {icon && <span className={size === 'xsmall' ? 'text-sm' : 'text-md'}>{icon}</span>}
          {text || children}
        </div>
      )}
    </button>
  )
}

export { Button }
