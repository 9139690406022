import React from 'react'
import { FaRegCopy } from 'react-icons/fa6'

const SuggestCopyComponent = (props: { value: string; handleStateChange: (value: string) => void; disabled: boolean }) => {
  const { value, handleStateChange, disabled } = props
  return (
    <div className="flex flex-row w-full bg-[#f8f9fd] rounded-md mt-4 p-2 gap-1">
      <span className="text-sm text-[#414141] w-full" data-bolt="copy-suggestion-text">{value}</span>
      <div className="flex self-start">
        <button
          disabled={disabled}
          onClick={() => {
            handleStateChange(value ?? '')
          }}
          data-bolt="copy-suggestion-button"
        >
          <FaRegCopy color="#35a7ff" size={20} />
        </button>
      </div>
    </div>
  )
}

export { SuggestCopyComponent }
