/* eslint-disable @nx/enforce-module-boundaries */
import { CONFIG } from "../config/init.config"

const audienceTestUrlBuilder = (countrycode: string, projectId: string, ref: string): string => {
  const baseUrl = CONFIG.BASE_URL
  const languageMap: Record<string, string> = {
    en_GB: "en",
    en_US: "en",
    ar_AE: "ar",
    fr_FR: "fr",
    de_DE: "de",
    it_IT: "it",
    es_ES: "es",
    en_CA: "en",
    fr_CA: "fr",
    vi_VN: "vi",
    tr_TR: "tr",
    zh_CN: "zh",
    en_AU: "en",
    nl_NL: "nl",
    es_MX: "es-mx",
    hi_IN: "hi",
    en_ZA: "en",
    ar_SA: "ar",
    ja_JP: "ja",
    en_SG: "en",
    th_TH: "th",
    id_ID: "id",
    pt_BR: "pt-br",
    ar_EG: "ar",
    kk_KZ: "kk",
    de_CH: "de",
    fr_CH: "fr",
    pl_PL: "pl",
    ro_RO: "ro",
    en_HK: "en",
    en_IE: "en",
    pt_PT: "pt",
    es_CL: "es",
    es_CO: "es",
    tl_PH: "tl",
    zh_TW: "zh-tw",
    sv_SE: "sv",
    ko_KR: "ko",
    ms_MY: "ms",
    cs_CZ: "cs"
  }
  const lang = languageMap[countrycode] || "en"
  const url = `${baseUrl}/chat-core/participant/participant-start?lang=${lang}&projectId=${projectId}&cid=${countrycode}&ref=${ref}`

  return url
}

export { audienceTestUrlBuilder }
