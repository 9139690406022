import React, { FC } from 'react'
import styles from './setupsteps.module.css'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  OutlinedInput,
  SelectChangeEvent,
} from '@mui/material'
import { setupData } from '../../../data/setup'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

interface IDemographicsState {
  age: {
    from: string | undefined | null
    to: string | undefined | null
  }
  gender: string[] | undefined
  relationship: string[] | undefined
  employment: string[] | undefined
  children: string[] | undefined
}

interface IDemographicsComponent {
  project: any
  setDemographics: React.Dispatch<React.SetStateAction<IDemographicsState>>
  demographics: IDemographicsState
  ageValidation: { status: boolean; message: string }
  disabled: boolean
}
const DemographicsComponent: FC<IDemographicsComponent> = ({
  project,
  setDemographics,
  demographics,
  ageValidation,
  disabled,
}) => {
  const projectState = project
  const projectSteps = projectState?.setups[0]?.steps

  const handleChange = (event: SelectChangeEvent<string[]>, type: string) => {
    const {
      target: { value },
    } = event
    switch (type) {
      case 'gender':
        setDemographics({ ...demographics, gender: typeof value === 'string' ? value.split(',') : value })
        break
      case 'relationship':
        setDemographics({ ...demographics, relationship: typeof value === 'string' ? value.split(',') : value })
        break
      case 'children':
        setDemographics({ ...demographics, children: typeof value === 'string' ? value.split(',') : value })
        break
      case 'employment':
        setDemographics({ ...demographics, employment: typeof value === 'string' ? value.split(',') : value })
        break
      default:
        break
    }
  }
  function ageOnChange(type: 'from' | 'to', value: string) {
    if (
      value.length < 3 &&
      (value.length === 2 ? Number(value) >= 16 && Number(value) <= 99 : true) &&
      (type === 'to' && value.length === 2 ? Number(value) - Number(demographics.age['from']) >= 0 : true)
    ) {
      setDemographics({ ...demographics, age: { ...demographics.age, [type]: value } })
    }
  }

  const checkInputVisibility = (type: string) => {
    if (projectState.recruitmentType === 'MANUAL') {
      return true
    } else {
      const hasValidCountries = setupData(projectSteps?.location?.country as string)[type]?.validCountries?.includes(
        projectSteps?.location?.country,
      )

      return hasValidCountries
    }
  }
  return (
    <>
      <div className="flex text-black items-center gap-4">
        <input
          disabled={disabled}
          placeholder="Age min"
          min="16"
          max="98"
          value={Number(demographics.age.from) || ''}
          onChange={(e) => ageOnChange('from', e.target.value)}
          className={`${styles.numberToFrom} 'block w-full p-2 h-14 text-base font-normal text-gray-700 bg-white border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:ring-2 focus:ring-[#7039BE] focus:border-0 focus:outline-none`}
          type="number"
          data-bolt="demographics-age-from"
        />

        <input
          disabled={disabled}
          placeholder="Age max"
          min="17"
          max="99"
          value={Number(demographics.age.to) || ''}
          onChange={(e) => ageOnChange('to', e.target.value)}
          className={`${styles.numberToFrom} block w-full p-2 h-14 text-base font-normal text-gray-700 bg-white border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:ring-2 focus:ring-[#7039BE] focus:border-0 focus:outline-none`}
          type="number"
          data-bolt="demographics-age-to"
        />
      </div>
      {ageValidation.status && <span className="text-red-500 text-xs">{ageValidation.message}</span>}
      <div className="my-5">
        <FormControl fullWidth>
          <InputLabel
            id="demo-simple-select-label-gender"
            sx={{
              '&.Mui-focused': {
                color: '#6200EE',
              },
            }}
          >
            Gender
          </InputLabel>

          <Select
            labelId="demo-simple-select-label-gender"
            id="demo-multiple-checkbox"
            label="Gender"
            multiple
            value={Array.isArray(demographics.gender) ? demographics.gender : []}
            disabled={disabled}
            onChange={(e: any) => handleChange(e, 'gender')}
            input={<OutlinedInput label="Gender" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
            sx={{
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#6200EE',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#6200EE !important',
              },
            }}
            data-bolt="demographics-gender-select"
          >
            {setupData(projectSteps?.location?.country).gender.conditions.map((value: any) => {
              if (
                [
                  'United Arab Emirates',
                  'Vietnam',
                  'Turkey',
                  'Egypt',
                  'Thailand',
                  'Indonesia',
                  'Saudi Arabia',
                  'Malaysia',
                ].includes(projectSteps?.location?.country) &&
                value.label === 'Other' &&
                projectState.recruitmentType === 'AUTO'
              )
                return null
              else {
                return (
                  <MenuItem key={value.label} value={value.label} data-bolt={`demographics-gender-select-${value.label}`}>
                    <Checkbox
                      disabled={disabled}
                      checked={demographics.gender && demographics.gender?.indexOf(value.label) > -1}
                    />
                    <ListItemText primary={value.label} />
                  </MenuItem>
                )
              }
            })}
          </Select>
        </FormControl>
      </div>
      {checkInputVisibility('children') && (
        <div className="my-5">
          <FormControl fullWidth>
            <InputLabel
              id="demo-simple-select-label-children"
              sx={{
                '&.Mui-focused': {
                  color: '#6200EE',
                },
              }}
            >
              Children
            </InputLabel>

            <Select
              disabled={disabled}
              labelId="demo-simple-select-label-children"
              id="demo-multiple-checkbox"
              label="Children"
              multiple
              value={Array.isArray(demographics.children) ? demographics.children : []}
              onChange={(e) => handleChange(e, 'children')}
              input={<OutlinedInput label="Children" />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
              sx={{
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#6200EE',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#6200EE !important',
                },
              }}
              data-bolt="demographics-children-select"
            >
              {setupData(projectSteps?.location?.country).children.conditions.map((value: any) => (
                <MenuItem key={value.label} value={value.label} data-bolt={`demographics-children-select-${value.label}`}>
                  <Checkbox
                    disabled={disabled}
                    checked={demographics.children && demographics.children.indexOf(value.label) > -1}
                  />
                  <ListItemText primary={value.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
      {checkInputVisibility('relationship') && (
        <div className="my-4">
          <FormControl fullWidth>
            <InputLabel
              id="demo-simple-select-label-relationship"
              sx={{
                '&.Mui-focused': {
                  color: '#6200EE',
                },
              }}
            >
              Relationship Status
            </InputLabel>

            <Select
              disabled={disabled}
              labelId="demo-simple-select-label-relationship"
              id="demo-multiple-checkbox"
              label="Relationship Status"
              multiple
              value={Array.isArray(demographics.relationship) ? demographics.relationship : []}
              onChange={(e) => handleChange(e, 'relationship')}
              input={<OutlinedInput label="Relationship Status" />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
              sx={{
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#6200EE',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#6200EE !important',
                },
              }}
              data-bolt="demographics-relationship-select"
            >
              {setupData(projectSteps?.location?.country).relationship.conditions.map((value) => (
                <MenuItem key={value.label} value={value.label} data-bolt={`demographics-relationship-select-${value.label}`}>
                  <Checkbox
                    disabled={disabled}
                    checked={demographics.relationship && demographics.relationship.indexOf(value.label) > -1}
                  />
                  <ListItemText primary={value.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
      {checkInputVisibility('employment') && (
        <div className="my-5">
          <FormControl fullWidth>
            <InputLabel
              id="demo-simple-select-label-employment"
              sx={{
                '&.Mui-focused': {
                  color: '#6200EE',
                },
              }}
            >
              Employment Status
            </InputLabel>

            <Select
              disabled={disabled}
              labelId="demo-simple-select-label-employment"
              id="demo-multiple-checkbox"
              label="Employment Status"
              multiple
              value={Array.isArray(demographics.employment) ? demographics.employment : []}
              onChange={(event) => handleChange(event, 'employment')}
              input={<OutlinedInput label="Employment Status" />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
              sx={{
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#6200EE',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#6200EE !important',
                },
              }}
              data-bolt="demographics-employment-select"
            >
              {setupData(projectSteps?.location?.country).employment.conditions.map((value) => (
                <MenuItem key={value.label} value={value.label} data-bolt={`demographics-employment-select-${value.label}`}>
                  <Checkbox checked={demographics.employment && demographics.employment.indexOf(value.label) > -1} />
                  <ListItemText primary={value.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
    </>
  )
}

export { DemographicsComponent }
