import { ENDPOINTS } from "@bolt-chat-core/common-type"
import ApiService, { API_URL } from "../api.axios"

export const _getProdegeCampaigns = async (status: any) => {
  const response = await ApiService.get(`${API_URL}${ENDPOINTS.CHAT_CORE.CAMPAIGN.GET_PRODEDE_CAMPAIGNS.URL}/${status}`)

  return response.data
}

export const _getProdegeCampaignInfo = async (id: string) => {
  const response = await ApiService.get(`${API_URL}${ENDPOINTS.CHAT_CORE.CAMPAIGN.GET_PRODEDE_CAMPAIGN_INFO.URL}/${id}`)

  return response.data
}

export const _getProdegeCampaignQuotas = async (id: string) => {
  const response = await ApiService.get(`${API_URL}${ENDPOINTS.CHAT_CORE.CAMPAIGN.GET_PRODEDE_CAMPAIGN_QUOTAS.URL}/${id}`)

  return response.data
}

export const _getProdegeCampaignSettings = async (id: string) => {
  const response = await ApiService.get(`${API_URL}${ENDPOINTS.CHAT_CORE.CAMPAIGN.GET_PRODEDE_CAMPAIGN_SETTINGS.URL}/${id}`)

  return response.data
}

export const _getProdegeCampaignStats = async (id: string) => {
  const response = await ApiService.get(`${API_URL}${ENDPOINTS.CHAT_CORE.CAMPAIGN.GET_PRODEDE_CAMPAIGN_STATS.URL}/${id}`)

  return response.data
}

export const _getProdegeQuestions = async (id: string) => {
  const response = await ApiService.get(`${API_URL}${ENDPOINTS.CHAT_CORE.CAMPAIGN.GET_PRODEDE_QUESTIONS.URL}/${id}`)

  return response.data
}

export const _stopProdegeCampaign = async (projectId: string, id: string) => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.CHAT_CORE.CAMPAIGN.STOP_PRODEGE_CAMPAIGN.URL}/${id}`, {
    projectId: projectId,
  })

  return response.data
}
