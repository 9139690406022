import { ENDPOINTS } from "@bolt-chat-core/common-type"
import ApiService, { API_URL } from "../api.axios"
import { IResponse } from "@bolt-chat-core/common"

export const _getTrackingStats = async (search: string, owner: string, type: string, start:string, end: string): Promise<IResponse<any>> => {
  const response = await ApiService.get(
    `${API_URL}${ENDPOINTS.CHAT_CORE.DASHBOARD.GET_STATS.URL}?${search !== '' ? `search=${search}` : ''}${owner !== 'ALL' ? `&owner=${owner}` : ''}${type !== 'BOTH' ? `&type=${type}` : ''}${start !== '' ? `&start=${start}` : ''}${end !== '' ? `&end=${end}` : ''}`,
  )
  return response.data
}

export const _getTrackingCount = async (): Promise<IResponse<any>> => {
  const response = await ApiService.get(
    `${API_URL}${ENDPOINTS.CHAT_CORE.DASHBOARD.GET_COUNT.URL}`,
  )
  return response.data
}
