import { DEV_CONFIG, LOCAL_CONFIG, PROD_CONFIG, UAT_CONFIG } from "./values.config"

let CURRENT_CONFIG = DEV_CONFIG
try {
  const currentUrl = window.location.hostname
  if (currentUrl === "localhost") {
    CURRENT_CONFIG = LOCAL_CONFIG
  } else if (currentUrl.indexOf("-uat") !== -1) {
    CURRENT_CONFIG = UAT_CONFIG
  } else if (currentUrl.indexOf("-dev") !== -1) {
    CURRENT_CONFIG = DEV_CONFIG
  } else {
    CURRENT_CONFIG = PROD_CONFIG
  }
} catch (e) {}

export const CONFIG = CURRENT_CONFIG
