import React from 'react';

// Define the type for the callback function
// You might want to replace 'any' with a more specific function type depending on your use case
type Callback = () => void;

function useAutosave(callback: Callback, delay = 1000, deps: React.DependencyList = []): void {
  // Use `React.MutableRefObject<Callback | undefined>` for the ref to specify that it will hold a function
  const savedCallback: React.MutableRefObject<Callback | undefined> = React.useRef<Callback>();

  // Keep callback ref up to date
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Create the interval
  React.useEffect(() => {
    // Function to call the callback
    function runCallback() {
      // Check if savedCallback.current is defined before calling it
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (typeof delay === 'number') {
      // Run the interval
      const interval = setInterval(runCallback, delay);
      // Clean up on unmount or dependency change
      return () => clearInterval(interval);
    }
  }, [delay, ...deps]);
}

export {useAutosave};