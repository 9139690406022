import { useAuthStore } from 'apps/chat-ui/stores/auth-store'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { Fragment, useEffect, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Logo, Button } from '@bolt-chat-core/shared-ui'
import Avatar from '@mui/material/Avatar'
export default function PendingPage() {
  const router = useRouter()
  const user = useAuthStore().user
  const [navbar, setNavbar] = useState(false)
  const fullname = user?.fullname
  const logout = () => {
    useAuthStore.getState().logout()
    router.push('/auth/login')
    window.location.reload()
  }
  useEffect(() => {
    const authLocal: any = localStorage.getItem('auth')
    if (!router.isReady) return
    if (user?.status === 'ACTIVE' || (user && !user?.status)) {
      router.push('/projects')
      return
    } else if (JSON.parse(authLocal) === null || JSON.parse(authLocal).state.accessToken === null) {
      router.push('/auth/login')
    }
  }, [router.isReady, user?.status])
  if (user?.status === 'ACTIVE' || user?.status === null) {
    return
  }
  return (
    <>
      <Head>
        <title>Bolt Insight</title>
      </Head>

      <nav className="min-h-[67px] max-h-[67px]">
        <div className="w-full bg-[#181818]">
          <div className="flex mx-4 lg:mx-8 justify-between min-h-[67px] gap-1">
            {/* Primary menu and logo */}
            <div className="flex items-center gap-2 my-2 justify-start w-full">{<Logo height={140} width={140} />}</div>
            {/* secondary */}
            <div className="flex gap-3 justify-end w-full">
              <span className="self-center">
                <span className="w-56 text-right">
                  <Menu as="div" className="relative inline-block text-left">
                    <Menu.Button
                      onClick={() => setNavbar(!navbar)}
                      className="inline-flex w-full justify-center  bg-black text-sm font-medium rounded-full"
                      data-bolt="nav-bar-menu"
                    >
                      <Avatar {...stringAvatar(fullname)} />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        className={`absolute z-50 hidden xl:block right-0 mt-2 w-56 origin-top-right divide-y divide-gray-800 bg-[#181818] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                      >
                        <Menu.Item>
                          <Button
                            onClick={() => logout()}
                            size="small"
                            variant="base"
                            color="white"
                            className="font-normal w-full"
                            dataBolt="nav-bar-menu-logout"
                          >
                            Log Out
                          </Button>
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="flex w-full justify-end pr-5">
          <div
            className={`relative z-[9999] self-end bg-[#181818] overflow-hidden flex flex-col xl:hidden min-w-48 origin-top duration-700  ${
              !navbar ? 'h-0' : 'h-auto py-4'
            }`}
          >
            <div className="px-8">
              <div className="flex flex-col tracking-wider font-medium">
                <ul className="grid grid-flow-row gap-2 items-center justify-between z-50">
                  <li className="list-none">
                    <button
                      onClick={() => logout()}
                      className="font-normal text-white text-sm bg-[#393939] px-2 py-1 rounded-md z-50"
                      data-bolt="nav-bar-menu-logout"
                    >
                      Log Out
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div className="flex justify-center items-center flex-wrap h-screen text-gray-800">
        <div className="text-center">
          <div className="flex justify-center">
            <NoProjectSVG />
          </div>
          <div className="text-white font-normal text-[20px] px-4 my-4 text-center max-w-xl mt-6">
            <div>
              <span className="text-[#1ED6BB]">Hang tight! We're checking out your request. </span>Keep an eye on your email—once
              it's approved, we'll let you know. Then you can come back here and kick off your projects!
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function NoProjectSVG() {
  return (
    <svg width="172" height="172" viewBox="0 0 172 172" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="86" cy="86" r="86" fill="#1A1D26" />
      <mask id="mask0_4458_2649" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="172" height="172">
        <circle cx="86" cy="86" r="86" fill="#1A1D26" />
      </mask>
      <g mask="url(#mask0_4458_2649)">
        <path
          d="M54.8015 173.458C54.8015 173.458 14.1363 177.24 -7.05345 143.067C-28.2432 108.893 13.7077 104.254 17.6028 95.544C21.4979 86.8336 9.81263 70.3961 25.8215 56.1268C41.8305 41.8574 55.646 52.8115 70.1801 54.4376C84.7142 56.0637 98.9331 37.9497 115.358 42.2734C131.783 46.5971 149.96 66.0473 137.846 90.9178C125.732 115.788 150.389 118.725 159.477 129.163C168.566 139.587 179.822 169.336 115.358 173.572C50.9064 177.807 54.8015 173.446 54.8015 173.446V173.458Z"
          fill="#C7F9F0"
        />
        <path
          d="M86.6176 112.813C109.194 97.7626 125.43 70.7869 122.291 44.971C120.009 43.7987 117.665 42.8785 115.371 42.2734C98.9331 37.9497 84.7268 56.0637 70.1927 54.4376C55.6586 52.8115 41.8305 41.8448 25.8342 56.1268C9.83785 70.4087 21.5105 86.8336 17.6154 95.544C13.9094 103.838 -24.3733 108.452 -9.62496 138.44C23.9055 138.781 56.7805 131.974 86.6302 112.813H86.6176Z"
          fill="#A8EFE1"
        />
        <path
          d="M181.612 121.145C157.662 102.88 126.186 148.411 112.181 171.857H134.077C161.935 156.441 195.39 131.658 181.612 121.145Z"
          fill="#64E2CA"
        />
        <path
          d="M175.889 126.604C175.7 126.251 175.259 126.125 174.906 126.314C154.725 137.306 131.342 157.827 116.455 171.857H118.572C133.434 157.928 156.036 138.251 175.599 127.587C175.952 127.398 176.078 126.957 175.889 126.604Z"
          fill="#A8EFE1"
        />
        <path
          d="M164.002 146.697C163.902 146.697 163.813 146.684 163.712 146.634L151.952 141.642L154.939 127.549C155.027 127.159 155.405 126.906 155.796 126.995C156.187 127.083 156.439 127.461 156.351 127.852L153.603 140.772L164.255 145.298C164.62 145.449 164.797 145.878 164.645 146.256C164.532 146.533 164.255 146.697 163.977 146.697H164.002Z"
          fill="#A8EFE1"
        />
        <path
          d="M175.322 134.936C175.221 134.936 175.133 134.923 175.032 134.873L167.066 131.495L169.082 121.965C169.171 121.574 169.549 121.322 169.94 121.41C170.33 121.498 170.582 121.877 170.494 122.267L168.717 130.625L175.574 133.537C175.94 133.688 176.116 134.116 175.965 134.495C175.852 134.772 175.574 134.936 175.297 134.936H175.322Z"
          fill="#A8EFE1"
        />
        <path
          d="M152.103 154.374C152.04 154.374 151.964 154.374 151.889 154.349L140.808 151.046L142.825 138.743C142.888 138.352 143.266 138.088 143.657 138.138C144.048 138.201 144.325 138.579 144.262 138.97L142.447 150L152.305 152.937C152.695 153.05 152.91 153.454 152.796 153.844C152.708 154.16 152.418 154.361 152.103 154.361V154.374Z"
          fill="#A8EFE1"
        />
        <path
          d="M138.792 167.66C138.716 167.66 138.628 167.647 138.552 167.622L126.35 163.311L126.426 162.731C126.93 159 128.241 149.079 128.266 148.083C128.266 147.68 128.594 147.39 129.01 147.365C129.413 147.365 129.728 147.705 129.728 148.108C129.703 149.256 128.316 159.705 127.951 162.34L139.044 166.26C139.422 166.399 139.623 166.815 139.485 167.193C139.384 167.496 139.094 167.672 138.804 167.672L138.792 167.66Z"
          fill="#A8EFE1"
        />
        <path
          d="M115.976 171.857L117.677 162.504C117.753 162.113 117.488 161.735 117.098 161.659C116.694 161.584 116.329 161.848 116.253 162.239L114.501 171.857H115.976Z"
          fill="#A8EFE1"
        />
        <path
          d="M117.728 171.857C139.195 147.983 186.579 69.2237 158.985 64.6353C116.921 57.6267 112.95 149.117 112.61 171.857H117.728Z"
          fill="#61D1BC"
        />
        <path
          d="M155.922 73.7742C155.519 73.5095 154.964 73.6103 154.687 74.0263C135.237 103.372 118.888 151.789 112.585 171.857H114.438C120.829 151.562 136.989 103.914 156.162 75.0095C156.427 74.6061 156.326 74.0515 155.91 73.7742H155.922Z"
          fill="#A8EFE1"
        />
        <path
          d="M138.716 103.624L133.913 86.7204C133.775 86.254 134.052 85.7623 134.518 85.6237C134.985 85.485 135.476 85.7623 135.615 86.2287L140.027 101.721L154.082 100.523C154.561 100.473 155.002 100.838 155.04 101.33C155.078 101.822 154.725 102.25 154.233 102.288L138.716 103.612V103.624Z"
          fill="#A8EFE1"
        />
        <path
          d="M149.216 84.0598L145.964 72.6393C145.825 72.1729 146.103 71.6813 146.569 71.5426C147.035 71.404 147.527 71.6813 147.666 72.1477L150.515 82.169L159.578 81.4001C160.057 81.3497 160.498 81.7152 160.536 82.2068C160.574 82.6985 160.221 83.127 159.729 83.1649L149.216 84.0598Z"
          fill="#A8EFE1"
        />
        <path
          d="M132.048 120.112L127.22 105.704C127.068 105.237 127.321 104.733 127.774 104.582C128.241 104.431 128.745 104.67 128.896 105.136L133.233 118.057L145.535 115.612C146.014 115.511 146.481 115.826 146.582 116.305C146.683 116.784 146.367 117.25 145.888 117.351L132.06 120.099L132.048 120.112Z"
          fill="#A8EFE1"
        />
        <path
          d="M123.438 141.579L123.186 140.898C121.598 136.587 117.362 125.154 116.82 124.057C116.606 123.616 116.77 123.087 117.211 122.86C117.652 122.633 118.182 122.809 118.396 123.251C119.026 124.511 123.4 136.348 124.598 139.6L138.792 137.495C139.283 137.419 139.724 137.759 139.8 138.238C139.876 138.717 139.535 139.171 139.056 139.247L123.438 141.554V141.579Z"
          fill="#A8EFE1"
        />
        <path
          d="M116.719 161.243L111.955 146.18C111.803 145.713 112.068 145.209 112.534 145.07C113.001 144.919 113.505 145.184 113.644 145.65L117.879 159.05L127.459 156.188C127.926 156.05 128.417 156.315 128.569 156.781C128.707 157.247 128.442 157.739 127.976 157.89L116.732 161.256L116.719 161.243Z"
          fill="#A8EFE1"
        />
        <path
          d="M66.3228 117.326C66.3228 117.326 48.5996 145.827 42.7885 148.916C36.9774 151.991 37.1286 132.201 37.1286 132.201L56.9318 109.12L66.3354 117.339L66.3228 117.326Z"
          fill="#F6A5A4"
        />
        <path
          d="M61.7344 96.9937L49.3559 116.141L64.8732 127.625C64.8732 127.625 65.8186 101.09 61.747 96.9937H61.7344Z"
          fill="#FF5952"
        />
        <path
          d="M49.2173 100.776C49.4063 100.498 51.0198 98.1032 52.4064 96.288C53.8813 94.3594 57.9654 90.8172 58.3184 89.2794C58.6713 87.7415 56.4654 87.7289 54.9275 89.1533C53.3897 90.5777 51.335 91.0946 51.335 91.0946C51.335 91.0946 54.3477 82.422 53.9695 77.279C54.1838 75.0226 51.94 75.01 51.814 77.1403C51.4988 82.6867 49.7467 86.1028 49.7467 86.1028C49.7467 86.1028 50.8181 77.9471 50.9694 75.2369C51.1585 71.9217 49.6206 71.846 48.7635 73.1948C48.171 74.1276 47.0995 84.0859 46.9231 84.8171C46.8852 79.8757 47.3138 74.5562 46.5323 72.4763C46.2172 71.6444 45.2591 71.0519 44.2759 72.1108C43.2927 73.1822 43.8095 81.048 43.0658 84.4767C42.8893 81.3001 42.0195 78.8043 40.696 76.5353C40.2422 75.7663 38.1875 75.5773 39.0699 77.9849C41.6918 85.1448 38.6161 89.0777 38.9438 92.2543C39.2716 95.4308 39.8262 98.7461 39.8262 98.7461C39.8262 98.7461 39.864 100.687 39.2085 104.129C38.5656 107.57 27.1451 136.083 28.4561 145.071C29.7671 154.071 40.9481 154.286 44.5154 146.231C41.7296 128.848 48.6878 102.729 49.2173 100.788V100.776Z"
          fill="#F6A5A4"
        />
        <path
          d="M106.244 92.6699C106.244 92.6699 118.951 96.9432 121.257 98.1029C117.224 119.734 110.782 148.953 112.081 165.176C95.2145 182.043 67.9616 167.95 67.9616 167.95C67.9616 167.95 70.7726 136.512 67.7473 131.532C64.722 126.553 57.1587 121.221 62.9572 111.855C65.2892 106.952 59.6924 100.523 61.7849 97.0692C64.0665 93.3128 79.8485 90.9556 79.8485 90.9556L106.232 92.6825L106.244 92.6699Z"
          fill="#FF5952"
        />
        <path
          d="M66.9783 49.8113C66.9783 49.8113 63.7009 58.862 66.4615 65.2277C68.1506 69.1102 61.974 70.9506 63.2093 78.6777C63.7261 81.93 63.827 84.7662 62.1126 89.1024C60.0075 94.4346 68.1884 98.0271 77.2896 93.4261C86.3907 88.8251 85.8361 83.6065 83.2898 78.0475C80.7435 72.4885 80.5544 47.5171 80.5544 47.5171L66.9783 49.8113Z"
          fill="#001839"
        />
        <path
          d="M80.2141 81.6276C80.2141 81.6276 82.1427 88.6111 79.8989 90.9179C77.6551 93.2247 71.7306 95.6197 71.7306 95.6197C71.7306 95.6197 70.6717 110.519 87.147 111.717C103.622 112.914 108.047 96.0105 108.047 96.0105C108.047 96.0105 101.19 94.876 98.4415 93.0608C95.6935 91.233 97.2566 70.0811 97.2566 70.0811L80.2141 81.6276Z"
          fill="#ED9898"
        />
        <path
          d="M70.1801 54.4378C70.1801 54.4378 66.701 78.8546 80.6804 84.5018C88.2689 86.3926 101.555 78.1991 100.332 55.169C99.1096 32.1262 83.0881 39.9542 77.4282 43.1812C71.7684 46.4082 70.1801 54.4378 70.1801 54.4378Z"
          fill="#F6A5A4"
        />
        <path
          d="M89.1387 52.0676C89.1387 52.0676 86.0126 57.8661 80.7435 56.114C73.1424 53.5929 66.8649 57.6392 66.575 62.0259C57.9529 51.4626 65.5413 34.836 80.8822 37.8739C83.189 33.7645 94.9372 31.7729 96.9793 39.5252C108.286 37.8865 109.812 49.8617 108.059 56.4543C107.253 59.4922 116.606 63.2991 111.765 75.3751C109.635 80.7072 115.182 82.3585 113.543 88.3713C111.904 94.3841 105.236 100.863 93.5002 98.0271C81.7645 95.1782 94.5212 87.7284 94.5969 85.1695C94.6725 82.6106 89.0253 81.2744 91.1052 76.4466C93.1851 71.6187 95.5549 69.3875 92.9204 66.2614C90.2858 63.1352 86.9202 56.2526 89.1387 52.0676Z"
          fill="#001839"
        />
        <path
          d="M73.893 64.4544C73.9178 63.7099 73.5431 63.0932 73.056 63.077C72.569 63.0608 72.154 63.6511 72.1292 64.3956C72.1044 65.1401 72.4791 65.7568 72.9661 65.7731C73.4532 65.7893 73.8681 65.1989 73.893 64.4544Z"
          fill="#001839"
        />
        <path
          d="M84.6118 64.3426C84.6366 63.5981 84.2619 62.9814 83.7749 62.9652C83.2878 62.9489 82.8729 63.5393 82.848 64.2838C82.8232 65.0283 83.1979 65.645 83.685 65.6612C84.172 65.6775 84.587 65.0871 84.6118 64.3426Z"
          fill="#001839"
        />
        <path
          d="M77.1131 73.5851C76.2181 73.5851 75.1971 73.4591 74.9071 72.9045C74.4029 71.9843 76.6971 66.4631 77.1635 65.3664C77.2139 65.253 77.3274 65.2151 77.4408 65.253C77.5543 65.3034 77.5921 65.4168 77.5543 65.5303C76.2307 68.669 75.008 72.1986 75.2853 72.7028C75.5878 73.2574 77.4282 73.2196 78.5123 73.0683C78.6384 73.0431 78.7392 73.1314 78.7518 73.2448C78.7644 73.3583 78.6888 73.4717 78.5753 73.4843C78.4367 73.5095 77.819 73.5851 77.1257 73.5851H77.1131Z"
          fill="#001839"
        />
        <path
          d="M81.2477 61.3203C81.2477 61.3203 81.1468 61.3077 81.109 61.2699C81.0208 61.1942 81.0208 61.0556 81.109 60.9673C81.1342 60.9421 83.4284 58.5219 87.2227 60.7656C87.3235 60.8287 87.3613 60.9547 87.2983 61.0556C87.2353 61.1564 87.1092 61.1816 87.0084 61.1312C83.5167 59.0639 81.5124 61.169 81.4242 61.2573C81.3863 61.3077 81.3233 61.3203 81.2729 61.3203H81.2477Z"
          fill="#001839"
        />
        <path
          d="M70.7852 61.7111C70.7852 61.7111 70.6717 61.6859 70.6339 61.6481C70.5583 61.5598 70.5583 61.4338 70.6339 61.3455C71.5163 60.501 72.4239 60.1102 73.3693 60.148C74.6929 60.211 75.5374 61.1564 75.5752 61.2069C75.6509 61.2951 75.6383 61.4338 75.55 61.5094C75.4618 61.585 75.3231 61.5724 75.2475 61.4842C75.2475 61.4842 74.4786 60.627 73.3441 60.5766C72.5247 60.5514 71.718 60.9043 70.9238 61.6607C70.886 61.6985 70.8356 61.7237 70.7726 61.7237L70.7852 61.7111Z"
          fill="#001839"
        />
        <path
          d="M132.186 132.201L119.719 133.045C119.719 133.045 124.497 150.958 121.257 150.1C118.018 149.243 79.672 149.886 79.672 149.886C79.672 149.886 66.3733 147.504 62.1504 155.836C57.9276 164.168 67.0161 161.672 67.5582 161.786C68.1002 161.899 75.7769 158.105 79.672 157.676C79.672 157.676 131.972 165.177 134.783 161.685C137.594 158.193 132.186 132.201 132.186 132.201Z"
          fill="#F6A5A4"
        />
        <path
          d="M115.471 119.709C115.471 119.709 116.808 107.229 118.068 102.401C119.329 97.5736 121.812 94.4727 127.157 106.007C132.489 117.541 134.796 133.55 134.796 133.55L116.631 135.856C116.631 135.856 114.942 124.31 115.471 119.709Z"
          fill="#FF5952"
        />
        <path
          d="M75.4492 75.6647C75.4492 75.6647 80.5922 77.8959 84.9033 74.7319C80.7057 81.2237 77.8946 79.6354 75.4492 75.6647Z"
          fill="white"
        />
        <path
          d="M101 161.105H14.0103C12.8894 161.105 11.9808 162.014 11.9808 163.135C11.9808 164.256 12.8894 165.164 14.0103 165.164H101C102.121 165.164 103.03 164.256 103.03 163.135C103.03 162.014 102.121 161.105 101 161.105Z"
          fill="#1F74A3"
        />
        <path
          d="M76.3316 161.105H13.7077C12.754 161.105 11.9808 161.879 11.9808 162.832V163.437C11.9808 164.391 12.754 165.164 13.7077 165.164H76.3316C77.2853 165.164 78.0585 164.391 78.0585 163.437V162.832C78.0585 161.879 77.2853 161.105 76.3316 161.105Z"
          fill="#13597F"
        />
        <path
          d="M71.8062 161.672H14.1111C11.59 161.672 9.43445 159.87 8.98065 157.386L2.47624 121.423C1.89639 118.221 4.35446 115.284 7.60666 115.284H65.2892C67.8103 115.284 69.9658 117.087 70.4196 119.57L76.924 155.533C77.5038 158.735 75.0458 161.672 71.7936 161.672H71.8062Z"
          fill="#1F74A3"
        />
        <path
          d="M70.3944 162.429H12.7119C10.1908 162.429 8.03525 160.626 7.58145 158.143L1.07704 122.18C0.497192 118.978 2.95525 116.041 6.20746 116.041H63.89C66.4111 116.041 68.5666 117.844 69.0204 120.327L75.5248 156.29C76.1047 159.492 73.6466 162.429 70.3944 162.429Z"
          fill="#185D8B"
        />
        <path
          d="M34.4471 131.227C35.5217 131.07 36.1628 129.368 35.8791 127.425C35.5954 125.482 34.4942 124.035 33.4195 124.192C32.3449 124.349 31.7037 126.051 31.9875 127.993C32.2712 129.936 33.3724 131.384 34.4471 131.227Z"
          fill="#CDEAE6"
        />
        <path
          d="M143.203 165.163H-5.04917C-8.54399 165.163 -11.3771 167.993 -11.3771 171.485C-11.3771 174.976 -8.54399 177.806 -5.04917 177.806H143.203C146.698 177.806 149.531 174.976 149.531 171.485C149.531 167.993 146.698 165.163 143.203 165.163Z"
          fill="#2694C4"
        />
        <path
          d="M149.531 171.491C149.531 168.012 146.683 165.163 143.203 165.163H101.769C98.2902 165.163 95.4414 168.012 95.4414 171.491C95.4414 174.97 98.2902 177.819 101.769 177.819H143.203C146.683 177.819 149.531 174.97 149.531 171.491Z"
          fill="#32ADD8"
        />
      </g>
      <path
        d="M53.74 56.624L50.2509 46.2036C52.191 43.1996 53.3332 39.6322 53.3332 35.7989C53.3332 25.2064 44.759 16.6323 34.1666 16.6323C23.5741 16.6323 15 25.2064 15 35.7989C15 46.3914 23.5741 54.9655 34.1666 54.9655C37.7808 54.9655 41.1448 53.9485 44.0237 52.2117L53.74 56.6396V56.624Z"
        fill="#FF5952"
      />
      <path
        d="M38.8135 28.4758C35.872 28.1316 34.4639 30.9009 34.1666 31.5737C33.8537 30.854 32.4455 28.1629 29.5353 28.4914C26.187 28.8826 23.4333 34.9377 30.4115 41.1961C31.8197 42.3696 32.8836 43.2145 33.6033 43.7621C33.8067 43.9186 33.9945 44.0594 34.1509 44.1845C34.3074 44.0594 34.5108 43.9186 34.7299 43.7465C35.4496 43.1832 36.5135 42.3539 37.9373 41.1805C44.9155 34.922 42.1618 28.8669 38.8135 28.4758Z"
        fill="white"
      />
      <path
        d="M63.5345 8.77753C60.593 8.43332 59.1848 11.2027 58.8876 11.8755C58.5746 11.1558 57.1665 8.46461 54.2563 8.79318C50.908 9.18434 48.1543 15.2394 55.1325 21.4979C56.5406 22.6713 57.6046 23.5162 58.3243 24.0639C58.5277 24.2203 58.7155 24.3611 58.8719 24.4863C59.0284 24.3611 59.2318 24.2203 59.4508 24.0482C60.1706 23.485 61.2345 22.6557 62.6583 21.4822C69.6365 15.2238 66.8828 9.16869 63.5345 8.77753Z"
        fill="#FF5952"
      />
      <path
        d="M41.9114 0.0160167C40.2999 -0.171737 39.5332 1.34594 39.3768 1.7058C39.2047 1.31465 38.438 -0.156091 36.8577 0.0316629C35.0271 0.25071 33.5251 3.55205 37.3271 6.96292C38.0938 7.60442 38.6727 8.05816 39.0795 8.37108C39.189 8.46496 39.2985 8.54319 39.3768 8.60577C39.4706 8.54319 39.5645 8.46496 39.6897 8.37108C40.0808 8.0738 40.6598 7.60442 41.4421 6.96292C45.2597 3.55205 43.7421 0.235063 41.9114 0.0316629V0.0160167Z"
        fill="#FF5952"
      />
    </svg>
  )
}
function stringAvatar(name: string | undefined) {
  return {
    sx: {
      bgcolor: '#2D2D3E',
      width: '36px',
      height: '36px',
      color: '#d9d9d9',
      fontSize: 12,
      fontWeight: '500',
    },
    children: `${name ? name?.split(' ')[0][0] : 'AA'}`,
  }
}
