import React, { ChangeEvent, FC } from 'react'

interface TextareaProps {
  id?: string
  name?: string
  placeholder?: string
  maxLength?: number
  className?: string
  rows?: number
  disabled?: boolean
  value?: string
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void
  onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void
  dataBolt?: string
}

const Textarea: FC<TextareaProps> = ({
  id,
  name,
  placeholder,
  maxLength,
  className,
  rows = 3,
  disabled,
  value,
  onChange,
  onFocus,
  onBlur,
  dataBolt,
}) => {
  return (
    <textarea
      id={id}
      name={name}
      placeholder={placeholder}
      rows={rows}
      disabled={disabled}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      maxLength={maxLength}
      className={`w-full rounded-md border-0 outline-none p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#6200EE] text-sm leading-6 mac-style-scrollbar"
      ${className}`}
      data-bolt={dataBolt}
    />
  )
}

export { Textarea }
