export const LOCAL_CONFIG = {
  BASE_URL: "http://localhost:3000/api/v1",
  SOCKET_URL: "http://localhost:8000",
  AI_URL: "https://boltai-dev.boltinsight.com/api/v1",
}

export const DEV_CONFIG = {
  BASE_URL: "https://chat-dev.boltinsight.com/api/v1",
  SOCKET_URL: "https://chat-socket-dev.boltinsight.com",
  AI_URL: "https://boltai-dev.boltinsight.com/api/v1",
}

export const UAT_CONFIG = {
  BASE_URL: "https://chat-uat.boltinsight.com/api/v1",
  SOCKET_URL: "https://chat-socket-uat.boltinsight.com",
  AI_URL: "https://boltai-uat.boltinsight.com/api/v1",
}

export const PROD_CONFIG = {
  BASE_URL: "https://chat.boltinsight.com/api/v1",
  SOCKET_URL: "https://chat-socket.boltinsight.com",
  AI_URL: "https://boltai.boltinsight.com/api/v1",
}
