import { FC } from 'react'
import { Textarea } from '../../common/textarea/textarea.component'

interface IBurningQuestionComponent {
  handleBurningQuestionStateChange: (value: string) => void
  burningQuestion: string | undefined
  disabled: boolean
}

const BurningQuestionComponent: FC<IBurningQuestionComponent> = ({
  handleBurningQuestionStateChange,
  burningQuestion,
  disabled,
}) => {
  return (
    <Textarea
      disabled={disabled}
      id="burningQuestion"
      name="burningQuestion"
      rows={3}
      onChange={(e: any) => handleBurningQuestionStateChange(e?.target.value)}
      value={burningQuestion}
      placeholder="Do consumers have a need or desire for skincare products made specifically for people with tattoos?"
      dataBolt="burning-question-component-textarea"
    />
  )
}

export { BurningQuestionComponent }
