import {
  IResponse,
  RecruitmentGetStandartQuestionAnswerLibraryTransferData,
  RecruitmentGetStandartQuestionLibraryTransferData,
} from "@bolt-chat-core/common"
import ApiService, { API_URL } from "../api.axios"
import { ENDPOINTS } from "@bolt-chat-core/common-type"

export const _getRecruitmentStandartQuestionLibrary = async (
  countryCode: string,
): Promise<IResponse<RecruitmentGetStandartQuestionLibraryTransferData>> => {
  const response = await ApiService.get(`${API_URL}${ENDPOINTS.RECRUITMENT.GET_QUESTIONS.URL}?countryCode=${countryCode}`)
  return response.data
}

export const _getRecruitmentGetStandartQuestionAnswerLibrary = async (
  countryCode: string,
  languageCode: string,
  questionId: string,
): Promise<IResponse<RecruitmentGetStandartQuestionAnswerLibraryTransferData>> => {
  const response = await ApiService.get(
    `${API_URL}${ENDPOINTS.RECRUITMENT.GET_ANSWERS.URL}?countryCode=${countryCode}&languageCode=${languageCode}&questionId=${questionId}`,
  )
  return response.data
}
