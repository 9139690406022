export enum METHODS {
  POST = "POST",
  GET = "GET",
  PATCH = "PATCH",
  DELETE = "DELETE",
}

export const ENDPOINTS = {
  CHAT_CORE: {
    QUERY: {
      QUERY: {
        URL: "/chat-core/query/query",
        METHOD: METHODS.POST,
      },
      COUNT: {
        URL: "/chat-core/query/count",
        METHOD: METHODS.GET,
      },
    },
    REPORT: {
      GET: {
        URL: "/chat-core/report",
        METHOD: METHODS.GET,
      },
      ADD_KEYQUOTE: {
        URL: "/chat-core/report/add-keyquote",
        METHOD: METHODS.POST,
      },
      GET_QUEUE: {
        URL: "/chat-core/report/get-queue",
        METHOD: METHODS.GET,
      },
    },
    TEAM: {
      GET: {
        URL: "/chat-core/team",
        METHOD: METHODS.GET,
      },
      CREATE: {
        URL: "/chat-core/team/create",
        METHOD: METHODS.POST,
      },
      UPDATE: {
        URL: "/chat-core/team/update",
        METHOD: METHODS.PATCH,
      },
      DELETE: {
        URL: "/chat-core/team/delete",
        METHOD: METHODS.DELETE,
      },
      UPDATE_MEMBERS: {
        URL: "/chat-core/team/update-members",
        METHOD: METHODS.PATCH,
      },
      PROJECT_MEMBERSHIP_UPDATE: {
        URL: "/chat-core/team/update-project-membership",
        METHOD: METHODS.PATCH,
      },
      CREDIT_UPDATE: {
        URL: "/chat-core/team/credit-update",
        METHOD: METHODS.PATCH,
      },
    },
    META_ANALYSIS: {
      GET: {
        URL: "/chat-core/meta-analysis",
        METHOD: METHODS.GET,
      },
      CREATE: {
        URL: "/chat-core/meta-analysis/search",
        METHOD: METHODS.POST,
      },
      HISTORY: {
        URL: "/chat-core/meta-analysis/history",
        METHOD: METHODS.GET,
      },
      UPDATE: {
        URL: "/chat-core/meta-analysis/update",
        METHOD: METHODS.PATCH,
      },
      DELETE: {
        URL: "/chat-core/meta-analysis/delete",
        METHOD: METHODS.DELETE,
      },
    },
    PROJECT: {
      CREATE: {
        URL: "/chat-core/project/create",
        METHOD: METHODS.POST,
      },
      UPDATE: {
        URL: "/chat-core/project/update",
        METHOD: METHODS.PATCH,
      },
      DELETE: {
        URL: "/chat-core/project/delete",
        METHOD: METHODS.DELETE,
      },
      GET_ACTIVITIES: {
        URL: "/chat-core/project/activities",
        METHOD: METHODS.GET,
      },
      GET_DATA_ACQUISITION_ACTIVITIES: {
        URL: "/chat-core/project/data-acquisition-activities",
        METHOD: METHODS.GET,
      },
      SHARE_WITH_TEAM: {
        URL: "/chat-core/project/share-with-team",
        METHOD: METHODS.POST,
      },
      SHARE_REPORT_WITH_CLIENT: {
        URL: "/chat-core/project/share-report-with-client",
        METHOD: METHODS.POST,
      },
      UPDATE_EXPORT_IN_LOCAL_LANGUAGE: {
        URL: "/chat-core/project/update-export-in-local-language",
        METHOD: METHODS.POST,
      },
      SHARE_CHATS_WITH_CLIENT: {
        URL: "/chat-core/project/share-chat-with-client",
        METHOD: METHODS.POST,
      },
      CREATE_CONTACT_US: {
        URL: "/chat-core/project/contact-us",
        METHOD: METHODS.POST,
      },
      UPLOAD_STIMULUS: {
        URL: "/chat-core/project/stimulus/upload",
        METHOD: METHODS.POST,
      },
      UPLOAD_DISCOVER: {
        URL: "/chat-core/project/discover/upload",
        METHOD: METHODS.POST,
      },
      UPLOAD_AUDIO: {
        URL: "/chat-core/project/audio/upload",
        METHOD: METHODS.POST,
      },
      UPLOAD_VIDEO: {
        URL: "/chat-core/project/video/upload",
        METHOD: METHODS.POST,
      },
      LAUNCH_PROJECT: {
        URL: "/chat-core/project/launch",
        METHOD: METHODS.POST,
      },
      START_LIVE_CHAT: {
        URL: "/chat-core/project/start-live-chat",
        METHOD: METHODS.POST,
      },
      BACK_TO_REVIEW: {
        URL: "/chat-core/project/back-to-review",
        METHOD: METHODS.POST,
      },
      GO_TO_COMPLETE: {
        URL: "/chat-core/project/go-to-complete",
        METHOD: METHODS.POST,
      },
      BACK_TO_SETUP: {
        URL: "/chat-core/project/back-to-setup",
        METHOD: METHODS.POST,
      },
      UPLOAD_CUSTOMER_FILE: {
        URL: "/chat-core/project/customer/upload",
        METHOD: METHODS.POST,
      },
    },
    PARTICIPANT: {
      UPDATE: {
        URL: "/chat-core/participant/update",
        METHOD: METHODS.PATCH,
      },
      VALIDATE_FINGERPRINT: {
        URL: "/chat-core/participant/validate-fingerprint",
        METHOD: METHODS.POST,
      },
      CHANGE_STATUS: {
        URL: "/chat-core/participant/change-status",
        METHOD: METHODS.PATCH,
      },
    },
    MESSAGE: {
      MESSAGE_DISPLAY: {
        URL: "/chat-core/message/message-display",
        METHOD: METHODS.POST,
      },
      MESSAGE_UPDATE: {
        URL: "/chat-core/message/message-update",
        METHOD: METHODS.POST,
      },
    },
    USER: {
      CREATE: {
        URL: "/chat-core/user/create",
        METHOD: METHODS.POST,
      },
      UPDATE: {
        URL: "/chat-core/user/update",
        METHOD: METHODS.PATCH,
      },
      DELETE: {
        URL: "/chat-core/user/delete",
        METHOD: METHODS.DELETE,
      },
      GET_ACTIVITIES: {
        URL: "/chat-core/user/activities",
        METHOD: METHODS.GET,
      },
      FREE_CREDITS: {
        URL: "/chat-core/user/get-free-credits",
        METHOD: METHODS.POST,
      },
      UPDATE_STATUS: {
        URL: "/chat-core/user/user-status",
        METHOD: METHODS.POST,
      },
    },
    PAYMENT: {
      GET_PRODUCTS: {
        URL: "/chat-core/payment/get-stripe-products",
        METHOD: METHODS.GET,
      },
      CREDIT_DEPOSIT: {
        URL: "/chat-core/payment/credit-deposit",
        METHOD: METHODS.POST,
      },
    },
    CAMPAIGN: {
      GET_PRODEDE_CAMPAIGNS: {
        URL: "/chat-core/campaign/get-prodege-campaigns",
        METHOD: METHODS.GET,
      },
      GET_PRODEDE_CAMPAIGN_INFO: {
        URL: "/chat-core/campaign/get-prodege-campaign-info",
        METHOD: METHODS.GET,
      },
      GET_PRODEDE_CAMPAIGN_QUOTAS: {
        URL: "/chat-core/campaign/get-prodege-campaign-quotas",
        METHOD: METHODS.GET,
      },
      GET_PRODEDE_CAMPAIGN_SETTINGS: {
        URL: "/chat-core/campaign/get-prodege-campaign-settings",
        METHOD: METHODS.GET,
      },
      GET_PRODEDE_CAMPAIGN_STATS: {
        URL: "/chat-core/campaign/get-prodege-campaign-stats",
        METHOD: METHODS.GET,
      },
      GET_PRODEDE_QUESTIONS: {
        URL: "/chat-core/campaign/get-prodege-questions",
        METHOD: METHODS.GET,
      },
      STOP_PRODEGE_CAMPAIGN: {
        URL: "/chat-core/campaign/stop-prodege-campaign",
        METHOD: METHODS.POST,
      },
    },
    CRON: {
      GET_CRONJOBS: {
        URL: "/chat-core/cron/cronjobs",
        METHOD: METHODS.GET,
      },
    },
    DASHBOARD: {
      GET_STATS: {
        URL: "/chat-core/dashboard/stats",
        METHODS: METHODS.GET
      },
      GET_COUNT: {
        URL: "/chat-core/dashboard/count",
        METHODS: METHODS.GET
      }
    }
  },
  EXTERNAL: {
    CHAT_BUILDER: {
      CREATE: {
        URL: "/external/chat-builder/create",
        METHOD: METHODS.POST,
      },
      GET_REPORT: {
        URL: "/external/chat-builder/report",
        METHOD: METHODS.GET,
      },
      VALIDATE_AND_REDIRECT: {
        URL: "/external/chat-builder/validate-and-redirect",
        METHOD: METHODS.POST,
      },
    },
  },
  AUTH: {
    LOGIN: {
      URL: "/auth/login",
      METHOD: METHODS.POST,
    },
    MSAL_LOGIN: {
      URL: "/auth/login-msal",
      METHOD: METHODS.POST,
    },
    INVITE_USER: {
      URL: "/auth/invite-user",
      METHOD: METHODS.POST,
    },
    REGISTER: {
      URL: "/auth/register",
      METHOD: METHODS.POST,
    },
    REGISTER_WITH_TOKEN: {
      URL: "/auth/register-with-token",
      METHOD: METHODS.POST,
    },
    FORGOT_PASSWORD: {
      URL: "/auth/forgot-password",
      METHOD: METHODS.POST,
    },
    RESET_PASSWORD: {
      URL: "/auth/reset-password",
      METHOD: METHODS.POST,
    },
    LOGOUT: {
      URL: "/auth/logout",
      METHOD: METHODS.POST,
    },
    API_KEY_CREATE: {
      URL: "/auth/api-key/create",
      METHOD: METHODS.POST
    },
    API_KEY_UPDATE: {
      URL: "/auth/api-key/update",
      METHOD: METHODS.POST
    },
    API_KEY_DELETE: {
      URL: "/auth/api-key/delete",
      METHOD: METHODS.DELETE
    },
    API_KEY_USER_LOGIN: {
      URL: "/auth/api-key/login-with-key",
      METHOD: METHODS.POST
    }
  },
  RECRUITMENT: {
    GET_QUESTIONS: {
      URL: "/recruitment/platform/getStandartQuestionLibrary",
      METHOD: METHODS.GET,
    },
    GET_ANSWERS: {
      URL: "/recruitment/platform/getStandartQuestionAnswer",
      METHOD: METHODS.GET,
    },
  },
  CHAT_AI: {
    CREATE_CHATGUIDE: {
      URL: "/chat-ai/create-chatguide",
      METHOD: METHODS.POST,
    },
    CREATE_PROJECTIVEGUIDE: {
      URL: "/chat-ai/create-projectiveguide",
      METHOD: METHODS.POST,
    },
    CREATE_REPORT: {
      URL: "/chat-ai/create-report",
      METHOD: METHODS.POST,
    },
    UPDATE_REPORT: {
      URL: "/chat-ai/update-report",
      METHOD: METHODS.PATCH,
    },
    SEARCH_REPORT_INSIGHT: {
      URL: "/chat-ai/report-insight-search",
      METHOD: METHODS.POST,
    },
    SEARCH_SETUP_OBJECTIVE: {
      URL: "/chat-ai/setup-objective-search",
      METHOD: METHODS.POST,
    },
    SEARCH_SETUP_BURNING_QUESTION: {
      URL: "/chat-ai/setup-burning-question-search",
      METHOD: METHODS.POST,
    },
    REFRESH_SIMILARITY_CHECK: {
      URL: "/chat-ai/refresh-similarity-check",
      METHOD: METHODS.POST,
    },
    MULTI_PROJECT_PARTICIPANT_SIMILARITY_CHECK: {
      URL: "/chat-ai/multi-project-similarity-check",
      METHOD: METHODS.POST,
    },
  },
  EXPORT: {
    CHAT_GUIDE: {
      URL: "/export/chat-guide",
      METHOD: METHODS.POST,
    },
    REPORT: {
      URL: "/export/report",
      METHOD: METHODS.POST,
    },
    CHATS: {
      URL: "/export/chats",
      METHOD: METHODS.POST,
    },
    MEDIA: {
      URL: "/export/media",
      METHOD: METHODS.POST,
    },
  },
}
