import { ChatPrisma, Messages } from "@bolt-chat-core/chat-prisma-service-interface"
import { _genericCount, _genericQuery } from "../../services/query.service"
import { IResponse } from "@bolt-chat-core/common"
import { ChatCoreMessageUpdateDtoType, ENDPOINTS } from "@bolt-chat-core/common-type"
import ApiService, { API_URL } from "../api.axios"

export const _messageQuery = async (payload: ChatPrisma.MessagesFindManyArgs): Promise<IResponse<Messages[]>> => {
  const response = await _genericQuery<ChatPrisma.MessagesFindManyArgs, IResponse<Messages[]>>(
    ChatPrisma.ModelName.Messages,
    payload,
  )
  return response
}

export const _messageCount = async (payload: ChatPrisma.MessagesWhereInput): Promise<IResponse<number>> => {
  const response = await _genericCount<ChatPrisma.MessagesWhereInput>(ChatPrisma.ModelName.Messages, payload)
  return response
}

export const _messageSearch = async (
  field: ChatPrisma.MessagesScalarFieldEnum,
  searchKey: string,
  skip: number = 0,
  take: number = 10,
): Promise<IResponse<Messages[]>> => {
  const response = await _genericQuery<ChatPrisma.MessagesFindManyArgs, IResponse<Messages[]>>(ChatPrisma.ModelName.Messages, {
    where: {
      [field]: {
        contains: searchKey,
      },
    },
    skip,
    take,
  })
  return response
}

export const _getMessage = async (messageId: string): Promise<IResponse<Messages>> => {
  const response = await _genericQuery<ChatPrisma.MessagesFindManyArgs, IResponse<Messages>>(ChatPrisma.ModelName.Messages, {
    where: {
      id: messageId,
    },
  })
  return response
}

export const _getMessages = async (participantId: string): Promise<IResponse<Messages[]>> => {
  const response = await _genericQuery<ChatPrisma.MessagesFindManyArgs, IResponse<Messages[]>>(ChatPrisma.ModelName.Messages, {
    where: {
      participantId,
    },
  })
  return response
}

export const _messageDisplay = async (
  messageId: string,
  display: boolean,
  regenerateReportAssets?: boolean,
): Promise<IResponse<string>> => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.CHAT_CORE.MESSAGE.MESSAGE_DISPLAY.URL}`, {
    messageId,
    display,
    regenerateReportAssets: regenerateReportAssets || false,
  })
  return response.data
}

export const _messageUpdate = async (params: ChatCoreMessageUpdateDtoType): Promise<IResponse<Messages>> => {
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.CHAT_CORE.MESSAGE.MESSAGE_UPDATE.URL}`, params)
  return response.data
}