import { ChatPrisma, Participants } from "@bolt-chat-core/chat-prisma-service-interface"
import { _genericCount, _genericQuery } from "../../services/query.service"
import { ChatCoreParticipantUpdateTransferData, IResponse } from "@bolt-chat-core/common"
import ApiService, { API_URL } from "../api.axios"
import { ENDPOINTS } from "@bolt-chat-core/common-type"

export const _participantQuery = async (
  payload: ChatPrisma.ParticipantsWhereInput,
  select?: ChatPrisma.ParticipantsSelect,
): Promise<IResponse<Participants[]>> => {
  const response = await _genericQuery<ChatPrisma.ParticipantsFindManyArgs, IResponse<Participants[]>>(
    ChatPrisma.ModelName.Participants,
    {
      where: payload,
      select: select,
    },
  )
  return response
}

export const _participantCount = async (payload: ChatPrisma.ParticipantsWhereInput): Promise<IResponse<number>> => {
  const response = await _genericCount<ChatPrisma.ParticipantsWhereInput>(ChatPrisma.ModelName.Participants, payload)
  return response
}

export const _participantSearch = async (
  field: ChatPrisma.ParticipantsScalarFieldEnum,
  searchKey: string,
  skip: number = 0,
  take: number = 10,
): Promise<IResponse<Participants[]>> => {
  const response = await _genericQuery<ChatPrisma.ParticipantsFindManyArgs, IResponse<Participants[]>>(
    ChatPrisma.ModelName.Participants,
    {
      where: {
        [field]: {
          contains: searchKey,
        },
      },
      skip,
      take,
    },
  )
  return response
}

export const _getParticipant = async (participantId: string): Promise<IResponse<Participants[]>> => {
  const response = await _genericQuery<ChatPrisma.ParticipantsFindManyArgs, IResponse<Participants[]>>(
    ChatPrisma.ModelName.Participants,
    {
      where: {
        id: participantId,
      },
    },
  )
  return response
}

export const _getParticipants = async (projectId: string): Promise<IResponse<Participants[]>> => {
  const response = await _genericQuery<ChatPrisma.ParticipantsFindManyArgs, IResponse<Participants[]>>(
    ChatPrisma.ModelName.Participants,
    {
      where: {
        projectId,
      },
    },
  )
  return response
}

export const _validateParticipantFingerprint = async (
  visitorId: string,
  requestId: string,
  country: string,
): Promise<IResponse<any>> => {
  const response = await ApiService.post(
    `${API_URL}${ENDPOINTS.CHAT_CORE.PARTICIPANT.VALIDATE_FINGERPRINT.URL}?visitorId=${visitorId}&requestId=${requestId}&country=${country}`,
  )
  return response.data
}

export const _updateParticipant = async (
  participantId: string,
  updateData: any,
): Promise<IResponse<ChatCoreParticipantUpdateTransferData>> => {
  const response = await ApiService.patch(
    `${API_URL}${ENDPOINTS.CHAT_CORE.PARTICIPANT.UPDATE.URL}?participantId=${participantId}`,
    updateData,
  )
  return response.data
}

export const _participantChangeStatus = async (
  participantId: string,
  newStatus: string,
  newStep: string,
): Promise<IResponse<ChatCoreParticipantUpdateTransferData>> => {
  const response = await ApiService.patch(
    `${API_URL}${ENDPOINTS.CHAT_CORE.PARTICIPANT.CHANGE_STATUS.URL}?participantId=${participantId}&newStatus=${newStatus}&newStep=${newStep}`,
  )
  return response.data
}
