import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Autocomplete,
  TextField,
  SelectChangeEvent,
} from '@mui/material'
import { categories } from '../../../data/category'
import { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react'
import { ChipsInput } from '../../common/chips-input/chips-input.component'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

interface ICategoryComponent {
  setCategory: Dispatch<SetStateAction<{ category: string; subCategory: string[] }>>
  customCategory: string[]
  setCustomCategory: Dispatch<SetStateAction<string[]>>
  category: { category: string; subCategory: string[] }
  disabled: boolean
}

const CategoryComponent: FC<ICategoryComponent> = ({ category, setCategory, disabled, customCategory, setCustomCategory }) => {
  const categoryIndex = useRef<number>(0)

  const handleCategory = (value: string) => {
    setCategory({ category: value, subCategory: [] })
  }
  const handleDeleteCategory = () => {
    setCategory({ category: '', subCategory: [] })
  }
  useEffect(() => {
    categoryIndex.current = categories.findIndex((x) => x.category.label === category.category)
  }, [category])

  const handleSubCategory = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value
    setCategory((prev: any) => ({ ...prev, subCategory: typeof value === 'string' ? value.split(',') : value }))
  }

  return (
    <>
      <Autocomplete
        disabled={disabled}
        fullWidth
        id="combo-box-demo"
        options={categories.sort((a, b) => a.category.label.localeCompare(b.category.label)).map((val) => val.category)}
        sx={{
          '&.Mui-focused .MuiInputLabel-formControl': {
            color: '#6200EE',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#6200EE !important',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#6200EE !important',
          },
        }}
        renderInput={(params) => <TextField {...params} label="Category" />}
        renderOption={(props, option) => <li {...props} data-bolt="category-component-option" key={option.label}>{option.label}</li>}
        value={category.category as any}
        onChange={(event, newValue) => {
          if (newValue?.label) {
            handleCategory(newValue?.label)
          } else {
            handleDeleteCategory()
          }
        }}
        data-bolt="category-component-autocomplete"
      />
      {category.category && (
        <FormControl fullWidth>
          <InputLabel
            id="demo-simple-select-label"
            sx={{
              '&.Mui-focused': {
                color: '#6200EE',
              },
            }}
          >
            Sub-Category
          </InputLabel>
          <Select
            disabled={disabled}
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={category.subCategory}
            onChange={(event) => handleSubCategory(event)}
            input={<OutlinedInput label="Sub-Category" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
            sx={{
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#6200EE',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#6200EE !important',
              },
            }}
            data-bolt="category-component-select"
          >
            {category
              ? categories[categoryIndex.current]?.subcategory
                  .sort((a: string, b: string) => a.localeCompare(b))
                  .map((value: string, index: number) => (
                    <MenuItem
                      key={index}
                      value={value}
                      sx={{ whiteSpace: 'normal' }}
                      disabled={category.subCategory.length > 2 && !category.subCategory.includes(value)}
                      data-bolt={`category-component-select-${value}`}
                    >
                      <Checkbox
                        disabled={disabled || category.subCategory.length > 2}
                        checked={category.subCategory.indexOf(value) > -1}
                      />
                      <ListItemText primary={value} />
                    </MenuItem>
                  ))
              : null}
          </Select>
        </FormControl>
      )}
      <div>
        <p className="text-gray-900 text-sm">
          Can't find what you are looking for? Below, you can enter the categories you want:
        </p>
        <div className="mt-4 border border-[#d1d5db] rounded p-2">
          <ChipsInput value={customCategory} onChange={setCustomCategory} size="large" disabled={disabled} />
        </div>
      </div>
    </>
  )
}

export { CategoryComponent }
