import { KeyQuote, projectReport } from "@bolt-chat-core/chat-prisma-service-interface"
import { IResponse } from "@bolt-chat-core/common"
import { ChatCoreReportProjectAddKeyQuoteDtoType, ENDPOINTS } from "@bolt-chat-core/common-type"
import ApiService, { API_URL } from "../api.axios"

export const _getReportByProjectId = async (projectId: string): Promise<IResponse<projectReport>> => {
  const response = await ApiService.get(`${API_URL}${ENDPOINTS.CHAT_CORE.REPORT.GET.URL}?projectId=${projectId}`)
  return response.data
}

export const _addKeyQuoteToReport = async (params: ChatCoreReportProjectAddKeyQuoteDtoType): Promise<IResponse<KeyQuote>> => {
  const { projectId, messageId, type } = params
  const response = await ApiService.post(`${API_URL}${ENDPOINTS.CHAT_CORE.REPORT.ADD_KEYQUOTE.URL}`, {
    projectId,
    messageId,
    type,
  })
  return response.data
}

export const _getReportQueueJobs = async () => {
  const response = await ApiService.get(`${API_URL}${ENDPOINTS.CHAT_CORE.REPORT.GET_QUEUE.URL}`)
  return response.data
}
