import { Socket, connect } from "socket.io-client"
import {
  AddTextMessageEventPayload,
  JoinToParticipantRoomEventPayload,
  JoinToProjectRoomEventPayload,
  SendAiMessagesEventPayload,
  UpdateConsumerTypingStatusEventPayload,
  UpdateParticipantTypingStatusEventPayload,
} from "./socket.interface"
import { CONFIG } from "../../config/init.config"

class SocketService {
  private socket: Socket
  private socket_url = CONFIG.SOCKET_URL
  public connected = false

  constructor() {
    this.socket = connect(this.socket_url, { transports: ["websocket"] })
    this.onConnect(() => (this.connected = true))
    this.onDisconnect(() => (this.connected = false))
  }

  public onConnect(callback: Function) {
    this.socket.on("connect", () => callback())
  }

  public onDisconnect(callback: Function) {
    this.socket.on("disconnect", () => callback())
  }

  /**
   * @param callback Function to call on projectStatus event
   * @description This event will be emitted after project status changed
   */
  public onProjectStatusEvent(callback: Function) {
    this.socket.on("projectStatus", (data) => callback(data))
  }

  /**
   * @param callback Function to call on projectStatus event
   * @description This event will be emitted after a new message inserted
   */
  public onMessageInsertedEvent(callback: Function) {
    this.socket.on("messageInserted", (data) => callback(data))
  }

  /**
   * @param callback Function to call on projectStatus event
   * @description This event will be emitted after a new message inserted
   */
  public onProjectMessageInsertedEvent(callback: Function) {
    this.socket.on("projectMessageInserted", (data) => callback(data))
  }

  /**
   * @param callback Function to call on projectStatus event
   * @description This event will be emitted after project updated
   */
  public onProjectUpdatedEvent(callback: Function) {
    this.socket.on("projectUpdated", (data) => callback(data))
  }

  /**
   * @param callback Function to call on projectStatus event
   * @description This event will be emitted after participant updated
   */
  public onParticipantUpdatedEvent(callback: Function) {
    this.socket.on("participantUpdated", (data) => callback(data))
  }

  /**
   * @param callback Function to call on projectStatus event
   * @description This event will be emitted after participant's typing status changed
   */
  public onParticipantTypingStatusUpdatedEvent(callback: Function) {
    this.socket.on("participantTypingStatusUpdated", (data) => callback(data))
  }

  /**
   * @param callback Function to call on projectStatus event
   * @description This event will be emitted after consumer's typing status changed
   */
  public onConsumerTypingStatusUpdatedEvent(callback: Function) {
    this.socket.on("consumerTypingStatusUpdated", (data) => callback(data))
  }

  /**
   * @param callback Function to call on projectStatus event
   * @description This event will be emitted after any message has seen
   */
  public onMessageSeenEvent(callback: Function) {
    this.socket.on("messageSeen", (data) => callback(data))
  }

  /**
   * @param callback Function to call on projectStatus event
   * @description This event will be emitted after any message has been updated
   */
  public onMessageUpdatedEvent(callback: Function) {
    this.socket.on("messageUpdated", (data) => callback(data))
  }

  /**
   * @param callback Function to call on projectStatus event
   * @description This event will be emitted after participant's chat guide changed
   */
  public onChatGuideIndexUpdatedEvent(callback: Function) {
    this.socket.on("chatGuideIndexUpdated", (data) => callback(data))
  }

  /**
   * @param callback Function to call on projectStatus event
   * @description This event will be emitted after trying to make fake complete
   */
  public onFakeCompleteRedirectionEvent(callback: Function) {
    this.socket.on("fakeCompleteRedirection", (data) => callback(data))
  }

  /**
   * @param callback Function to call on projectStatus event
   * @description This event will be emitted after participant join to chat room
   */
  public onParticipantDetailsEvent(callback: Function) {
    this.socket.on("participantDetails", (data) => callback(data))
  }

  /**
   * @param callback Function to call on redirect participant event
   * @description This event will be emitted after participant redirection
   */
  public onRedirectParticipant(callback: Function) {
    this.socket.on("redirectParticipant", (data) => callback(data))
  }

  /**
   * @param {Object} payload
   * @param {string} payload.participantId - Participant's identifier
   * @param {string} payload.projectId - Project's identifier
   * @description Event to join participant socket room
   */
  public emitJoinToParticipantRoomEvent(payload: JoinToParticipantRoomEventPayload) {
    this.socket.emit("joinToParticipantRoom", payload)
  }

  /**
   * @param {Object} payload
   * @param {string} payload.projectId - Project's identifier
   * @description Event to join project socket room
   */
  public emitJoinToProjectRoomEvent(payload: JoinToProjectRoomEventPayload) {
    this.socket.emit("joinToProjectRoom", payload)
  }

  /**
   * @param {Object} payload
   * @description Event to add new text message
   */
  public emitAddTextMessageEvent(payload: AddTextMessageEventPayload) {
    this.socket.emit("addTextMessage", payload)
  }

  /**
   * @param {Object} payload
   * @description Event to add new file message
   */
  public emitAddFileMessageEvent(payload: any) {
    this.socket.emit("addFileMessage", payload)
  }

  /**
   * @param {Object} payload
   * @description Event to update participant's typing status
   */
  public emitUpdateParticipantTypingStatusEvent(payload: UpdateParticipantTypingStatusEventPayload) {
    this.socket.emit("updateParticipantTypingStatus", payload)
  }

  /**
   * @param {Object} payload
   * @description Event to update consumer's typing status
   */
  public emitUpdateConsumerTypingStatusEvent(payload: UpdateConsumerTypingStatusEventPayload) {
    this.socket.emit("updateConsumerTypingStatus", payload)
  }

  /**
   * @param {string} messageId
   * @description Event to update message to be seen
   */
  public emitMakeMessageSeenEvent(messageId: string) {
    // TODO - change this event's payload from string to object
    this.socket.emit("makeMessageSeen", messageId)
  }

  /**
   * @param {Object} payload
   * @description Event to trigger message sender to Bolt AI
   */
  public emitSendAiMessagesEvent(payload: SendAiMessagesEventPayload) {
    this.socket.emit("sendAiMessages", payload)
  }
}

export { SocketService }
