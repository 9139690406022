import { FC } from 'react'
import { TextField } from '@mui/material'

interface IProjectNameComponent {
  projectName: string | null | undefined
  setProjectName: React.Dispatch<React.SetStateAction<string | null | undefined>>
  disabled: boolean
}

const ProjectNameComponent: FC<IProjectNameComponent> = ({ projectName, setProjectName, disabled }) => {
  return (
    <TextField
      className="!w-full"
      disabled={disabled}
      id="outlined-basic"
      label="Project Name"
      variant="outlined"
      value={projectName}
      onChange={(e) => setProjectName(e.target.value)}
      sx={{
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            borderColor: '#6200EE !important',
          },
        },
        '& label.Mui-focused': {
          color: '#6200EE',
        },
        '&.Mui-focused .MuiInputLabel': {
          color: '#6200EE',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#6200EE !important',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#6200EE !important',
        },
      }}
      data-bolt="project-name-component-textfield"
    />
  )
}

export { ProjectNameComponent }
