import React from 'react'
import { AutoComplete } from 'antd'

interface IAutoCompleteComponent {
  onSearch?: any
  value?: any
  placeholder?: string
  className?: string
  autoClearSearchValue?: boolean
  onSelect?: any
  options?: any
  status?: 'error' | undefined
}

const AutoCompleteComponent: React.FC<IAutoCompleteComponent> = ({
  onSearch,
  value,
  placeholder,
  className,
  autoClearSearchValue,
  onSelect,
  options,
  status,
}) => {
  return (
    <AutoComplete
      className={className}
      placeholder={placeholder}
      value={value}
      onSearch={onSearch}
      autoClearSearchValue={autoClearSearchValue}
      onSelect={onSelect}
      options={options}
      status={status}
    />
  )
}

export { AutoCompleteComponent }
