export const Setup = {
  costMultiplier: 75,
  quotaMuliplier: 100,
  expectedLoi: 20,
  requiredCompleteMultiplier: 3,
  fieldTime: 1,
  maxNumberOfQuestions: 20,
  maxStimulusFileUpload: 5,
  maxNumberOfAnswerPoll: 20, // mean: no limit
  maxNumberOfRating: 20, // mean: no limit
  maxNumberOfAnswerPollQuestion: 5,
  maxNumberOfAnswerRatingStatement: 5,
  maxNumberOfAnswerCustomTargetingQuestion: 5,
  maxNumberOfProbe: 5,
  totalSteps: 14,
  maxProjectSize: 100,
  maxRequestMedia: 5
}
//
export const Durations = {
  liveChatDuration: 25,
  audienceDuration: 10,
  aiLiveChatDuration: 30,
  manualAiLiveChatDuration: 24 * 60,
}
