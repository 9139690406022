/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import CryptoJS from "crypto-js"

const generateProdegeSignature = (status: string, externalId: string) => {
  let completion_type = ""
  if (status === "COMPLETE") {
    completion_type = "1"
  } else if (status === "OVER_QUOTA") {
    completion_type = "2"
  } else if (status === "DISQUALIFIED") {
    completion_type = "3"
  }
  const params = [
    `${process.env.PRODEGE_SECRET_KEY}`,
    `apik=${process.env.PRODEGE_API_KEY}`,
    `completion_type=${completion_type}`,
    `transaction_id=${externalId}`,
  ]

  const stringToSign = params.sort().join(":")
  const prodegeSignature = CryptoJS.SHA256(stringToSign)
    .toString(CryptoJS.enc.Base64)
    .split("+")
    .join("-")
    .split("/")
    .join("_")
    .split("=")
    .join("")
  return { signature: prodegeSignature, completionType: completion_type }
}

export const redirectUrlBuilder = (ref: string, status: string, externalId: string, fakeComplete: boolean) => {
  let redirectLink = ""
  if (ref === "pure") {
    if (status === "COMPLETE" || fakeComplete) {
      redirectLink = `https://spectrumsurveys.com/surveydone?st=21&transaction_id=${externalId}`
    } else if (status === "OVER_QUOTA") {
      redirectLink = `https://spectrumsurveys.com/surveydone?st=17&transaction_id=${externalId}`
    } else if (status === "DISQUALIFIED") {
      redirectLink = `https://spectrumsurveys.com/surveydone?st=18&transaction_id=${externalId}`
    }
  } else if (ref === "lucid") {
    if (status === "COMPLETE" || fakeComplete) {
      redirectLink = `https://notch.insights.supply/cb?token=de505482-a558-430a-a079-f5158727fb6d&rst=1&RID=${externalId}`
    } else if (status === "OVER_QUOTA") {
      redirectLink = `https://samplicio.us/s/ClientCallBack.aspx?RIS=40&rst=2&RID=${externalId}`
    } else if (status === "DISQUALIFIED") {
      redirectLink = `https://samplicio.us/s/ClientCallBack.aspx?RIS=20&rst=3&RID=${externalId}`
    }
  } else if (ref === "prodege") {
    const { signature, completionType } = generateProdegeSignature(status, externalId)
    redirectLink = `https://prodegemr.sbxdv.com/prodegemr/transaction-completion?apik=BwZikhfMxBAJvnO&completion_type=${completionType}&signature=${signature}&transaction_id=${externalId}`
  }
  return redirectLink
}
